import {
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Directive,
  HostBinding,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

// App
import { BaseComponent } from './base-component';
import { ISlug } from '../types';
import {
  generateCSSFromTheme,
  isBrowser,
  uuidv4
} from '../tools';

/*
  If `applyThemeStyles` is true, the component will act as
  a live preview of whatever color styles are passed in
  with the theme
*/

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class LiveThemedComponent extends BaseComponent implements OnChanges, OnDestroy {
  @Input() slug: ISlug;
  @Input() applyThemeStyles = false;

  @HostBinding('class')
  @Input()
  wrapperClass = uuidv4().replace(/[^a-zA-Z]/g, '_');

  private _currentStyleNode;

  constructor(@Inject(DOCUMENT) private _document: Document) {
    super();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (!this.applyThemeStyles) {
      this._removeStyleNode();
      return;
    }

    this._checkIfDirtyAndApplyStylesIfNeeded(changes);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._removeStyleNode();
  }

  private _checkIfDirtyAndApplyStylesIfNeeded(changes: SimpleChanges) {
    if (!isBrowser) return;
    const oldTheme = (changes?.slug?.previousValue as ISlug)?.theme;
    const newTheme = (changes?.slug?.currentValue as ISlug)?.theme;
    const keys = ['button', 'card', 'link', 'text', 'layout', 'secondaryButton', 'fontSize'];

    // Only do the work of generating new CSS and manipulating
    // the DOM if elements we're interested in have actually changed
    const oldProps = keys.reduce((o, k) => ({ ...o, [k]: oldTheme?.[k] }), {});
    const newProps = keys.reduce((o, k) => ({ ...o, [k]: newTheme?.[k] }), {});

    let dirty = Object.keys(oldProps).length !== Object.keys(newProps).length;

    if (!dirty) {
      for (const outerKey in newProps) {
        if (newProps[outerKey] && oldProps[outerKey]) {
          for (const innerKey in newProps[outerKey]) {
            if (newProps[outerKey]?.[innerKey] !== oldProps[outerKey]?.[innerKey]) {
              dirty = true;
              break;
            }
          }
          if (dirty)
            break;
        } else {
          dirty = true;
          break;
        }
      }
    }

    if (dirty) this._updateCss();
  }

  private get _documentHead() {
    return this._document?.head || this._document?.getElementsByTagName('head')[0];
  }

  private _removeStyleNode() {
    if (!isBrowser || !this._currentStyleNode) return;

    // Remove any previously generated styles
    this._documentHead.removeChild(this._currentStyleNode);
    this._currentStyleNode = null;
  }

  private _updateCss() {
    if (!isBrowser) return;

    const newCss = generateCSSFromTheme(this.slug?.theme, `.${this.wrapperClass}`);
    const newChild = this._document?.createTextNode(newCss);

    if (this._currentStyleNode) {
      // If the style node exists, just replace its contents
      this._currentStyleNode.replaceChildren(newChild);
    } else {
      // If the style node doesn't exist, create it and populate its contents
      this._currentStyleNode = this._document?.createElement('style');
      this._currentStyleNode.type = 'text/css';
      this._currentStyleNode.appendChild(newChild);

      // Append the new style node to the document head
      this._documentHead.appendChild(this._currentStyleNode);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFontConfigComponent } from './custom-font-config.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';


@NgModule({
  declarations: [
    CustomFontConfigComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    NzFormModule,
    ReactiveFormsModule,
    NzUploadModule,
    NzIconModule,
    NzInputModule,
    NzDividerModule,
  ]
})
export class CustomFontConfigModule { }

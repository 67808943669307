<div class="container">
  <div class="drop-hero" *ngIf="!drop || drop?.imageUrl">
    <div
      class="drop-hero-backdrop"
      [style.backgroundColor]="drop ? paletteColor : ''"
    ></div>
    <div
      class="drop-hero-backdrop image"
      *ngIf="
        drop?.imageUrl && !(drop?.styles?.detail?.headerImageColor?.length > 0)
      "
      [style.background]="'url(' + drop.imageUrl + ')'"
    ></div>
  </div>

  <div class="drop-container" [class.has-image]="!drop || drop?.imageUrl">
    <div class="space-wrapper">
      <nz-card [nzBodyStyle]="{ padding: '0px' }" [style]="drop?.cardCSS">
        <div class="header">
          <div
            class="image-wrapper"
            *ngIf="!drop || drop?.imageUrl"
            [style.backgroundColor]="
              drop?.image?.palettes?.length > 0 ? drop.image?.palettes[0] : ''
            "
          >
            <lib-soft-image-view
              [imageUrl]="drop?.imageUrl"
              [initialHeight]="'100px'"
              [image]="drop?.image"
              [imgixImage]="drop?.imgixImage"
              [alt]="drop?.title"
            >
            </lib-soft-image-view>
          </div>
          <div class="meta-wrapper">
            <div *ngIf="drop" class="meta card-text-color">
              <div
                *ngIf="
                  drop?.isFull ||
                  (drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit) ||
                  drop?.registrationCloseDate
                "
              >
                <nz-tag
                  nzColor="warning"
                  *ngIf="drop?.isFull || drop?.isPastRegistrationDeadline"
                >
                  <i nz-icon nzType="lock"></i>
                  <span>Signups are closed</span>
                </nz-tag>
                <nz-tag
                  nzColor="warning"
                  *ngIf="
                    drop?.registrationCloseDate &&
                    !drop?.isPastRegistrationDeadline &&
                    !drop?.isFull
                  "
                >
                  <i nz-icon nzType="calendar"></i>
                  <span
                    >{{
                      drop?.tickets?.length > 0
                        ? 'Get tickets by'
                        : 'Sign up by'
                    }}
                    {{ registrationCloseDate }}</span
                  >
                </nz-tag>
                <nz-tag
                  *ngIf="
                    drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit
                  "
                  nzColor="processing"
                >
                  <i nz-icon nzType="unlock"></i>
                  <span>{{ drop?.rsvpRestrictions?.remaining }} left</span>
                </nz-tag>
              </div>
              <h2 class="title" [style]="drop?.cardTextCSS">
                {{ drop?.title }}
              </h2>
              <p
                *ngIf="drop?.subtitle"
                [style]="drop?.cardTextCSS"
                class="subtitle"
              >
                {{ drop.subtitle }}
              </p>
              <div *ngIf="priceStr">
                <nz-alert nzType="info" [nzMessage]="priceStr"> </nz-alert>
              </div>
            </div>
            <nz-skeleton
              *ngIf="!drop"
              [nzTitle]="false"
              [nzAvatar]="false"
              [nzParagraph]="{ rows: 3, width: 150 }"
            >
            </nz-skeleton>

            <div
              class="main-actions-container"
              (click)="$event.stopPropagation()"
            >
              <div class="row" *ngIf="drop?.published">
                <div class="wide">
                  <lib-drop-main-button-view
                    [drop]="drop"
                    [userContent]="userContent"
                    [isLoading]="isLoading"
                    [forceDisabled]="shouldDisableButtons"
                  >
                  </lib-drop-main-button-view>
                </div>
                <lib-event-drop-share-button-view
                  [content]="drop"
                  [userContent]="userContent"
                >
                </lib-event-drop-share-button-view>
              </div>
            </div>
          </div>
        </div>
      </nz-card>

      <nz-card
        *ngIf="userContent?.rsvpEnabled && drop?.published"
        [nzBodyStyle]="{ padding: 0 }"
        [style]="drop?.cardCSS"
      >
        <lib-rsvp-confirmation-view
          [content]="drop"
          [userContent]="userContent"
        >
        </lib-rsvp-confirmation-view>
      </nz-card>

      <nz-card *ngIf="drop?.body" [style]="drop?.cardCSS">
        <div class="drop-body ql-readonly-body card-text-color">
          <div
            *ngIf="drop.body"
            [innerHTML]="
              drop?.signedBody || drop.body | safeHtml: drop?.cardTextCSS
            "
          ></div>
        </div>
      </nz-card>
    </div>
  </div>
</div>

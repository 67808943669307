<div
  class="collection-view-container"
  @initalRenderNoop
  [@.disabled]="!showAsAbstract"
>
  <div
    class="item"
    *ngFor="let block of collection?.blocks; let i = index; trackBy: trackBy"
    [class.narrow]="
      block?.blockType !== 'header' && block?.blockType !== 'embed'
    "
    [@slideInOut]="block?.blockType !== 'header' ? 'slide' : null"
  >
    <div
      *ngIf="block?.blockType == 'text' && block?.body"
      class="text-color"
      [innerHTML]="block?.body"
    ></div>
    <lib-link-card-view
      *ngIf="block?.content?.isLink"
      [link]="block?.content"
      (cardClick)="handleClick($event)"
    >
    </lib-link-card-view>
    <lib-event-drop-card
      *ngIf="
        (block?.content?.isEvent ||
          block?.content?.isDrop ||
          block?.content?.isNewsletter) &&
        !showAsAbstract
      "
      [content]="block?.content"
      (cardClick)="handleClick($event)"
    >
    </lib-event-drop-card>
    <lib-abstract-signup-view
      *ngIf="block?.content?.isDrop && showAsAbstract"
      [color]="cardTextColor"
    >
    </lib-abstract-signup-view>
    <lib-abstract-event-view
      *ngIf="block?.content?.isEvent && showAsAbstract"
      [color]="cardTextColor"
    >
    </lib-abstract-event-view>
    <lib-profile-view
      *ngIf="block?.blockType == 'profile'"
      [block]="block"
      [slug]="slug"
    ></lib-profile-view>
    <lib-social-icons-view
      *ngIf="block?.blockType == 'socialIcons'"
      [block]="block"
      [slug]="slug"
    >
    </lib-social-icons-view>
    <lib-image-block-view
      *ngIf="block?.blockType == 'image'"
      [imageBlock]="block"
    ></lib-image-block-view>
    <lib-spacer-view
      *ngIf="block?.blockType == 'spacer'"
      [block]="block"
    ></lib-spacer-view>
    <lib-upcoming-events
      *ngIf="block?.blockType == 'upcomingEvents'"
      [block]="block"
      (cardClick)="handleClick($event)"
    >
    </lib-upcoming-events>
    <lib-youtube-embed-view
      *ngIf="block?.blockType == 'youtube'"
      [embedCode]="block?.embedCode"
    >
    </lib-youtube-embed-view>
    <lib-vimeo-embed-view
      *ngIf="block?.blockType == 'vimeo'"
      [embedCode]="block?.embedCode"
    ></lib-vimeo-embed-view>
    <lib-embed-view
      *ngIf="block?.blockType == 'twitter' || block?.blockType == 'instagram'"
      [embedCode]="block?.embedCode"
      [placeholderIcon]="block?.blockType"
      [placeholderText]="block?.blockType == 'twitter' ? 'Tweet' : 'Instagram'"
    >
    </lib-embed-view>
    <lib-embed-view
      *ngIf="block?.blockType == 'embed'"
      [embedCode]="block?.embedCode"
    >
    </lib-embed-view>
    <lib-newsletter-listing
      *ngIf="block?.blockType == 'newsletterListing'"
      [block]="block"
      (cardClick)="handleClick($event)"
    ></lib-newsletter-listing>
    <lib-newsletter-signup-view
      *ngIf="block?.blockType == 'newsletterSignup' && !showAsAbstract"
      [block]="block"
    >
    </lib-newsletter-signup-view>
    <lib-header-view
      *ngIf="block?.blockType == 'header'"
      [block]="block"
      [dangleAvatar]="showAsAbstract"
    >
    </lib-header-view>
  </div>

  <div
    class="empty text-color"
    *ngIf="collection?.blocks?.length == 0 && slug?.theme?.metadata?.empty"
  >
    {{ slug?.theme?.metadata?.empty }}
  </div>
</div>

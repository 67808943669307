import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BaseComponent } from '../../models';

@Component({
  selector: 'lib-custom-style-item',
  templateUrl: './custom-style-item.component.html',
  styleUrls: ['./custom-style-item.component.less']
})
export class CustomStyleItemComponent extends BaseComponent implements OnInit {
  form: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _dialogRef: NzModalRef<CustomStyleItemComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      name: [undefined, [Validators.required]]
    });

    this.form.valueChanges.pipe(this.takeUntilDestroy).subscribe((val) => {
      this._dialogRef.updateConfig({
        nzOkDisabled: !this.form.valid,
        nzOnOk: () => {
          this._dialogRef.close({ ...this.form.value });
        }
      });
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { PaymentFormComponent } from '../components/payment-form';

@NgModule({
    declarations: [
        PaymentFormComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzFormModule,
        NzSpinModule,
        NzButtonModule,
        NzInputModule,
        NzSelectModule
    ],
    exports: [
        PaymentFormComponent
    ]
})
export class PaymentFormModule { }

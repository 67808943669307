<!-- WITH CARD -->
<nz-card
  *ngIf="!imageBlock?.hideCard"
  [nzBodyStyle]="{ padding: '0px' }"
  class="image-wrapper"
>
  <ng-container
    [ngTemplateOutlet]="
      imageBlock?.rawImages?.length == 1
        ? singleImageTemplate
        : carouselTemplate
    "
  ></ng-container>
</nz-card>

<!-- WITHOUT CARD -->
<div *ngIf="imageBlock?.hideCard" class="image-wrapper">
  <ng-container
    [ngTemplateOutlet]="
      imageBlock?.rawImages?.length == 1
        ? singleImageTemplate
        : carouselTemplate
    "
  ></ng-container>
</div>

<!-- TPL FOR SINGLE IMAGE BLOCK -->
<ng-template #singleImageTemplate>
  <lib-soft-image-view
    [imageUrl]="imageBlock.rawImages[0]"
    [imgixImage]="
      imageBlock.imgixImages?.length > 0 ? imageBlock.imgixImages[0] : null
    "
  >
  </lib-soft-image-view>
</ng-template>

<!-- TPL FOR MULTIPLE IMAGE CAROUSEL -->
<ng-template #carouselTemplate>
  <nz-carousel nzAutoPlay nzAutoPlaySpeed="6000" nzEffect="fade">
    <div
      nz-carousel-content
      *ngFor="let image of imageBlock.rawImages; index as i"
    >
      <lib-soft-image-view
        [imageUrl]="image"
        [imgixImage]="
          imageBlock.imgixImages?.length > i ? imageBlock.imgixImages[i] : null
        "
      >
      </lib-soft-image-view>
    </div>
  </nz-carousel>
</ng-template>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-powered-by-view',
  templateUrl: './powered-by-view.component.html',
  styleUrls: ['./powered-by-view.component.less']
})
export class PoweredByViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

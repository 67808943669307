import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors
} from '@angular/forms';

// 3rd party
import { Subscription, combineLatest } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';

// App
import {
  IEventDropBase,
  IUserMetadata,
  IUserPublicMetadata,
  IPromptResponse,
  ITicket,
  IUserContent,
  DeliveryType
} from '../../types';
import { AuthService, UserService, ErrorService } from '../../services';
import { BaseComponent } from '../../models';
import { VALID_COUNTRY_CODES, PRIVACY_URL, TERMS_URL } from '../../constants';
import { isPhoneNumberValid } from '../../tools';

const STRIPE_BASE_FEE = 30;
const STRIPE_PERCENTAGE_FEE = 0.029;

type Stages =
  | 'phone'
  | 'code'
  | 'email'
  | 'emailCode'
  | 'profile'
  | 'prompts'
  | 'purchase'
  | 'tickets';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  // Event data passed in if triggered as part of RSVP flow
  content: IEventDropBase;

  // User content to return if registering for something
  userContent: IUserContent = {};

  // Currently displayed error
  error: string;

  // Stepper stages are dynamically generated depending on
  // the prompts and user info required by the event
  stages: Stages[] = [];

  // The currently displayed index in the stepper
  currentIndex = 0;

  // The current stage
  get currentStage() {
    return this.stages?.length > this.currentIndex
      ? this.stages[this.currentIndex]
      : null;
  }

  // Whether the user is on the final stage of the flow
  get isOnFinalScreen() {
    return this.currentIndex >= this.stages.length - 1;
  }

  // The user's cached phone number
  phoneNumber;

  // Placeholder shown in phone entry box
  // Country dependent
  phonePlaceholder = '(123) 456-7890';

  // The user's cached email
  email: string;
  emailVerificationCodeMedium: DeliveryType | 'none';

  // User requirements for the input event
  needsName = false;
  needsEmail = false;

  // Whether the user tappeed the Edit button next to
  // their name or email in the profile stage
  isEditingName = false;
  isEditingEmail = false;

  // Ticket selected by the user
  selectedTicket: ITicket;

  // For the button spinners in the UI
  isLoading: boolean;

  // For the spinner on the resend code button
  isResendingCode: boolean;

  // The copy to be shown on the phone number capture stage
  loginCopy: string;

  // The copy to be shown on the phone number capture stage
  emailLoginCopy: string;

  // Constants
  privacyUrl = PRIVACY_URL;
  termsUrl = TERMS_URL;
  validCountryCodes = VALID_COUNTRY_CODES;

  // The current user's public profile information
  userProfile: IUserPublicMetadata;

  // The current user's private profile information
  userMetadata: IUserMetadata;

  // Whether the user has already paid for this event (if it's ticketed)
  userHasPaid = false;

  // Whether user profile information has emitted at least once
  hasLoadedProfile = false;

  // Receipt breakdown for ticketed events
  subtotal: number;
  fees: number;
  total: number;

  // Form groups for each stage
  ticketFormGroup: FormGroup;
  phoneFormGroup: FormGroup;
  codeFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  emailCodeFormGroup: FormGroup;
  profileFormGroup: FormGroup;
  promptsFormGroup: FormGroup;

  private _userSubscription: Subscription;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _user: UserService,
    private _error: ErrorService,
    private _auth: AuthService,
    private _formBuilder: FormBuilder,
    private _dialogRef: NzModalRef<LoginComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this._initEventRequirements();
    this._initCopy();
    this._initEmailLoginCopy();
    this._initFormGroups();
    this._initStages();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._userSubscription?.unsubscribe();
  }

  private _initEventRequirements() {
    const privateReqs = this.content?.privateUserInfoRequirements;
    const publicReqs = this.content?.userInfoRequirements;
    this.needsName = !!publicReqs?.displayName?.required;
    this.needsEmail = !!privateReqs?.email?.required;
  }

  private _initCopy() {
    if (this.loginCopy?.length > 0) return;

    this.loginCopy = 'Please enter your phone number.';

    if (this.content?.isEvent) {
      this.loginCopy = `${this.loginCopy} You'll receive messages about this event and can opt out at any time.`;
    } else if (this.content?.isDrop) {
      this.loginCopy = `${this.loginCopy} You can opt out at any time.`;
    }
  }

  private _initEmailLoginCopy() {
    if (this.emailLoginCopy?.length > 0) return;

    const contentRequiresPhoneNumber = this._contentRequiresPhoneNumberLogin();
    this.emailLoginCopy = contentRequiresPhoneNumber
      ? 'Please enter your email.'
      : 'Please log in with your email.';

    if (this.content?.isEvent) {
      this.emailLoginCopy = `${this.emailLoginCopy} You'll receive messages about this event and can opt out at any time.`;
    } else if (this.content?.isDrop) {
      this.emailLoginCopy = `${this.emailLoginCopy} You can opt out at any time.`;
    }
  }

  // Dynamically build the stages of the flow based on the requirements
  // of the input event
  private _contentRequiresPhoneNumberLogin() {
    const phoneNumberRequirmentExplicitlyEnabled =
      !!this.content?.privateUserInfoRequirements?.phoneNumber?.required;

    // Absence of the phoneNumber requirement is legacy and indicates
    // that the event expects phone number by default.
    const phoneNumberRequirmentImplicitlyEnabled =
      this.content?.privateUserInfoRequirements?.phoneNumber === undefined;

    return (
      phoneNumberRequirmentExplicitlyEnabled ||
      phoneNumberRequirmentImplicitlyEnabled
    );
  }

  private _contentRequiresEmailLogin() {
    return !!this.content?.privateUserInfoRequirements?.email?.required;
  }

  private _initLoginStages() {
    const authUser = this._auth.currentUser;

    const authedUserHasPhoneNumber = !!authUser?.phoneNumber;
    const authedUserHasEmail = !!authUser?.email;

    const requiresEmail = this._contentRequiresEmailLogin();
    const requiresPhoneNumber = this._contentRequiresPhoneNumberLogin();

    if (requiresPhoneNumber && !authedUserHasPhoneNumber)
      this.stages.push('phone', 'code');

    if (requiresEmail && !authedUserHasEmail)
      this.stages.push('email', 'emailCode');
  }

  private _skipEmailVerificationCodeStage() {
    this.stages = this.stages?.filter((stage) => stage !== 'emailCode');
  }

  private _skipEmailStages() {
    this.stages = this.stages?.filter(
      (stage) => stage !== 'emailCode' && stage !== 'email'
    );
  }

  private _initStages() {
    const prompts = this.content?.prompts;

    const needsPrompts = (prompts?.length ?? 0) > 0;
    const needsPurchase = this.content?.isPaid;
    const needsProfile = this.needsName || this.needsEmail;

    // Stage 0: Ticketing
    if (needsPurchase) this.stages.push('tickets');

    // Stage 1: Login Requirements
    this._initLoginStages();

    if (needsProfile) {
      this.stages.push('profile');

      this._userSubscription = combineLatest([
        this._user.currentUserProfile$(),
        this._user.currentUserMetadata$()
      ]).subscribe(([userProfile, userMetadata]) => {
        this.userProfile = userProfile;
        this.userMetadata = userMetadata;
        this.hasLoadedProfile = true;
        this.profileFormGroup.patchValue({
          email: this.userMetadata?.email,
          displayName: this.userProfile?.displayName,
          photoURL: this.userProfile?.photoURL
        });
        this.profileFormGroup.updateValueAndValidity();
        this._cdr.detectChanges();
      });
    }

    if (needsPrompts) this.stages.push('prompts');

    if (needsPurchase) {
      this.stages.push('purchase');

      this._user
        .getUserContent$(this.content.contentId)
        .pipe(this.takeUntilDestroy)
        .subscribe((e) => {
          this.userHasPaid =
            e?.paymentStatus === 'confirmed' || e?.paymentStatus === 'pending';

          const ticket = e?.userContentPayment?.ticket;
          if (ticket) {
            this.selectedTicket = ticket;
            this.ticketFormGroup.patchValue({ ticket: ticket?.label });
            this.recalculateTotalPrice(false);
            this._cdr.detectChanges();
          }
        });
    }

    this._cdr.detectChanges();
  }

  // Build form groups for each stage
  private _initFormGroups(): void {
    this.selectedTicket = this.content?.tickets?.[0];
    this.ticketFormGroup = this._formBuilder.group({
      ticket: [this.selectedTicket?.label, Validators.required]
    });

    if (this.selectedTicket) this.recalculateTotalPrice(false);

    this.phoneFormGroup = this._formBuilder.group(
      {
        phoneNumber: ['', Validators.required],
        countryCode: ['US', Validators.required]
      },
      { validators: this._isPhoneNumberValid }
    );

    this.emailFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.codeFormGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });

    this.emailCodeFormGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });

    this.profileFormGroup = this._formBuilder.group({
      email: [
        '',
        this.needsEmail ? [Validators.required, Validators.email] : []
      ],
      displayName: ['', this.needsName ? [Validators.required] : []],
      photoURL: ['', []],
      hasOptedIntoNewsletter: [true]
    });

    this.promptsFormGroup = this._formBuilder.group(
      this.content?.prompts?.reduce((prompts, prompt, index) => {
        return {
          ...prompts,
          [index]: ['', prompt?.required ? Validators.required : []]
        };
      }, {}) ?? {}
    );
  }

  // Convenience methods for toggling UI state
  private _setLoading(loading: boolean) {
    this.isLoading = loading;
    this._cdr.detectChanges();
  }

  private _setError(error: any) {
    this.error = this._error.formatError(error);
    this._cdr.detectChanges();
  }

  // Form group validator to ensure phone number checks out
  private _isPhoneNumberValid(group: FormGroup): ValidationErrors | null {
    const { phoneNumber, countryCode } = group.value;
    return isPhoneNumberValid(phoneNumber, countryCode);
  }

  // Comparator for keyvalue pipe to force Angular to preserve
  // the order of the country codes map
  preserveOrder = (a, b): number => 0;

  handleCountryCodeChanged(code) {
    const country = VALID_COUNTRY_CODES[code];
    this.phonePlaceholder = country?.placeholder;
  }

  recalculateTotalPrice(updateSelectedTicket = true) {
    if (updateSelectedTicket) this._updateSelectedTicket();

    this.subtotal = this.selectedTicket?.price;
    this.total = Math.ceil(
      (this.subtotal + STRIPE_BASE_FEE) / (1 - STRIPE_PERCENTAGE_FEE)
    );
    this.fees = this.total - this.subtotal;
    this._cdr.detectChanges();
  }

  handleDidCheckCheckbox(checkedBoxes: string[], promptIdx) {
    this.promptsFormGroup.get(`${promptIdx}`).setValue(checkedBoxes.join(', '));
  }

  // Close without approving
  close() {
    this._dialogRef.close(false);
  }

  get shouldShowNewsletterOptIn(): boolean {
    return !!this.content?.buttonLabels?.newsletter;
  }

  // Advance to the next stage in the flow
  private _advance() {
    if (this.currentIndex < this.stages.length - 1) {
      this.currentIndex++;
      this._cdr.detectChanges();
      return;
    }

    this._dialogRef.close(this.userContent);
  }

  private _updateSelectedTicket() {
    const selectedTicket = this.ticketFormGroup.get('ticket')?.value;
    const ticketIndex = this.content?.tickets
      ?.map((ticket) => ticket?.label)
      ?.indexOf(selectedTicket);
    this.selectedTicket = this.content?.tickets?.[ticketIndex];
  }

  // Step 0
  async submitTicketSelection() {
    if (!this.ticketFormGroup.valid) return;
    this._updateSelectedTicket();
    this._advance();
  }

  async submitEmail() {
    if (!this.emailFormGroup.valid) return;
    this._setLoading(true);
    this.email = this.emailFormGroup.value.email;

    // Initiate the signin flow with transformed number
    try {
      const result = await this._auth.initiateEmailSignInOrLinkingFlow({
        email: this.email
      });

      // User has already been authorized and we can skip the email verification code process.
      if (result.credential !== null) this._skipEmailVerificationCodeStage();
      this.emailVerificationCodeMedium = result.medium;

      this._setLoading(false);
      this._advance();
    } catch (e) {
      this._setLoading(false);
      this._setError(e);
      this.phoneNumber = null;
    }
  }

  async resendPhoneCode() {
    if (!this.phoneNumber) return;

    this.isResendingCode = true;
    this._cdr.detectChanges();

    try {
      await this._auth.initiatePhoneSignInFlow(this.phoneNumber);
      this.isResendingCode = false;
      this._cdr.detectChanges();
    } catch (e) {
      this.isResendingCode = true;
      this.phoneNumber = null;
      this._setError(e);
    }
  }

  // Step 1
  async submitPhone() {
    if (!this.phoneFormGroup.valid) return;
    this._setLoading(true);
    const { countryCode, phoneNumber } = this.phoneFormGroup.value;
    const adjustedNumber = phoneNumber.replace(/[^0-9]+/g, '');
    const countryPrefix = VALID_COUNTRY_CODES[countryCode]?.value;
    this.phoneNumber = `+${countryPrefix}${adjustedNumber}`;

    // Initiate the signin flow with transformed number
    try {
      await this._auth.initiatePhoneSignInFlow(this.phoneNumber);
      this._setLoading(false);
      this._advance();
    } catch (e) {
      this._setLoading(false);
      this._setError(e);
      this.phoneNumber = null;
    }
  }

  // Step 2
  async submitCode() {
    if (!this.codeFormGroup.valid || !this.phoneNumber) return;
    const verificationCode = `${this.codeFormGroup.value.verificationCode}`;
    this._setLoading(true);

    try {
      const credential = await this._auth.completePhoneLogin({
        phoneNumber: this.phoneNumber,
        verificationCode
      });

      // If the newly authorized user has an email of any kind, we can skip the email
      // authorization stages.
      if (credential.user.email) this._skipEmailStages();
      this._setLoading(false);
      this._advance();
    } catch (e) {
      this._setLoading(false);
      this._setError(e);
    }
  }

  async submitEmailCode() {
    if (!this.emailCodeFormGroup.valid || !this.email) return;
    const verificationCode = `${this.emailCodeFormGroup.value.verificationCode}`;
    this._setLoading(true);

    try {
      await this._auth.completeEmailLogin({
        email: this.email,
        verificationCode
      });
      this._setLoading(false);
      this._advance();
    } catch (e) {
      this._setLoading(false);
      this._setError(e);
    }
  }

  // Submit user info step
  async submitProfile() {
    if (!this.profileFormGroup.valid) return;
    this._setLoading(true);
    this._userSubscription?.unsubscribe();
    const { displayName, email, hasOptedIntoNewsletter } =
      this.profileFormGroup.value;
    const needsNameAndUserHasNoneOrEntered =
      this.needsName && (!this.userProfile?.displayName || this.isEditingName);

    const promises: Promise<any>[] = [
      needsNameAndUserHasNoneOrEntered &&
        this._user.setUserProfile({
          displayName
          // TODO: AVATAR
        })
    ];

    // Newsletter opt in
    if (this.shouldShowNewsletterOptIn && hasOptedIntoNewsletter)
      this.userContent.hasOptedIntoNewsletter = true;

    /*
     * The below flow is not entirely necessary. This is only userful if we want to allow
     * users to change their email address in the profile section. But truthfully, this is unneccesary
     * as selecting the email is baked into the auth flow. I(Steven): Only left this in because I did not
     * want to incidentally break the state machine.
     */
    const verifiedEmails = [
      ...(this.userMetadata?.verifiedEmails ?? []),
      ...(this.userMetadata?.email && this.userMetadata?.emailVerified
        ? [this.userMetadata?.email]
        : [])
    ];

    if (email && verifiedEmails.includes(email)) {
      // If the email address is a federated identifier. We promote
      // it to the primary.
      promises.push(this._auth.promoteSecondOrderEmail({ email }));
    } else if (email && email !== this.userMetadata?.email) {
      // If the user decides to change the email in the profile section and the email
      // differs from what's on the account currently. We can send a non-blocking
      // magic link.
      promises.push(
        this._auth.initiateEmailSignInOrLinkingFlow({
          email,
          sendMagicLink: true
        })
      );
    } else if (
      !this.stages.includes('email') &&
      this.userMetadata?.email &&
      !this.userMetadata?.emailVerified
    ) {
      // If the email stages were skipped and the email on the user record is not verified
      // that means that the 'target' auth user already had an unauthorized email on file
      // we now issue a magic link to ask them to verify their email address.
      promises.push(
        this._auth.initiateEmailSignInOrLinkingFlow({
          email: this.userMetadata?.email,
          sendMagicLink: true
        })
      );
    }

    Promise.all(promises)
      .then(([a, b]) => {
        this._setLoading(false);
        this._advance();
      })
      .catch((e) => {
        this._setLoading(false);
        this._setError(e);
      });
  }

  // Collect prompt responses step
  async submitPrompts() {
    if (!this.promptsFormGroup.valid) return;
    const prompts = this.content?.prompts ?? [];
    const responses = Object.values(this.promptsFormGroup.value) as string[];
    this.userContent.promptResponses = prompts.map<IPromptResponse>(
      (prompt, idx) => {
        return {
          prompt: prompt?.prompt,
          response: responses[idx]
        };
      }
    );

    this._advance();
  }

  skipPayment() {
    if (this.currentStage === 'purchase') this._advance();
  }

  paymentCallback() {
    const component = this;
    return (success: boolean, error?: any) => {
      if (success) return component._advance();

      this.error = error;
      this._cdr.detectChanges();
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzInputModule } from 'ng-zorro-antd/input';

import { RsvpConfirmationViewComponent } from '../views/rsvp-confirmation-view';
import { DirectivesModule } from './directives.module';

@NgModule({
    declarations: [
        RsvpConfirmationViewComponent,
    ],
    imports: [
        CommonModule,
        ClipboardModule,
        DirectivesModule,
        NzResultModule,
        NzButtonModule,
        NzIconModule,
        NzInputModule
    ],
    exports: [
        RsvpConfirmationViewComponent
    ]
})
export class RsvpConfirmationViewModule { }

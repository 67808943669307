import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes
} from '@angular/fire/storage';

// 3rd party
import { firstValueFrom } from 'rxjs';
import { debounceTime, filter, pairwise } from 'rxjs/operators';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalService } from 'ng-zorro-antd/modal';

// App
import { BaseComponent } from '../../models';
import { IDropShadowValues, IFont, IThemeDTO } from '../../types';
import { CUSTOM_FONTS, DROP_SHADOW_EXPLAINER_URL } from '../../constants';
import { parseDropShadowString, uuidv4 } from '../../tools';
import { UiService, SlugService } from '../../services';

export type ThemeChange = {
  theme: IThemeDTO;
  isDirty: boolean;
  isValid: boolean;
};

type DropShadowFieldsType =
  | 'card'
  | 'button'
  | 'secondaryButton'
  | 'customCardsStyle'
  | 'customButtonsStyle';

type TypeLevel = {
  identifier: string;
  label: string;
  showSize: boolean;
  tooltip?: string;
};

@Component({
  selector: 'lib-theme-builder-view',
  templateUrl: './theme-builder-view.component.html',
  styleUrls: ['./theme-builder-view.component.less']
})
export class ThemeBuilderViewComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() theme: IThemeDTO;
  @Output() themeChanged = new EventEmitter<ThemeChange>();
  availableFonts = CUSTOM_FONTS;
  appearanceForm: FormGroup;
  isUploadingBackground = false;
  isUploadingFavicon = false;
  dropShadowExplainerUrl = DROP_SHADOW_EXPLAINER_URL;
  canAdjustFavicon = true;
  cardDropShadowParts: IDropShadowValues;
  primaryButtonDropShadowParts: IDropShadowValues;
  secondaryButtonDropShadowParts: IDropShadowValues;
  customCardsStyleDropShadowParts: {
    [key: string]: IDropShadowValues;
  };
  customButtonsStyleDropShadowParts: {
    [key: string]: IDropShadowValues;
  };
  customFontConfigComponent;
  typeLevels: TypeLevel[] = [
    {
      identifier: 'font',
      label: 'Default font',
      showSize: false,
      tooltip: 'Fallback font used for all typography'
    },
    {
      identifier: 'h1',
      label: 'Header 1',
      showSize: true
    },
    {
      identifier: 'h2',
      label: 'Header 2',
      showSize: true
    },
    {
      identifier: 'h3',
      label: 'Header 3',
      showSize: true
    },
    {
      identifier: 'h4',
      label: 'Header 4',
      showSize: true
    },
    {
      identifier: 'h5',
      label: 'Header 5',
      showSize: true
    },
    {
      identifier: 'h6',
      label: 'Header 6',
      showSize: true
    },
    {
      identifier: 'p',
      label: 'Paragraph',
      showSize: true
    }
  ];

  imagePaths: Partial<NzUploadFile>[] = [];
  backgroundMode: 'image' | 'color' | 'gradient';
  customContentStyleComponent;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _storage: Storage,
    private _slug: SlugService,
    private _dialog: NzModalService,
    private _formBuilder: FormBuilder,
    private _ui: UiService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initForm();

    if (isPlatformBrowser(this._platform)) {
      this.appearanceForm.valueChanges
        .pipe(debounceTime(10), this.takeUntilDestroy)
        .subscribe((theme: IThemeDTO) => {
          this.themeChanged.emit({
            theme,
            isDirty: this.appearanceForm.dirty,
            isValid: this.appearanceForm.valid
          });
        });

      this._handleFontFieldsChangeListener();

      this._slug.getCurrentSlugMetadata().then((meta) => {
        const isBrandPlan = meta?.billingInfo?.projectTier === 'basic';
        this.canAdjustFavicon =
          !meta || (isBrandPlan && meta?.hasPaymentMethod);
        if (!this.canAdjustFavicon) {
          this.appearanceForm.get('metadata.favicon').disable();
        }
      });
    }
  }

  ngOnChanges(): void {
    this.appearanceForm?.markAsPristine();
    this.appearanceForm?.patchValue(this.theme);
    const { backgroundImage, backgroundGradient, backgroundColor } =
      this.theme?.layout;

    if (!!backgroundImage) {
      this.backgroundMode = 'image';
    } else if (!!backgroundGradient) {
      this.backgroundMode = 'gradient';
    } else if (!!backgroundColor) {
      this.backgroundMode = 'color';
    } else {
      this.backgroundMode = 'color';
    }

    if (
      this.backgroundMode === 'image' &&
      this.theme?.layout?.backgroundImage
    ) {
      this.imagePaths = [
        {
          url: this.theme?.layout?.backgroundImage,
          uid: this.theme?.layout?.backgroundImage
        }
      ];
    }

    const themeCustomFonts = Object.values(this.theme?.fonts ?? {}).filter(
      (f) => f?.isCustom
    );

    this.availableFonts = [...themeCustomFonts, ...CUSTOM_FONTS];

    this.cardDropShadowParts = parseDropShadowString(
      this.theme?.card?.dropShadow
    );
    this.primaryButtonDropShadowParts = parseDropShadowString(
      this.theme?.button?.dropShadow
    );
    this.secondaryButtonDropShadowParts = parseDropShadowString(
      this.theme?.secondaryButton?.dropShadow
    );
    this.customCardsStyleDropShadowParts =
      this.customCardsStyleDropShadowParts ?? {};
    this.customButtonsStyleDropShadowParts =
      this.customButtonsStyleDropShadowParts ?? {};
    if (this.theme?.customCards?.length) {
      this.theme?.customCards.forEach((item, idx) => {
        this.customCardsStyleDropShadowParts[idx.toString()] =
          parseDropShadowString(item?.dropShadow);
      });
    }
    if (this.theme?.customButtons?.length) {
      this.theme?.customButtons.forEach((item, idx) => {
        this.customButtonsStyleDropShadowParts[idx.toString()] =
          parseDropShadowString(item?.dropShadow);
      });
    }
    this._buildCustomArray('cards');
    this._buildCustomArray('buttons');
  }

  private _initForm() {
    this.appearanceForm = this._formBuilder.group({
      metadata: this._formBuilder.group({
        empty: [this.theme?.metadata?.empty, Validators.maxLength(280)],
        favicon: [this.theme?.metadata?.favicon]
      }),
      layout: this._formBuilder.group({
        backgroundImage: [this.theme?.layout?.backgroundImage],
        backgroundGradient: [this.theme?.layout?.backgroundGradient],
        backgroundColor: [
          this.theme?.layout?.backgroundColor,
          Validators.maxLength(30)
        ]
      }),
      fonts: this._formBuilder.group({
        font: [this.theme?.fonts?.font],
        h1: [this.theme?.fonts?.h1],
        h2: [this.theme?.fonts?.h2],
        h3: [this.theme?.fonts?.h3],
        h4: [this.theme?.fonts?.h4],
        h5: [this.theme?.fonts?.h5],
        h6: [this.theme?.fonts?.h6],
        p: [this.theme?.fonts?.p]
      }),
      text: this._formBuilder.group({
        color: [this.theme?.text?.color, Validators.maxLength(30)]
      }),
      link: this._formBuilder.group({
        textColor: [this.theme?.link?.textColor, Validators.maxLength(30)],
        hoverTextColor: [
          this.theme?.link?.hoverTextColor,
          Validators.maxLength(30)
        ]
      }),
      card: this._formBuilder.group({
        borderRadius: [
          this.theme?.card?.borderRadius,
          Validators.maxLength(16)
        ],
        borderWidth: [this.theme?.card?.borderWidth, Validators.maxLength(16)],
        dropShadow: [this.theme?.card?.dropShadow, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.card?.backgroundColor,
          Validators.maxLength(30)
        ],
        textColor: [this.theme?.card?.textColor, Validators.maxLength(30)],
        borderColor: [this.theme?.card?.borderColor, Validators.maxLength(30)]
      }),
      customCards: this._formBuilder.array([]),
      button: this._formBuilder.group({
        borderRadius: [
          this.theme?.button?.borderRadius,
          Validators.maxLength(16)
        ],
        borderWidth: [
          this.theme?.button?.borderWidth,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.button?.dropShadow, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.button?.backgroundColor,
          Validators.maxLength(30)
        ],
        textColor: [this.theme?.button?.textColor, Validators.maxLength(30)],
        borderColor: [
          this.theme?.button?.borderColor,
          Validators.maxLength(30)
        ],
        hoverBackgroundColor: [
          this.theme?.button?.hoverBackgroundColor,
          Validators.maxLength(30)
        ],
        hoverTextColor: [
          this.theme?.button?.hoverTextColor,
          Validators.maxLength(30)
        ],
        hoverBorderColor: [
          this.theme?.button?.hoverBorderColor,
          Validators.maxLength(30)
        ]
      }),
      secondaryButton: this._formBuilder.group({
        borderRadius: [
          this.theme?.secondaryButton?.borderRadius,
          Validators.maxLength(16)
        ],
        borderWidth: [
          this.theme?.secondaryButton?.borderWidth,
          Validators.maxLength(16)
        ],
        dropShadow: [
          this.theme?.secondaryButton?.dropShadow,
          Validators.maxLength(30)
        ],
        backgroundColor: [
          this.theme?.secondaryButton?.backgroundColor,
          Validators.maxLength(30)
        ],
        textColor: [
          this.theme?.secondaryButton?.textColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.secondaryButton?.borderColor,
          Validators.maxLength(30)
        ],
        hoverBackgroundColor: [
          this.theme?.secondaryButton?.hoverBackgroundColor,
          Validators.maxLength(30)
        ],
        hoverTextColor: [
          this.theme?.secondaryButton?.hoverTextColor,
          Validators.maxLength(30)
        ],
        hoverBorderColor: [
          this.theme?.secondaryButton?.hoverBorderColor,
          Validators.maxLength(30)
        ]
      }),
      customButtons: this._formBuilder.array([])
    });
  }

  get isCustomFont(): boolean {
    return this.appearanceForm?.value?.fonts.font?.isCustom;
  }

  get currentImageUrl(): string {
    return this.appearanceForm.get('layout.backgroundImage').value;
  }

  onRemove = (file: NzUploadFile) => {
    this.updateImage();
    this.imagePaths = [];
    return false;
  };

  updateImage(url: string = null) {
    this.appearanceForm.patchValue({
      layout: {
        backgroundImage: url
      }
    });
    this.appearanceForm.markAsDirty();
  }

  // Called by file picker event emitter in UI
  uploadFile = (file: NzUploadFile): boolean => {
    if (!file) return false;
    this.isUploadingBackground = true;

    const extension = file.name?.split('.')?.pop();
    const fileName = `${uuidv4()}.${extension}`;
    const storageRef = ref(this._storage, `images/${fileName}`);
    uploadBytes(storageRef, file as any)
      .then(async (snapshot) => {
        this.isUploadingBackground = false;
        const url = await getDownloadURL(snapshot.ref);
        this.imagePaths = [{ url, uid: url }];
        this.isUploadingBackground = false;
        this.updateImage(url);
      })
      .catch((e) => {
        this.isUploadingBackground = false;
        this.updateImage();
      });
    return false;
  };

  uploadFavicon = (file: NzUploadFile): boolean => {
    if (!file) return false;
    this.isUploadingFavicon = true;

    const extension = file.name?.split('.')?.pop();
    const fileName = `${uuidv4()}.${extension}`;
    const storageRef = ref(this._storage, `favicon/${fileName}`);
    uploadBytes(storageRef, file as any)
      .then(async (snapshot) => {
        this.isUploadingFavicon = false;
        const url = await getDownloadURL(snapshot.ref);
        this.appearanceForm.get('layout.favicon').patchValue(url);
        this.appearanceForm.markAsDirty();
      })
      .catch((e) => {
        this.isUploadingFavicon = false;
        this.appearanceForm.get('layout.favicon').patchValue(undefined);
        this.appearanceForm.markAsDirty();
      });
    return false;
  };

  async showCustomFontConfigDialog(field: string) {
    if (!this.customFontConfigComponent) {
      this._ui.setLoading(true);
      const { CustomFontConfigComponent } = await import(
        '../../entry-points/custom-font-config'
      );

      this.customFontConfigComponent = CustomFontConfigComponent;
      this._ui.setLoading(false);
    }

    const component = this.customFontConfigComponent;
    const ref = await this._dialog.create<typeof component, IFont>({
      nzContent: component,
      nzClosable: false,
      nzOkDisabled: true,
      nzOkText: 'Done',
      nzComponentParams: {
        currentFont: this.appearanceForm?.value?.fonts[field]
      }
    });

    ref.afterClose.pipe(this.takeUntilDestroy).subscribe((font) => {
      if (font) {
        const control = this.appearanceForm.get('fonts.' + field);
        control.patchValue(font);
        control.markAsDirty();
        const existingFonts = this.availableFonts.filter(
          (f) => f?.importUrl === font?.importUrl
        );
        if (!existingFonts?.length) {
          this.availableFonts.unshift(font);
        }
      }
    });
  }

  fontCompare = (f1: IFont, f2: IFont) =>
    f1?.importUrl === f2?.importUrl || f1?.displayName === f2?.displayName;

  private _getColor(key: string) {
    return this.appearanceForm.get(key)?.value;
  }
  private _setColor(key: string, value: string) {
    const control = this.appearanceForm.get(key);
    control?.setValue(value);
    control?.markAsDirty();
  }

  get layoutBackgroundColor(): string {
    return this._getColor('layout.backgroundColor');
  }
  set layoutBackgroundColor(value: string) {
    this._setColor('layout.backgroundColor', value);
  }

  get textColor(): string {
    return this._getColor('text.color');
  }
  set textColor(value: string) {
    this._setColor('text.color', value);
  }

  get linkTextColor(): string {
    return this._getColor('link.textColor');
  }
  set linkTextColor(value: string) {
    this._setColor('link.textColor', value);
  }

  get linkHoverTextColor(): string {
    return this._getColor('link.hoverTextColor');
  }
  set linkHoverTextColor(value: string) {
    this._setColor('link.hoverTextColor', value);
  }

  get buttonBackgroundColor(): string {
    return this._getColor('button.backgroundColor');
  }
  set buttonBackgroundColor(value: string) {
    this._setColor('button.backgroundColor', value);
  }

  get secondaryButtonBackgroundColor(): string {
    return this._getColor('secondaryButton.backgroundColor');
  }
  set secondaryButtonBackgroundColor(value: string) {
    this._setColor('secondaryButton.backgroundColor', value);
  }

  get buttonTextColor(): string {
    return this._getColor('button.textColor');
  }
  set buttonTextColor(value: string) {
    this._setColor('button.textColor', value);
  }

  get secondaryButtonTextColor(): string {
    return this._getColor('secondaryButton.textColor');
  }
  set secondaryButtonTextColor(value: string) {
    this._setColor('secondaryButton.textColor', value);
  }

  get buttonBorderColor(): string {
    return this._getColor('button.borderColor');
  }
  set buttonBorderColor(value: string) {
    this._setColor('button.borderColor', value);
  }

  get secondaryButtonBorderColor(): string {
    return this._getColor('secondaryButton.borderColor');
  }
  set secondaryButtonBorderColor(value: string) {
    this._setColor('secondaryButton.borderColor', value);
  }

  get buttonHoverBackgroundColor(): string {
    return this._getColor('button.hoverBackgroundColor');
  }
  set buttonHoverBackgroundColor(value: string) {
    this._setColor('button.hoverBackgroundColor', value);
  }

  get secondaryButtonHoverBackgroundColor(): string {
    return this._getColor('secondaryButton.hoverBackgroundColor');
  }
  set secondaryButtonHoverBackgroundColor(value: string) {
    this._setColor('secondaryButton.hoverBackgroundColor', value);
  }

  get buttonHoverTextColor(): string {
    return this._getColor('button.hoverTextColor');
  }
  set buttonHoverTextColor(value: string) {
    this._setColor('button.hoverTextColor', value);
  }

  get secondaryButtonHoverTextColor(): string {
    return this._getColor('secondaryButton.hoverTextColor');
  }
  set secondaryButtonHoverTextColor(value: string) {
    this._setColor('secondaryButton.hoverTextColor', value);
  }

  get buttonHoverBorderColor(): string {
    return this._getColor('button.hoverBorderColor');
  }
  set buttonHoverBorderColor(value: string) {
    this._setColor('button.hoverBorderColor', value);
  }

  get secondaryButtonHoverBorderColor(): string {
    return this._getColor('secondaryButton.hoverBorderColor');
  }
  set secondaryButtonHoverBorderColor(value: string) {
    this._setColor('secondaryButton.hoverBorderColor', value);
  }

  get cardBackgroundColor(): string {
    return this._getColor('card.backgroundColor');
  }
  set cardBackgroundColor(value: string) {
    this._setColor('card.backgroundColor', value);
  }

  get cardTextColor(): string {
    return this._getColor('card.textColor');
  }
  set cardTextColor(value: string) {
    this._setColor('card.textColor', value);
  }

  get cardBorderColor(): string {
    return this._getColor('card.borderColor');
  }
  set cardBorderColor(value: string) {
    this._setColor('card.borderColor', value);
  }

  get faviconUrl(): string {
    return this.appearanceForm.get('layout.favicon')?.value;
  }

  // Card DropShadow
  get cardDropShadowCtrl(): FormControl {
    return this.appearanceForm.get('card.dropShadow') as FormControl;
  }
  get cardDropShadowColor(): string {
    return this._extractColor('card');
  }

  // Primary button dropShadow
  get primaryButtonDropShadowCtrl(): FormControl {
    return this.appearanceForm.get('button.dropShadow') as FormControl;
  }
  get primaryButtonDropShadowColor(): string {
    return this._extractColor('button');
  }

  // Secondary button dropShadow
  get secondaryButtonDropShadowCtrl(): FormControl {
    return this.appearanceForm.get('secondaryButton.dropShadow') as FormControl;
  }
  get secondaryButtonDropShadowColor(): string {
    return this._extractColor('secondaryButton');
  }

  get customCardsStylesFormArray(): FormArray {
    return this.appearanceForm.get('customCards') as FormArray;
  }
  get customButtonsStylesFormArray(): FormArray {
    return this.appearanceForm.get('customButtons') as FormArray;
  }

  public getCustomCardStylesDropShadowColorAtIndex(
    index: number,
    type: 'cards' | 'buttons'
  ): string {
    return this._extractColor(
      type === 'cards' ? 'customCardsStyle' : 'customButtonsStyle',
      index
    );
  }

  public setCardBackgroundColorAtIndex(
    index: number,
    value: string,
    type: 'cards' | 'buttons'
  ): void {
    const control =
      type === 'cards'
        ? (this.customCardsStylesFormArray
            ?.at(index)
            ?.get('backgroundColor') as FormControl)
        : (this.customButtonsStylesFormArray
            ?.at(index)
            ?.get('backgroundColor') as FormControl);
    if (control) {
      control?.setValue(value);
      control?.markAsDirty();
    }
  }

  public getCardBackgroundColorAtIndex(
    index: number,
    type: 'cards' | 'buttons'
  ): string {
    return type === 'cards'
      ? this.customCardsStylesFormArray?.at(index)?.get('backgroundColor')
          ?.value
      : this.customButtonsStylesFormArray?.at(index)?.get('backgroundColor')
          ?.value;
  }

  public setTextColorAtIndex(
    index: number,
    value: string,
    type: 'cards' | 'buttons'
  ): void {
    const control =
      type === 'cards'
        ? (this.customCardsStylesFormArray
            ?.at(index)
            ?.get('textColor') as FormControl)
        : (this.customButtonsStylesFormArray
            ?.at(index)
            ?.get('textColor') as FormControl);
    if (control) {
      control?.setValue(value);
      control?.markAsDirty();
    }
  }

  public getTextColorAtIndex(index: number, type: 'cards' | 'buttons'): string {
    return (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('textColor')?.value;
  }

  public setBorderColorAtIndex(
    index: number,
    value: string,
    type: 'cards' | 'buttons'
  ): void {
    const control = (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('borderColor') as FormControl;
    if (control) {
      control?.setValue(value);
      control?.markAsDirty();
    }
  }

  public getBorderColorAtIndex(
    index: number,
    type: 'cards' | 'buttons'
  ): string {
    return (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('borderColor')?.value;
  }

  public setHoverBgColorAtIndex(
    index: number,
    value: string,
    type: 'cards' | 'buttons'
  ): void {
    const control = (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('hoverBackgroundColor') as FormControl;
    if (control) {
      control?.setValue(value);
      control?.markAsDirty();
    }
  }

  public getHoverBgColorAtIndex(
    index: number,
    type: 'cards' | 'buttons'
  ): string {
    return (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('hoverBackgroundColor')?.value;
  }

  public setHoverColorAtIndex(
    index: number,
    value: string,
    type: 'cards' | 'buttons'
  ): void {
    const control = (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('hoverTextColor') as FormControl;
    if (control) {
      control?.setValue(value);
      control?.markAsDirty();
    }
  }

  public getHoverColorAtIndex(
    index: number,
    type: 'cards' | 'buttons'
  ): string {
    return (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('hoverTextColor')?.value;
  }

  public setHoverBorderColorAtIndex(
    index: number,
    value: string,
    type: 'cards' | 'buttons'
  ): void {
    const control = (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('hoverBorderColor') as FormControl;
    if (control) {
      control?.setValue(value);
      control?.markAsDirty();
    }
  }

  public getHoverBorderColorAtIndex(
    index: number,
    type: 'cards' | 'buttons'
  ): string {
    return (
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray
    )
      ?.at(index)
      ?.get('hoverBorderColor')?.value;
  }

  handleShadowChange(
    shadow: string,
    input: DropShadowFieldsType,
    index?: number
  ) {
    const parts = parseDropShadowString(shadow);
    console.log(input, parts);

    if (input === 'card') {
      this.cardDropShadowCtrl.patchValue(shadow);
      this.cardDropShadowParts = parts;
    } else if (input === 'button') {
      this.primaryButtonDropShadowCtrl.patchValue(shadow);
      this.primaryButtonDropShadowParts = parts;
    } else if (input === 'secondaryButton') {
      this.secondaryButtonDropShadowCtrl.patchValue(shadow);
      this.secondaryButtonDropShadowParts = parts;
    } else if (input === 'customCardsStyle') {
      this.customCardsStylesFormArray
        .at(index)
        ?.get('dropShadow')
        ?.patchValue(shadow);
      this.secondaryButtonDropShadowParts = parts;
      this.customCardsStyleDropShadowParts[index.toString()] = parts;
    } else if (input === 'customButtonsStyle') {
      this.customButtonsStylesFormArray
        .at(index)
        ?.get('dropShadow')
        ?.patchValue(shadow);
      this.customButtonsStyleDropShadowParts[index.toString()] = parts;
    }

    this.appearanceForm.markAsDirty();
  }

  onDropShadowInputChange(
    color: any,
    field: DropShadowFieldsType,
    index?: number
  ): void {
    if (field === 'card') {
      this.cardDropShadowParts = {
        ...this.cardDropShadowParts,
        color
      };

      const { h, v, blur } = this.cardDropShadowParts;
      const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
        color ?? '#000000'
      }`;
      this.cardDropShadowCtrl.patchValue(shadow);
    } else if (field === 'button') {
      this.primaryButtonDropShadowParts = {
        ...this.primaryButtonDropShadowParts,
        color
      };

      const { h, v, blur } = this.primaryButtonDropShadowParts;
      const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
        color ?? '#000000'
      }`;
      this.primaryButtonDropShadowCtrl.patchValue(shadow);
    } else if (field === 'secondaryButton') {
      this.secondaryButtonDropShadowParts = {
        ...this.secondaryButtonDropShadowParts,
        color
      };

      const { h, v, blur } = this.secondaryButtonDropShadowParts;
      const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
        color ?? '#000000'
      }`;
      this.secondaryButtonDropShadowCtrl.patchValue(shadow);
    } else if (field === 'customCardsStyle') {
      let parts = this.customCardsStyleDropShadowParts[index.toString()];
      parts = {
        ...(parts && { ...parts }),
        color
      };
      this.customCardsStyleDropShadowParts[index.toString()] = parts;
      const { h, v, blur } =
        this.customCardsStyleDropShadowParts[index.toString()];
      const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
        color ?? '#000000'
      }`;
      this.customCardsStylesFormArray
        .at(index)
        ?.get('dropShadow')
        ?.patchValue(shadow);
    } else if (field === 'customButtonsStyle') {
      let parts = this.customButtonsStyleDropShadowParts[index.toString()];
      parts = {
        ...(parts && { ...parts }),
        color
      };
      this.customButtonsStyleDropShadowParts[index.toString()] = parts;
      const { h, v, blur } =
        this.customButtonsStyleDropShadowParts[index.toString()];
      const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
        color ?? '#000000'
      }`;
      this.customButtonsStylesFormArray
        .at(index)
        ?.get('dropShadow')
        ?.patchValue(shadow);
    }

    this.appearanceForm.markAsDirty();
  }

  private _extractColor(field: DropShadowFieldsType, index?: number): string {
    if (field === 'card') {
      return this.cardDropShadowParts?.color ?? '#000000';
    } else if (field === 'button') {
      return this.primaryButtonDropShadowParts?.color ?? '#000000';
    } else if (field === 'secondaryButton') {
      return this.secondaryButtonDropShadowParts?.color ?? '#000000';
    } else if (field === 'customCardsStyle') {
      return (
        this.customCardsStyleDropShadowParts[index.toString()]?.color ??
        '#000000'
      );
    } else if (field === 'customButtonsStyle') {
      return (
        this.customButtonsStyleDropShadowParts[index.toString()]?.color ??
        '#000000'
      );
    }
    return '#000000';
  }

  trackBy(idx: number, item: number) {
    return item;
  }

  handleFontSizeChange(fontSize: number, field: string) {
    const control = this.appearanceForm.get('fonts')?.get(field);
    const currentFont: IFont = {
      ...control?.value,
      fontSize
    };
    control?.patchValue(currentFont);
    control?.markAsDirty();
  }

  private _handleFontFieldsChangeListener() {
    this.typeLevels.forEach((typeLevel) => {
      const ctrl = this.appearanceForm.get('fonts.' + typeLevel?.identifier);
      ctrl.valueChanges
        .pipe(
          pairwise(),
          filter(([prev, next]) => next?.importUrl !== prev?.importUrl),
          this.takeUntilDestroy
        )
        .subscribe(([prev, next]) =>
          ctrl.patchValue(
            {
              displayName: 'Default',
              ...next,
              fontSize: prev?.fontSize || next?.fontSize || null
            },
            { emitEvent: false }
          )
        );
    });
  }

  onGradientValueChange(value: string) {
    this.appearanceForm.get('layout.backgroundGradient')?.patchValue(value);
    this.appearanceForm.markAsDirty();
  }

  onBgModeOptionChanged(value: string) {
    const form = this.appearanceForm.get('layout') as FormGroup;
    if (value === 'image') {
      form?.patchValue({
        backgroundImage: this.theme?.layout?.backgroundImage ?? '',
        backgroundColor: null,
        backgroundGradient: null
      });
    } else if (value === 'color') {
      form?.patchValue({
        backgroundImage: null,
        backgroundColor: this.theme?.layout?.backgroundColor ?? '',
        backgroundGradient: null
      });
    } else if (value === 'gradient') {
      form?.patchValue({
        backgroundImage: null,
        backgroundColor: null,
        backgroundGradient: this.theme?.layout?.backgroundGradient ?? ''
      });
    }
  }

  async launchCustomContentStyleItemDialog(type: 'cards' | 'buttons') {
    if (!this.customContentStyleComponent) {
      this._ui.setLoading(true);
      const { CustomStyleItemComponent } = await import(
        '../../entry-points/custom-style-item'
      );

      this.customContentStyleComponent = CustomStyleItemComponent;
      this._ui.setLoading(false);
    }

    const component = this.customContentStyleComponent;
    const ref = await this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzClosable: false,
      nzOkText: 'Done',
      nzComponentParams: {}
    });

    firstValueFrom(ref.afterClose).then((res: any) => {
      if (res.name) {
        if (type === 'cards') {
          const formArray = this.appearanceForm.get('customCards') as FormArray;
          if (formArray) {
            formArray.push(
              this._formBuilder.group({
                name: [res.name, []],
                borderRadius: [0, Validators.maxLength(16)],
                borderWidth: [1, Validators.maxLength(16)],
                dropShadow: [undefined, Validators.maxLength(30)],
                backgroundColor: [undefined, Validators.maxLength(30)],
                textColor: [undefined, Validators.maxLength(30)],
                borderColor: [undefined, Validators.maxLength(30)]
              })
            );
            this.appearanceForm.markAsDirty();
          }
        } else {
          const formArray = this.appearanceForm.get(
            'customButtons'
          ) as FormArray;
          if (formArray) {
            formArray.push(
              this._formBuilder.group({
                name: [res.name, []],
                borderRadius: [0, Validators.maxLength(16)],
                borderWidth: [0, Validators.maxLength(16)],
                dropShadow: [undefined, Validators.maxLength(30)],
                backgroundColor: [undefined, Validators.maxLength(30)],
                textColor: [undefined, Validators.maxLength(30)],
                borderColor: [undefined, Validators.maxLength(30)],
                hoverBackgroundColor: [undefined, Validators.maxLength(30)],
                hoverTextColor: [undefined, Validators.maxLength(30)],
                hoverBorderColor: [undefined, Validators.maxLength(30)]
              })
            );
            this.appearanceForm.markAsDirty();
          }
        }
      }
    });
  }

  private _buildCustomArray(type: 'cards' | 'buttons') {
    const array: FormArray =
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray;
    array?.clear();
    if (type === 'cards') {
      (this.theme.customCards ?? []).forEach((item, idx) => {
        array.push(
          this._formBuilder.group({
            name: [item.name, []],
            borderRadius: [item.borderRadius, Validators.maxLength(16)],
            borderWidth: [item.borderWidth, Validators.maxLength(16)],
            dropShadow: [item.dropShadow, Validators.maxLength(30)],
            backgroundColor: [item.backgroundColor, Validators.maxLength(16)],
            textColor: [item.textColor, Validators.maxLength(30)],
            borderColor: [item.borderColor, Validators.maxLength(30)]
          })
        );
      });
    } else {
      (this.theme?.customButtons || []).forEach((item) => {
        array.push(
          this._formBuilder.group({
            name: [item.name, []],
            borderRadius: [item?.borderRadius, Validators.maxLength(16)],
            borderWidth: [item?.borderWidth, Validators.maxLength(16)],
            dropShadow: [item?.dropShadow, Validators.maxLength(30)],
            backgroundColor: [item?.backgroundColor, Validators.maxLength(30)],
            textColor: [item?.textColor, Validators.maxLength(30)],
            borderColor: [item?.borderColor, Validators.maxLength(30)],
            hoverBackgroundColor: [
              item?.hoverBackgroundColor,
              Validators.maxLength(30)
            ],
            hoverTextColor: [item?.hoverTextColor, Validators.maxLength(30)],
            hoverBorderColor: [item?.hoverBorderColor, Validators.maxLength(30)]
          })
        );
      });
    }
  }

  deleteCustomCardStyle(index: number, type: 'cards' | 'buttons') {
    type === 'cards'
      ? this.customCardsStylesFormArray?.removeAt(index)
      : this.customButtonsStylesFormArray?.removeAt(index);
    this.appearanceForm.markAsDirty();
  }
}

<!-- Show share button if the event/drop is published -->
<button *ngIf="content?.published && showText" [nzType]="type" [nzSize]="size" [nzDisabled]="isDisabled"
    aria-label="Send" nz-button class="share-button card-text-color" nz-dropdown [nzDropdownMenu]="shareMenu"
    (nzVisibleChange)="handleVisibleChanged($event)">
    <i nz-icon nzType="share-alt" *ngIf="showIcon"></i> Share
</button>

<button *ngIf="content?.published && !showText" [nzType]="type" [nzSize]="size" [nzDisabled]="isDisabled"
    aria-label="Send" nz-button class="share-button card-text-color" nz-dropdown [nzDropdownMenu]="shareMenu"
    (nzVisibleChange)="handleVisibleChanged($event)">
    <i nz-icon nzType="share-alt" *ngIf="showIcon"></i>
</button>

<!-- SHARE MENU -->
<nz-dropdown-menu #shareMenu="nzDropdownMenu">
    <ul nz-menu *ngIf="isLoading">
        <li nz-menu-item nzDisabled><i nz-icon nzType="loading"></i> Loading...</li>
    </ul>
    <ul nz-menu *ngIf="!isLoading">
        <li nz-menu-item *ngIf="canUseSystemShare" (click)="doShare()" [logEvent]="userTappedShare"
            [cubeData]="{ entityType: content.isEvent ? 'event' : content.isDrop ? 'drop' : content.isNewsletter ? 'newsletter' : '' }"
            [destination]="'cube'">
            <i nz-icon nzType="share-alt"></i>
            Share...
        </li>
        <li nz-menu-item [cdkCopyToClipboard]="userContent?.shortLinks?.referralPageShortLink || content?.shareUrl"
            (click)="doCopy()">
            <i nz-icon nzType="link"></i>
            Copy link
        </li>
        <li *ngIf="content.isEvent || content.isDrop" nz-submenu [nzTitle]="embedTitle">
            <ul *ngIf="content.isEvent">
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(eventCardVariant)" (click)="doCopy()">
                    Card
                </li>
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(eventMainVariant)" (click)="doCopy()">
                    Register button
                </li>
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(eventShareVariant)" (click)="doCopy()">
                    Share button
                </li>
            </ul>
            <ul *ngIf="content.isDrop">
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(dropCardVariant)" (click)="doCopy()">
                    Card
                </li>
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(dropMainVariant)" (click)="doCopy()">
                    Register button
                </li>
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(dropShareVariant)" (click)="doCopy()">
                    Share button
                </li>
                <li nz-menu-item [cdkCopyToClipboard]="embedStringForType(dropScrollVariant)" (click)="doCopy()">
                    Scroll-triggered popup
                </li>
            </ul>
        </li>
        <ng-template #embedTitle>
            <i nz-icon nzType="pic-center"></i> Copy embed code
        </ng-template>
    </ul>
</nz-dropdown-menu>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { EventCardViewComponent } from '../views/event-card-view';
import { EventMainButtonViewModule } from './event-main-button-view.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
  declarations: [EventCardViewComponent],
  imports: [
    CommonModule,
    EventMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    NzCardModule,
    NzSkeletonModule,
    NzAlertModule,
    NzIconModule,
    NzTagModule
  ],
  exports: [EventCardViewComponent]
})
export class EventCardViewModule { }

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// Stripe
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElements,
  StripeElementsOptions,
  PaymentRequest
} from '@stripe/stripe-js';

// App
import { IEventDropBase, ITicket } from '../../types';
import {
  STRIPE_CLIENT_KEY_TOKEN,
  VALID_BILLING_COUNTRIES
} from '../../constants';
import { cleanRecursive } from '../../tools/util';
import { ContentService } from '../../services';

@Component({
  selector: 'lib-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent implements OnInit {
  @Input() content: IEventDropBase;
  @Input() ticket: ITicket;
  @Input() name: string;
  @Input() isFinalScreen = false;
  @Input() paymentCallback: (success: boolean, error?: any) => {};

  isLoading: boolean;
  isSubmitting = false;
  elements: StripeElements;
  card: StripeCardElement;
  cardInputComplete: boolean;
  stripe: Stripe;
  error = '';
  billingCountries = VALID_BILLING_COUNTRIES;

  // Optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  // Payment intent
  paymentIntentSecret: string;
  stripeAccountId: string;
  stripePaymentForm: FormGroup;

  constructor(
    @Inject(STRIPE_CLIENT_KEY_TOKEN) private stripeClientKey: string,
    private _formBuilder: FormBuilder,
    private _content: ContentService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this._initFormGroups();
    this._setupPayment().then((_) => {
      this.isLoading = false;
      this._cdr.detectChanges();
    });
  }

  private _initFormGroups(): void {
    this.stripePaymentForm = this._formBuilder.group({
      name: [this.name ?? '', [Validators.required]],
      line1: ['', [Validators.required]],
      line2: [''],
      postalCode: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['US', [Validators.required]],
      city: ['', [Validators.required]]
    });
  }

  // Check if Apple / Google Pay are available
  private async _setupBrowserPay() {
    // // Price should always be represented in cents
    // const amount = this.event.tickets?.[0].price;
    // if (!amount && amount !== 0) throw new Error(STANDARD_UNEXPECTED_ERROR);
    // const paymentRequest = this.stripe.paymentRequest({
    //   country: 'US',
    //   currency: 'usd',
    //   total: {
    //     label: this.event.title ?? this.event.subtitle,
    //     amount,
    //   },
    //   requestPayerName: true,
    //   requestPayerEmail: true,
    // });
    // const prButton = this.elements.create('paymentRequestButton', { paymentRequest });
    // const result = await paymentRequest.canMakePayment();
    // if (result) {
    //   prButton.mount('#payment-request-button');
    //   await this.setupPaymentHandler(paymentRequest);
    // } else {
    //   // This comes from the Stripe docs
    //   // TODO: Should probably be done using ViewChildren
    //   document.getElementById('payment-request-button').style.display = 'none';
    // }
  }

  // Payment Request Button Interactions
  private async _setupPaymentHandler(paymentRequest: PaymentRequest) {
    // if (!this.stripe) return;
    // const component = this;
    // const callback = component.paymentCallback;
    // paymentRequest.on('paymentmethod', (ev) => {
    //   component.stripe.confirmCardPayment(
    //     this.paymentIntentSecret,
    //     { payment_method: ev.paymentMethod.id },
    //     // https://stripe.com/docs/payments/payment-intents/verifying-status#next-actions
    //     { handleActions: true }
    //   ).then((confirmResult) => {
    //     if (confirmResult.error) {
    //       component._setError(confirmResult.error.message);
    //       // Report to the browser that the payment failed, prompting it to
    //       // re-show the payment interface, or show an error message and close
    //       // the payment interface.
    //       ev.complete('fail');
    //     } else {
    //       // Report to the browser that the confirmation was successful, prompting
    //       // it to close the browser payment method collection interface.
    //       ev.complete('success');
    //       // Let Stripe.js handle the rest of the payment flow.
    //       component.stripe.confirmCardPayment(this.paymentIntentSecret).then(result => {
    //         if (result.error) {
    //           // The payment failed -- ask your customer for a new payment method.
    //           // callback(false, result.error);
    //           component._setError(result.error.message ?? '');
    //         } else {
    //           // The payment has succeeded.
    //           callback(true);
    //         }
    //       });
    //     }
    //   });
    // });
  }

  // Handle manual card payments
  completeCardPayment() {
    const name = this.stripePaymentForm.get('name').value;
    const line1 = this.stripePaymentForm.get('line1').value;
    const line2 = this.stripePaymentForm.get('line2').value;
    const postalCode = this.stripePaymentForm.get('postalCode').value;
    const state = this.stripePaymentForm.get('state').value;
    const country = this.stripePaymentForm.get('country').value;
    const city = this.stripePaymentForm.get('city').value;

    const callback = this.paymentCallback;
    const component = this;
    this.stripe
      .confirmCardPayment(this.paymentIntentSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name,
            address: cleanRecursive({
              line1,
              line2,
              postal_code: String(postalCode),
              state,
              country,
              city
            })
          }
        }
      })
      .then((result) => {
        this.isSubmitting = false;

        if (result.error) component._setError(result.error.message ?? '');
        else if (result.paymentIntent?.status === 'succeeded') callback(true);
      });
  }

  private async _setupPayment() {
    // 1) Fetch the secret first. Must be blocking.
    const intent = await this._content.getPaymentIntentSecret(
      this.content.contentId,
      this.ticket?.label
    );

    if (intent) {
      const { secret, accountId } = intent;
      this.stripeAccountId = accountId;
      this.paymentIntentSecret = secret;

      // 2) Blocking setup stripe, blocking.
      await this._setupStripe();

      return Promise.all([
        // this.setupBrowserPay(),
        this._setupCardPayment()
      ]);
    }

    this.paymentCallback(false, 'Sorry, something went wrong.');
  }

  private async _setupStripe() {
    await loadStripe(this.stripeClientKey).then((stripe) => {
      this.stripe = stripe;
      const elements = this.stripe.elements({ locale: 'en' });
      this.elements = elements;
    });
  }

  private _setupCardPayment() {
    const component = this;
    // Only mount the element the first time
    if (!this.card) {
      this.card = this.elements.create('card', {
        style: {
          base: {
            iconColor: '#666EE8',
            color: 'rgba(0, 0, 0, 0.85)',
            lineHeight: '32px',
            fontFamily:
              '"basis-grotesque", sans-serif, -apple-system, Roboto, sans-serif',
            fontWeight: '400',
            fontSize: '16px',
            '::placeholder': {
              color: '#ccc'
            }
          }
        }
      });

      component.card.mount('#card-element');
      component.card.on('change', (event) => {
        component.cardInputComplete = event.complete;

        if (event.error) {
          component._setError(event.error.message);
        } else {
          component._clearError();
        }
      });
    }
  }

  private _clearError() {
    const displayError = document.getElementById('card-errors');
    displayError.textContent = '';
    this.isLoading = false;
    this._cdr.detectChanges();
  }

  private _setError(error: string) {
    const displayError = document.getElementById('card-errors');
    displayError.textContent = error ?? '';
    this.isLoading = false;
    this._cdr.detectChanges();
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import {
  AuthService,
  BaseComponent,
  IUserRole,
  ROOT_HOST_TOKEN,
  isPhoneNumberValid,
  VALID_COUNTRY_CODES,
  ISlug,
  ErrorService,
  SlugService
} from 'shared';
import { Stages } from '@login/types';
import { onboardingAnimation } from '@login/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [onboardingAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  constructor(
    private _formBuilder: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _error: ErrorService,
    private _slug: SlugService,
    private _auth: AuthService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId,
    @Inject(ROOT_HOST_TOKEN) public rootHost: string
  ) {
    super();
  }

  error: string; // Currently displayed error
  isResendingCode: boolean; // For the spinner on the resend code button
  stages: Stages[] = ['phone', 'code', 'subsite_selection'];
  currentIndex = -1; // The currently displayed index in the stepper
  validCountryCodes = VALID_COUNTRY_CODES;
  phoneNumber; // The user's cached phone number
  isLoading = false;
  userRoles: IUserRole[] = [];
  slugs: ISlug[] = [];

  // Form groups
  phoneFormGroup: FormGroup;
  codeFormGroup: FormGroup;

  // Placeholder shown in phone entry box
  // Country dependent
  phonePlaceholder = '(123) 456-7890';

  // The current stage
  get currentStage() {
    return this.stages?.length > this.currentIndex
      ? this.stages[this.currentIndex]
      : null;
  }

  ngOnInit() {
    this._initForms();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId))
      this._auth.logout().then(() => {
        this.currentIndex = 0;
        this._cdr.detectChanges();
      });
  }

  private _initForms() {
    this.phoneFormGroup = this._formBuilder.group(
      {
        phoneNumber: ['', Validators.required],
        countryCode: ['US', Validators.required]
      },
      { validators: this._isPhoneNumberValid }
    );

    this.codeFormGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });
  }

  // Form group validator to ensure phone number checks out
  private _isPhoneNumberValid(group: FormGroup): ValidationErrors | null {
    const { phoneNumber, countryCode } = group.value;
    return isPhoneNumberValid(phoneNumber, countryCode);
  }

  handleCountryCodeChanged(code) {
    const country = VALID_COUNTRY_CODES[code];
    this.phonePlaceholder = country?.placeholder;
  }

  // Comparator for keyvalue pipe to force Angular to preserve
  // the order of the country codes map
  preserveOrder = (a, b): number => 0;

  async resendPhoneCode() {
    if (!this.phoneNumber) return;

    this.isResendingCode = true;
    this._cdr.detectChanges();

    try {
      await this._auth.initiatePhoneSignInFlow(this.phoneNumber);
      this.isResendingCode = false;
      this._cdr.detectChanges();
    } catch (e) {
      this.isResendingCode = true;
      this.phoneNumber = null;
      this._setError(e);
    }
  }

  // Step 1
  async submitPhone() {
    if (!this.phoneFormGroup.valid) return;
    this._setLoading(true);
    const { countryCode, phoneNumber } = this.phoneFormGroup.value;
    const adjustedNumber = phoneNumber.replace(/[^0-9]+/g, '');
    const countryPrefix = VALID_COUNTRY_CODES[countryCode]?.value;
    this.phoneNumber = `+${countryPrefix}${adjustedNumber}`;

    // Initiate the signin flow with transformed number
    try {
      await this._auth.initiatePhoneSignInFlow(this.phoneNumber);
      this._setLoading(false);
      this._advance();
    } catch (e) {
      this._setLoading(false);
      this._setError(e);
      this.phoneNumber = null;
    }
  }

  // Step 2
  async submitCode() {
    if (!this.codeFormGroup.valid || !this.phoneNumber) return;
    const verificationCode = `${this.codeFormGroup.value.verificationCode}`;
    this._setLoading(true);

    try {
      const credential = await this._auth.completePhoneLogin({
        phoneNumber: this.phoneNumber,
        verificationCode
      });

      this.userRoles = await this._auth.userRoles();
      if (this.userRoles.length === 1) {
        this.directToSlugAdmin(this.userRoles[0].slug);
      } else {
        this.slugs = await Promise.all(
          this.userRoles.map((role) => this._slug.getSlug(role.slug))
        );
        this._setLoading(false);
        this._advance();
      }
    } catch (e) {
      this._setLoading(false);
      this._setError(e);
    }
  }

  // Convenience methods for toggling UI state
  private _setLoading(loading: boolean) {
    this.isLoading = loading;
    this._cdr.detectChanges();
  }

  private _setError(error: any) {
    this.error = this._error.formatError(error);
    this._cdr.detectChanges();
  }

  // Advance to the next stage in the flow
  private _advance() {
    if (this.currentIndex < this.stages.length - 1) {
      this.currentIndex++;
      this._cdr.detectChanges();
      return;
    }
  }

  private _redirectTo(url: string) {
    this._document.location.href = url;
  }

  directToSlugAdmin(slug: string) {
    const slugAdmin = `https://${slug}.${this.rootHost}/admin`;
    this._redirectTo(slugAdmin);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { EventDropShareButtonViewComponent } from '../views/event-drop-share-button-view';
import { DirectivesModule } from './directives.module';

@NgModule({
  declarations: [
    EventDropShareButtonViewComponent
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    DirectivesModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule
  ],
  exports: [
    EventDropShareButtonViewComponent
  ]
})
export class EventDropShareButtonViewModule { }

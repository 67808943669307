export const ENDPOINTS = {
  onboarding: {
    slugRequest: '/onboarding/slug_request',
    slugValidation: 'onboarding/slug_valid'
  },
  singlesend: '/singlesend',
  organization: {
    member: {
      invite: '/organization/member/invite',
      update: '/organization/member/update',
      remove: '/organization/member/remove'
    },
    notificationSettings: '/organization/notification_settings',
    mailing: {
      replyTo: '/organization/mailing/reply_to'
    },
    sms: {
      alphaSender: '/organization/sms/alpha_sender'
    },
    account_settings: '/organization/account_settings'
  },
  event: '/event',
  link: '/link',
  collection: '/collection',
  drop: '/drop',
  newsletter: '/newsletter',
  analytics: {
    trackEntityInteraction: '/analytics/track_entity_interaction'
  },
  auth: {
    refresh: '/auth/refresh',
    analytics: '/auth/analytics_token',
    norbySearch: '/v2/auth/search_key',
    search: '/auth/search_key',
    stream: '/auth/stream_key',
    email: {
      promote: '/auth/email/promote'
    },
    login: {
      phone: {
        request: '/auth/phone/login/request',
        verify: '/auth/phone/login/verify'
      },
      email: {
        request: '/auth/email/login/request',
        verify: '/auth/email/login/verify'
      }
    },
    logout: '/auth/logout'
  },
  contacts: {
    csvImport: '/contact/import',
    edit: '/contact'
  },
  norbySearch: {
    contacts: '/search/contacts'
  }
};

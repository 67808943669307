<!-- Show join button if event is happening -->
<div *ngIf="event?.published && event?.isHappening && !event?.isCanceled">
    <a *ngIf="userEvent?.rsvpEnabled && userEvent?.urls?.clickThroughRaw" [nzLoading]="isLoading" nz-button
        nzType="primary" nzBlock nzSize="large" target="_blank" rel="noreferrer" (click)="handleJoinClick()"
        [href]="userEvent.urls.clickThroughRaw">
        {{ event.buttonLabels?.mid || 'JOIN' }}
    </a>
    <!-- If the user hasn't registered or isn't logged in, still show the join button but handle as an RSVP -->
    <button *ngIf="!userEvent?.rsvpEnabled" [nzLoading]="isLoading" nz-button nzType="primary" [disabled]="isDisabled"
        nzBlock nzSize="large" (click)="handleRsvpClick()" [logEvent]="userClickedPreEventCta"
        [cubeData]="{ entityType: 'event', entityId: event?.contentId }" [destination]="'cube'">
        {{ event.buttonLabels?.mid || 'JOIN' }}
    </button>
</div>

<!-- Show recap button if event is over -->
<div *ngIf="event?.isOver && userEvent?.urls?.recapRaw && !event?.isCanceled">
    <a nz-button nzType="primary" nzBlock nzSize="large" target="_blank" rel="noreferrer"
        [href]="userEvent?.urls?.recapRaw" [logEvent]="userClickedPostEventCta" [nzLoading]="isLoading"
        [cubeData]="{ entityType: 'event', entityId: event?.contentId }" [destination]="'cube'">
        {{ event?.buttonLabels?.post || 'RECAP' }}
    </a>
</div>

<!-- Show RSVP button if event is future, published, and there's no user event or rsvp is disabled -->
<div *ngIf="event?.published && event?.isFuture && !userEvent?.rsvpEnabled && !event?.isCanceled">
    <button nz-button nzBlock nzSize="large" [nzLoading]="isLoading" [disabled]="isDisabled" (click)="handleRsvpClick()"
        nzType="primary" [logEvent]="userClickedPreEventCta"
        [cubeData]="{ entityType: 'event', entityId: event?.contentId }" [destination]="'cube'">{{
        event?.buttonLabels?.pre ? event.buttonLabels.pre : event?.tickets?.length > 0 ? 'Tickets' :
        'RSVP'}}</button>
</div>

<!-- Show confirmed if it's published, future, and we've rsvped -->
<div *ngIf="event?.published && event?.isFuture && userEvent?.rsvpEnabled && !event?.isCanceled">
    <button nz-button nzBlock nzSize="large" nzType="primary" nz-dropdown [nzDropdownMenu]="rsvpMenu"
        [nzLoading]="isLoading">
        You're confirmed
        <i nz-icon nzType="down"></i>
    </button>
</div>

<!-- REMOVE RSVP MENU -->
<nz-dropdown-menu #rsvpMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item *ngIf="!userEvent?.receiveNotifications?.sms && event?.hasPhoneRequirement"
            (click)="handleEnableSMS()">
            <i nz-icon nzType="message"></i> Turn on texts
        </li>

        <li nz-menu-item *ngIf="userEvent?.receiveNotifications?.sms" (click)="handleDisableSMS()">
            <i nz-icon nzType="stop"></i> Turn off texts
        </li>

        <li nz-menu-item *ngIf="!userEvent?.receiveNotifications?.email && event?.hasEmailRequirement"
            (click)="handleEnableEmail()">
            <i nz-icon nzType="mail"></i> Turn on emails
        </li>

        <li nz-menu-item *ngIf="userEvent?.receiveNotifications?.email" (click)="handleDisableEmail()">
            <i nz-icon nzType="stop"></i> Turn off emails
        </li>

        <li nz-menu-divider></li>

        <li nz-menu-item (click)="handleRemoveRSVP()">
            <i nz-icon nzType="delete"></i> Remove registration
        </li>
    </ul>
</nz-dropdown-menu>

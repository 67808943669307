import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SitePreviewViewComponent } from '../views/site-preview-view';
import { CollectionViewModule } from './collection-view.module';

@NgModule({
  declarations: [
    SitePreviewViewComponent
  ],
  imports: [
    CommonModule,
    CollectionViewModule
  ],
  exports: [
    SitePreviewViewComponent
  ]
})
export class SitePreviewViewModule { }

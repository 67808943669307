import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { setDoc } from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';

// 3rd party
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

// App
import { ENDPOINTS, AUTH_FLOW_EVENTS } from '../../constants';
import { IUserContent, IContent, IEventDropBase } from '../../types';
import { AnalyticsTrackingService } from '../analytics-tracking';
import { AuthService } from '../auth';
import { DeviceService } from '../device';
import { ApiService } from '../api';
import { ErrorService } from '../error';
import { UserService } from '../user';

/*
  Users interacting with content
*/

@Injectable({
  providedIn: 'root'
})
export class ContentInteractionsService {
  beaconToken: string;

  // Lazy loaded components
  loginComponent;
  rsvpConfirmationComponent;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _functions: Functions,
    private _auth: AuthService,
    private _device: DeviceService,
    private _analyticsTracking: AnalyticsTrackingService,
    private _dialog: NzModalService,
    private _message: NzMessageService,
    private _api: ApiService,
    private _error: ErrorService,
    private _user: UserService
  ) {
    this._auth.authState$.subscribe(async (user) => {
      this.beaconToken = user
        ? (await this._api.post<{ token: string }>('/auth/beacon_token'))?.token
        : null;
    });
  }

  trackEventReferral(eventId: string, trackingParams: any) {
    const callable = httpsCallableData(this._functions, 'trackEventReferral');
    return firstValueFrom(callable({ eventId, trackingParams }));
  }

  async toggleSmsForContent(content: IContent, sms = true) {
    const userId = this._auth.currentUser?.uid;
    const contentId = content.contentId;
    const payload = { userId, contentId, receiveNotifications: { sms } };
    const doc = this._user.userContentForContent(userId, contentId);
    return setDoc(doc, payload, { merge: true }).then(() =>
      this._message.success(`Turned ${sms ? 'on' : 'off'} texts.`)
    );
  }

  async toggleEmailForContent(content: IContent, email = true) {
    const userId = this._auth.currentUser?.uid;
    const contentId = content.contentId;
    const payload = { userId, contentId, receiveNotifications: { email } };
    const doc = this._user.userContentForContent(userId, contentId);
    return setDoc(doc, payload, { merge: true }).then(() =>
      this._message.success(`Turned ${email ? 'on' : 'off'} emails.`)
    );
  }

  async getUserContentForContent(contentId: string): Promise<IUserContent> {
    const endpoint = `content/${contentId}/referral`;
    try {
      return await this._api.post<IUserContent>(endpoint);
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async subscribeToNewsletter(email: string) {
    const endpoint = `newsletter/signup`;
    const slug = this._device.currentSlug;
    return this._api.post(endpoint, { email, slug });
  }

  async interactWithLink(linkId: string, interactionType: 'click' = 'click') {
    const endpoint = `${ENDPOINTS.link}/${linkId}/interaction`;
    try {
      return await this._api.post(endpoint, {
        interactionType,
        domReferrer: this._document?.referrer
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  sendBeaconForLink(linkId: string): boolean {
    if (this.beaconToken) {
      const endpoint = `${
        ENDPOINTS.link
      }/${linkId}/interaction_beacon?interactionType=click&token=${
        this.beaconToken
      }${
        this._document?.referrer
          ? `&domReferrer=${this._document?.referrer}`
          : ''
      }`;
      return !!navigator?.sendBeacon(this._api.constructApiUrl(endpoint));
    }

    return false;
  }

  // Remove registration for event or drop
  async unregisterForContent(content: IEventDropBase) {
    const userId = this._auth.currentUser?.uid;
    const payload = {
      rsvpEnabled: false,
      receiveNotifications: {
        email: false,
        sms: false
      }
    };
    const doc = this._user.userContentForContent(userId, content.contentId);
    return setDoc(doc, payload, { merge: true }).then(() =>
      this._message.success('Removed registration.')
    );
  }

  // Register for an event or drop
  async registerForContent(content: IEventDropBase) {
    const confirmedRegistration =
      await this._confirmUserMeetsEventRequirementsOrInitiateLoginFlow(content);
    if (!confirmedRegistration) return;

    const currentUser = this._auth.currentUser;
    const hasEmailRequirement =
      content.privateUserInfoRequirements?.email?.required;
    const hasSmsRequirement =
      content.privateUserInfoRequirements?.phoneNumber === undefined ||
      content.privateUserInfoRequirements?.phoneNumber?.required;
    // Construct optimistic user content object
    const userContent: IUserContent = {
      ...confirmedRegistration,
      userId: currentUser?.uid,
      contentId: content.contentId,
      contentType: content.contentType,
      rsvpStatus:
        !hasEmailRequirement || currentUser?.emailVerified
          ? 'active'
          : 'pending',
      rsvpEnabled: true,
      receiveNotifications: {
        email: hasEmailRequirement,
        sms: hasSmsRequirement
      }
    };

    if (!this.rsvpConfirmationComponent) {
      const { RsvpConfirmationComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/rsvp-confirmation'
      );

      this.rsvpConfirmationComponent = RsvpConfirmationComponent;
    }

    const component = this.rsvpConfirmationComponent;
    const ret = this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzClosable: false,
      nzComponentParams: { content, userContent },
      nzBodyStyle: { padding: '0px' },
      nzCancelText: null,
      nzFooter: null,
      nzAutofocus: 'ok'
    });

    firstValueFrom(ret.afterClose).then((res) => {
      if (content.urls?.postRegistrationRedirect) {
        this._document.location.href = content.urls.postRegistrationRedirect;
      }
    });

    const doc = this._user.userContentForContent(
      currentUser?.uid,
      content.contentId
    );
    return setDoc(doc, userContent, { merge: true }).catch((err) =>
      console.log(err)
    );
  }

  private async _confirmUserMeetsEventRequirementsOrInitiateLoginFlow(
    content: IEventDropBase
  ): Promise<IUserContent> {
    this._analyticsTracking.track(
      content?.isEvent
        ? AUTH_FLOW_EVENTS.userBeganRegisterForEventFlow
        : content?.isDrop
        ? AUTH_FLOW_EVENTS.userBeganRegisterForDropFlow
        : AUTH_FLOW_EVENTS.userBeganRegisterForNewsletterFlow
    );

    const prompts = content?.prompts;
    const privateReqs = content?.privateUserInfoRequirements;
    const publicReqs = content?.userInfoRequirements;
    const user = this._auth.currentUser;
    const userLoggedIn = this._auth.userLoggedIn;

    const hasEmailRequirement = privateReqs?.email?.required;
    const hasSmsRequirement =
      privateReqs?.phoneNumber === undefined ||
      privateReqs?.phoneNumber?.required;

    // Only proceed to the login modal if event has no requirements
    // and the user is logged in
    // const needsEmail =
    //   hasEmailRequirement && (!user.email || !user.emailVerified);
    const needsName = publicReqs?.displayName?.required;
    const needsPurchase = content?.isPaid;
    const needsPhone = hasSmsRequirement && !user.phoneNumber;

    if (
      userLoggedIn &&
      !(prompts?.length > 0) &&
      !needsPurchase &&
      !hasEmailRequirement &&
      !needsName &&
      !needsPhone
    )
      return {};

    if (!this.loginComponent) {
      const { LoginComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/login'
      );

      this.loginComponent = LoginComponent;
    }

    const component = this.loginComponent;
    const dialogRef = this._dialog.create<typeof component, IUserContent>({
      nzContent: component,
      nzClosable: false,
      nzFooter: null,
      nzComponentParams: { content }
    });

    const ret = await firstValueFrom(dialogRef.afterClose);

    this._analyticsTracking.track(
      content?.isEvent
        ? AUTH_FLOW_EVENTS.userFinishedRegisterForEventFlow
        : content?.isDrop
        ? AUTH_FLOW_EVENTS.userFinishedRegisterForDropFlow
        : AUTH_FLOW_EVENTS.userFinishedRegisterForNewsletterFlow
    );

    return ret;
  }
}

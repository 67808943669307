<div class="header text-color" *ngIf="slug">
    <!-- TITLE -->
    <h2 *ngIf="slug?.title && (block?.showTitle != false)">{{ slug?.title }}</h2>

    <!-- PRONOUNS -->
    <h4 *ngIf="slug?.pronouns && (block?.showPronouns != false)">{{ slug?.pronouns }}</h4>

    <!-- BIO -->
    <div class="bio" *ngIf="slug?.bio && (block?.showBio != false)" [innerHTML]="slug?.bio | safeHtml">
    </div>

    <!-- TAGS -->
    <div class="tags" *ngIf="slug?.location && (block?.showLocation != false)">
        <nz-tag [style]="tagStyle"><i nz-icon nzType="environment"></i> {{ slug.location }}</nz-tag>
    </div>

    <ng-content></ng-content>
</div>

<div class="header" *ngIf="!slug">
    <nz-skeleton></nz-skeleton>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';

// App
import { LinkCardViewComponent } from '../views/link-card-view';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
  declarations: [LinkCardViewComponent],
  imports: [
    CommonModule,

    NzCardModule,
    NzAvatarModule,
    NzCarouselModule,

    SoftImageViewModule
  ],
  exports: [LinkCardViewComponent]
})
export class LinkCardViewModule {}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

import { IDrop, IUserContent } from '../../types';

import dayjs from 'dayjs';

@Component({
  selector: 'lib-drop-card-view',
  templateUrl: './drop-card-view.component.html',
  styleUrls: ['./drop-card-view.component.less']
})
export class DropCardViewComponent implements OnChanges {
  @Output() cardClick = new EventEmitter<IDrop>();
  @Input() drop: IDrop;
  @Input() userContent: IUserContent;
  @Input() isLoadingDrop = false;
  @Input() isLoadingUserContent = false;
  @Input() isDisabled = false;
  registrationCloseDate: string;

  ngOnChanges(): void {
    this.registrationCloseDate = this.drop?.registrationCloseDate
      ? dayjs(this.drop.registrationCloseDate).format('MMM DD, YYYY')
      : null;
  }

  handleClick(): void {
    if (!this.isDisabled) this.cardClick.next(this.drop);
  }

  get priceStr(): string {
    if (this.drop?.tickets?.length > 0) {
      const price = this.drop.tickets[0].price / 100;
      return `From $${price}`;
    }

    return null;
  }
}

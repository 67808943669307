<div class="container">
  <div class="gradient-picker-wrapper">
    <div #picker></div>
  </div>

  <nz-form-item>
    <nz-form-label nzFor="angle">Type</nz-form-label>
    <nz-form-control nzErrorTip="That looks too long.">
      <nz-select
        (ngModelChange)="handleTypeChange($event)"
        [(ngModel)]="type"
        nzSize="large"
      >
        <nz-option nzLabel="Linear" nzValue="linear"></nz-option>
        <nz-option nzLabel="Radial" nzValue="radial"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="type !== 'radial'">
    <nz-form-label nzFor="angle">Angle</nz-form-label>
    <nz-form-control nzErrorTip="That looks too long.">
      <nz-slider
        [(ngModel)]="angle"
        (ngModelChange)="handleAngleChanged($event)"
        nzMax="360"
      ></nz-slider>
    </nz-form-control>
  </nz-form-item>

  <div id="color-picker">
    <span
      #colorPicker
      (colorPickerChange)="colorChanged($event)"
      (cpToggleChange)="colorPickerVisible = $event"
      [colorPicker]="selectedHandle?.getColor()"
      [cpToggle]="colorPickerVisible"
      cpAlphaChannel="disabled"
      cpPosition="bottom"
    ></span>
  </div>
</div>

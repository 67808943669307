import { Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  docData,
  setDoc,
  getDoc
} from '@angular/fire/firestore';

// 3rd party
import { switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

// App
import { AuthService } from '../auth';
import { IUserContent, IUserPublicMetadata, IUserMetadata } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private _auth: AuthService, private _firestore: Firestore) {}

  // returns true if successfully marked as seen, false on error
  async markTipSeen(identifier: string): Promise<void> {
    const currentUser = this._auth.currentUser;
    const ref = doc(this._firestore, 'userTooltips', currentUser?.uid);
    return setDoc(
      ref,
      {
        [identifier]: true
      },
      { merge: true }
    );
  }

  async checkTipSeen(identifier: string): Promise<boolean> {
    try {
      const currentUser = this._auth.currentUser;
      const ref = doc(this._firestore, 'userTooltips', currentUser?.uid);
      const snapshot = await getDoc(ref);
      return snapshot.exists && snapshot.data()[identifier];
    } catch (e) {
      // safer to fall back with the assumption that the user saw a tip than to reshow a tip
      return true;
    }
  }

  userContentForContent(userId: string, contentId: string) {
    return userId?.length && contentId?.length
      ? doc(this._firestore, 'userContent', `${userId}_${contentId}`)
      : null;
  }

  getUserContent$(contentId: string): Observable<IUserContent> {
    return this._auth.authState$.pipe(
      switchMap((u) =>
        u?.uid?.length && contentId?.length
          ? docData(this.userContentForContent(u.uid, contentId))
          : from([null])
      )
    );
  }

  currentUserMetadata$(): Observable<IUserMetadata> {
    return this._auth.authState$.pipe(
      switchMap((u) =>
        u?.uid && !u?.isAnonymous
          ? docData(doc(this._firestore, 'userMetadata', u.uid))
          : from([null])
      )
    );
  }

  currentUserProfile$(): Observable<IUserPublicMetadata> {
    return this._auth.authState$.pipe(
      switchMap((u) =>
        u?.uid && !u?.isAnonymous
          ? docData(doc(this._firestore, 'users', u.uid))
          : from([null])
      )
    );
  }

  async setUserProfile(user: IUserPublicMetadata) {
    const uid = this._auth.currentUser?.uid;
    if (!uid?.length) return;
    const ref = doc(this._firestore, 'users', uid);
    return setDoc(ref, user, { merge: true });
  }
}

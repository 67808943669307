import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { ColorPickerModule } from 'ngx-color-picker';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDividerModule } from 'ng-zorro-antd/divider';

// App
import { SoftImageViewModule } from './soft-image-view.module';
import { DropShadowSlidersViewModule } from './drop-shadow-sliders-view.module';
import { ThemeBuilderViewComponent } from '../views/theme-builder-view';
import { GradientGeneratorModule } from './gradient-generator.module';

@NgModule({
  declarations: [ThemeBuilderViewComponent],
  imports: [
    ColorPickerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    NzButtonModule,
    NzCollapseModule,
    NzFormModule,
    NzInputNumberModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    NzSliderModule,
    NzToolTipModule,
    NzUploadModule,
    NzPopconfirmModule,
    NzDividerModule,

    DropShadowSlidersViewModule,
    GradientGeneratorModule,
    SoftImageViewModule
  ],
  exports: [ThemeBuilderViewComponent]
})
export class ThemeBuilderViewModule {}

<a
  [href]="href"
  class="link-card"
  target="_blank"
  rel="noreferrer"
  (click)="handleClick($event)"
>
  <nz-card
    [class.hoverable]="link"
    [nzCover]="
      link?.imgixImages?.length > 1
        ? carouselCoverTemplate
        : link?.imageUrl && link?.imageIsTop
        ? coverTemplate
        : null
    "
    [style]="link?.cardCSS"
    class="card"
  >
    <div class="row">
      <div
        class="avatar left"
        *ngIf="
          link?.imgixImages?.length <= 1 && link?.imageUrl && link?.imageIsLeft
        "
      >
        <ng-container *ngTemplateOutlet="avatarTemplate"> </ng-container>
      </div>
      <div
        [class.center]="link?.textPosition === 'center'"
        [class.left]="link?.textPosition === 'left'"
        [class.right]="link?.textPosition === 'right'"
        class="meta"
      >
        <h3 class="title card-text-color" [style]="link?.cardTextCSS">
          {{ link?.title || '• • • • •' }}
        </h3>
        <p
          *ngIf="link?.description"
          class="card-text-color"
          [style]="link?.cardTextCSS"
        >
          {{ link?.description }}
        </p>
      </div>
      <div
        class="avatar right"
        *ngIf="
          link?.imgixImages?.length <= 1 && link?.imageUrl && link?.imageIsRight
        "
      >
        <ng-container *ngTemplateOutlet="avatarTemplate"> </ng-container>
      </div>
    </div>
  </nz-card>
</a>

<ng-template #coverTemplate>
  <lib-soft-image-view
    [imageUrl]="link?.imageUrl"
    [image]="link?.image"
    [imgixImage]="link?.imgixImage"
    [alt]="link?.title"
  >
  </lib-soft-image-view>
</ng-template>

<ng-template #avatarTemplate>
  <nz-avatar
    [nzSrc]="link?.imageUrl"
    nzSize="large"
    [nzAlt]="link?.title"
    [style]="'background-color:' + link?.styles?.card?.backgroundColor"
    nzShape="square"
  >
  </nz-avatar>
</ng-template>

<ng-template #carouselCoverTemplate>
  <nz-carousel nzAutoPlay nzAutoPlaySpeed="6000" nzEffect="fade">
    <div nz-carousel-content *ngFor="let image of link?.images; index as i">
      <lib-soft-image-view
        [alt]="link?.title"
        [imageUrl]="image?.url"
        [image]="image"
        [imgixImage]="link?.imgixImages[i]"
      ></lib-soft-image-view>
    </div>
  </nz-carousel>
</ng-template>

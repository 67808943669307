import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { DropDetailViewComponent } from '../views/drop-detail-view';
import { DropMainButtonViewModule } from './drop-main-button-view.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { RsvpConfirmationViewModule } from './rsvp-confirmation-view.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';

// Zorro
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  declarations: [DropDetailViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    DropMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    RsvpConfirmationViewModule,
    SafeHtmlPipeModule,

    NzCardModule,
    NzSkeletonModule,
    NzButtonModule,
    NzIconModule,
    NzAlertModule,
    NzToolTipModule,
    NzTagModule
  ],
  exports: [DropDetailViewComponent]
})
export class DropDetailViewModule {}

import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';

// 3rd party
import { Observable, from } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import {
  Auth,
  authState,
  signInAnonymously,
  signInWithCustomToken,
  User,
  UserCredential,
  signOut,
  user,
  linkWithCredential,
  PhoneAuthProvider
} from '@angular/fire/auth';
import {
  collection,
  collectionData,
  Firestore,
  getDocs,
  orderBy,
  query,
  where
} from '@angular/fire/firestore';

// App
import { ApiService } from '../api';
import { ENDPOINTS, COOKIE_PREFIX_TOKEN } from '../../constants';
import { ILoginRequestStatus } from './types';
import { IUserRole } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _currentUser: User;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(COOKIE_PREFIX_TOKEN) private _cookiePrefix: string,
    private _auth: Auth,
    private _firestore: Firestore,
    private _api: ApiService
  ) {
    if (isPlatformBrowser(this._platform)) {
      authState(this._auth)
        .pipe(
          // Cache the updated user
          // Set current API user
          tap((u) => {
            this._currentUser = u ?? null;
            this._api.setCurrentUser(u);
          }),

          // Only proceed to login / refresh flow if needed
          distinctUntilChanged((x, y) => x?.uid === y?.uid),
          filter((user) => {
            if (!user) return true;
            const refreshTokenFlag = this._getCookie(
              `${this._cookiePrefix}refresh-token-flag`
            );
            const refreshTokenFlagEnabled = refreshTokenFlag === '1';
            return refreshTokenFlagEnabled && user?.isAnonymous;
          })
        )
        .subscribe((_) => this._defaultLogin());

      this._auth.useDeviceLanguage();
    }
  }

  private _getCookie(name: string) {
    const match = this._document?.cookie?.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    if (match) return match[2];
  }

  private async _defaultLogin(): Promise<UserCredential> {
    const user = await this._attemptReauthFlow();
    if (user) return user;
    return this.loginAnonymously();
  }

  get auth(): Auth {
    return this._auth;
  }

  // Query user roles from userRoles table
  userRoles$(): Observable<IUserRole[]> {
    if (isPlatformServer(this._platform)) return from([null]);

    return this.authState$.pipe(
      switchMap(
        (user) =>
          collectionData(
            query(
              collection(this._firestore, 'userRoles'),
              where('userId', '==', user?.uid ?? 0),
              orderBy('createdAtCursor')
            )
          ) as Observable<IUserRole[]>
      )
    );
  }

  async userRoles() {
    const roles = await getDocs(
      query(
        collection(this._firestore, 'userRoles'),
        where('userId', '==', this.currentUser?.uid ?? 0),
        orderBy('createdAtCursor')
      )
    );

    return roles.docs.map((role) => role.data() as IUserRole);
  }

  loginAnonymously(): Promise<UserCredential> {
    if (!this.currentUser) {
      return signInAnonymously(this._auth);
    }
  }

  private async _attemptReauthFlow(): Promise<UserCredential | null> {
    return this._signInWithRefreshToken();
  }

  private async _signInWithRefreshToken(): Promise<UserCredential | null> {
    const newTokens: {
      accessToken: string;
      refreshToken: string;
    } | null = await this._api
      .post<{
        refreshToken: string;
        accessToken: string;
      }>(ENDPOINTS.auth.refresh)
      .catch((e) => null);

    if (!newTokens) return null;

    const refreshTokenUserCredential: UserCredential | null =
      await signInWithCustomToken(this._auth, newTokens.accessToken)
        .then((res) => res)
        .catch((e) => null);

    return refreshTokenUserCredential;
  }

  async logout(): Promise<void> {
    // Signal for the server to kill the refresh token cookie.
    await this._api.post(ENDPOINTS.auth.logout);
    await signOut(this._auth);
  }

  get currentUser(): User {
    return this._currentUser;
  }

  get user$(): Observable<User> {
    if (isPlatformServer(this._platform)) return from([null]);
    return user(this._auth);
  }

  get authState$(): Observable<User> {
    if (isPlatformServer(this._platform)) return from([null]);
    return authState(this._auth);
  }

  get userLoggedIn() {
    if (isPlatformServer(this._platform)) return false;
    const user = this.currentUser;
    return user && !user.isAnonymous;
  }

  get userPhoneLoggedIn() {
    if (isPlatformServer(this._platform)) return false;
    const user = this.currentUser;
    return !!user?.phoneNumber;
  }

  // Step one in auth flow
  // Returns a confirmation result that can be used to
  // either log in or link the account, depending on whether
  // the user already exists or not
  async initiatePhoneSignInFlow(phoneNumber: string): Promise<boolean> {
    if (this.userPhoneLoggedIn) {
      throw new Error('User already logged in');
    }

    const ret = await this._api.post<boolean>(
      ENDPOINTS.auth.login.phone.request,
      {
        phoneNumber
      }
    );
    console.log(ret);
    return ret;
  }

  // Step two in auth flow if user already exists
  // Links new phone number to anonymous account
  async completePhoneLogin(input: {
    verificationCode: string;
    phoneNumber: string;
  }): Promise<UserCredential> {
    const { phoneNumber, verificationCode } = input;
    const response = await this._api.post<{
      accessToken: string;
      refreshToken: string;
    }>(ENDPOINTS.auth.login.phone.verify, { phoneNumber, verificationCode });

    const { accessToken } = response;
    return await signInWithCustomToken(this._auth, accessToken);
  }

  // Provide email
  // Returns a credential if the backend authorizes and optimistic login(supplies accessToken)
  async initiateEmailSignInOrLinkingFlow({
    email,
    sendMagicLink
  }: {
    email: string;
    sendMagicLink?: boolean;
  }) {
    const response = await this._api.post<ILoginRequestStatus>(
      ENDPOINTS.auth.login.email.request,
      {
        email,
        // Magic links issue authorization with a verification uri instead of verification codes.
        // If there is a currently authenticated user, the generated magic link will merge any eligible accounts.
        sendMagicLink: !!sendMagicLink
      }
    );

    const { accessToken } = response;
    if (accessToken) {
      // If the email address is free the backend either places it directly onto the currently authorized user,
      // or a hull account is created and the credentials are returned. In either case we must let the caller
      // know that the user has been authorized and does not need to go through the email verification code step.
      const credential = await signInWithCustomToken(this._auth, accessToken);
      return {
        ...response,
        credential
      };
    }

    return {
      ...response,
      credential: null
    };
  }
  /**
   * Promotes a second order email address to the primary slot
   */
  async promoteSecondOrderEmail({ email }: { email: string }) {
    const response = await this._api.post<boolean>(
      ENDPOINTS.auth.email.promote,
      {
        email
      }
    );
    return response;
  }

  // Step two in auth flow if user already exists
  // Links new phone number to anonymous account
  async completeEmailLogin(input: {
    verificationCode: string;
    email: string;
  }): Promise<UserCredential> {
    const { email, verificationCode } = input;
    const response = await this._api.post<{
      accessToken: string;
      refreshToken: string;
    }>(ENDPOINTS.auth.login.email.verify, { email, verificationCode });
    const { accessToken } = response;
    const credential = await signInWithCustomToken(this._auth, accessToken);
    return credential;
  }

  // Step two in auth flow if user doesn't already exist
  // Links new phone number to anonymous account
  async linkNumberToAccount(input: {
    verificationCode: string;
    phoneNumber: string;
  }): Promise<UserCredential> {
    const user = this.currentUser;
    if (!user) return;
    const credential = await this.completePhoneLogin(input);
    const authCredential = PhoneAuthProvider.credentialFromResult(credential);
    return linkWithCredential(user, authCredential)
      .then((cred) => cred)
      .catch(verificationCodeErrorHandler);
  }
}

// TODO
const verificationCodeErrorHandler = (e: any) => {
  switch (e.code) {
    case 'auth/invalid-verification-code':
      throw new Error('The verification code entered was invalid.');
    case 'auth/network-request-failed':
      throw new Error('Having trouble connecting right now. Try again later.');
    case 'auth/too-many-requests':
      throw new Error(
        "We've deteced unusual activity on this device and temporarily blocked further requests. Try again later."
      );
    case 'auth/user-disabled':
      throw new Error('Sorry, this account has been disabled.');
    case 'auth/web-storage-unsupported':
      throw new Error(
        "Sorry, your browser doesn't support logging in with phone number."
      );
    default:
      throw new Error('Sorry, there was an error. Try again later.');
  }
};

import { IDropShadowValues, IFont, IThemeDTO } from '../types';

const generateFontImportsAndDefinitions = (theme: IThemeDTO): string => {
  const allFonts: IFont[] = [
    'font',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p'
  ].map((f) => theme?.fonts[f]);

  const googleFonts: { [key: string]: IFont } = {};
  const customFonts: { [key: string]: IFont } = {};
  allFonts.forEach((f) => {
    if (f?.importUrl?.startsWith('https://fonts.googleapis.com')) {
      googleFonts[f.importUrl] = f;
    } else if (f?.importUrl) {
      customFonts[f.importUrl] = f;
    }
  });

  const fontImports = Object.values(googleFonts).map(
    (font) => `@import url('${font.importUrl}');`
  );
  const fontFaces = Object.values(customFonts).map(
    (font) =>
      `@font-face {
          font-family: ${font?.fontFamily ?? 'Custom font'};
          src: url('${font.importUrl}');
          font-display: swap;
        }`
  );

  return `${fontImports.join(' ')} ${fontFaces.join(' ')}`.trim();
};

/*
  Generate inline CSS styles based on the values in the supplied theme
*/
export const generateCSSFromTheme = (
  theme: IThemeDTO,
  selector: string = '.front-of-house'
): string => {
  let fontString = generateFontImportsAndDefinitions(theme);
  if (theme?.fonts?.font?.importUrl) {
    fontString += `
        ${selector} h1,
        ${selector} h2,
        ${selector} h3,
        ${selector} h4,
        ${selector} h5,
        ${selector} h6,
        ${selector} p,
        ${selector} input,
        ${selector} select,
        ${selector} textarea,
        ${selector} .ant-btn:not(.ant-btn-icon-only),
        ${selector} .ant-card,
        ${selector} .ant-alert,
        ${selector} .ant-menu-item,
        ${selector} .ant-result-title,
        ${selector} .ant-result-subtitle,
        ${selector} .ant-tag {
          font-family: ${theme.fonts.font?.fontFamily};
        }`;
  }

  const bgImage = theme?.layout?.backgroundImage;
  const bgGradient = theme?.layout?.backgroundGradient;
  // TODO: use signed background image
  // Will complicate appearance editor / live preview
  // slug?.layout?.signedBackgroundImage ?? slug?.layout?.backgroundImage;

  const bgRule = bgImage
    ? `background-image: url(${bgImage});`
    : `background: ${theme?.layout?.backgroundGradient}`;
  const bgImageString =
    bgImage || bgGradient
      ? `
        ${selector} .background-image,
        ${selector}.background-image { ${bgRule} }
        `
      : '';

  const bgColorString = theme?.layout?.backgroundColor
    ? `
      ${selector} .background-color,
      ${selector}.background-color {
          background-color: ${theme?.layout?.backgroundColor};
      }
      `
    : ``;

  return `
      ${fontString}
  
      ${bgImageString || bgColorString}
  
      ${selector} .ant-card,
      ${selector} .card-background-color {
        background-color: ${theme?.card?.backgroundColor};
      }
  
      ${selector} .text-color,
      ${selector} .text-color p,
      ${selector} .text-color h1,
      ${selector} .text-color h2,
      ${selector} .text-color h3,
      ${selector} .text-color h4,
      ${selector} .text-color h5,
      ${selector} .text-color h6 {
        color: ${theme?.text?.color};
      }
  
      ${selector} .card-style-on-hover:hover {
        background-color: ${theme?.card?.backgroundColor};
        color: ${theme?.card?.textColor};
      }
  
      ${selector} .card-text-color,
      ${selector} .card-text-color p,
      ${selector} .card-text-color h1,
      ${selector} .card-text-color h2,
      ${selector} .card-text-color h3,
      ${selector} .card-text-color h4,
      ${selector} .card-text-color h5,
      ${selector} .card-text-color h6,
      ${selector} .card-text-color .ant-result-title,
      ${selector} .card-text-color .ant-result-subtitle,
      ${selector} .card-text-color .ant-result-icon i {
        color: ${theme?.card?.textColor};
      }
  
      ${selector} p a:not(.ant-btn),
      ${selector} h1 a:not(.ant-btn),
      ${selector} h2 a:not(.ant-btn),
      ${selector} h3 a:not(.ant-btn),
      ${selector} h4 a:not(.ant-btn),
      ${selector} h5 a:not(.ant-btn),
      ${selector} h6 a:not(.ant-btn),
      ${selector} ul > *:not(.ant-menu-item) > a:not(.ant-btn),
      ${selector} ol > *:not(.ant-menu-item) > a:not(.ant-btn) {
          color: ${theme?.link?.textColor};
      }
  
      ${selector} p a:not(.ant-btn):hover,
      ${selector} h1 a:not(.ant-btn):hover,
      ${selector} h2 a:not(.ant-btn):hover,
      ${selector} h3 a:not(.ant-btn):hover,
      ${selector} h4 a:not(.ant-btn):hover,
      ${selector} h5 a:not(.ant-btn):hover,
      ${selector} h6 a:not(.ant-btn):hover,
      ${selector} ul > *:not(.ant-menu-item) > a:not(.ant-btn):hover,
      ${selector} ol > *:not(.ant-menu-item) > a:not(.ant-btn):hover {
          color: ${theme?.link?.hoverTextColor};
      }
  
      ${selector} .ant-card,
      ${selector} .ant-alert:not(.ant-alert-banner) {
          border-radius: ${theme?.card?.borderRadius}px;
      }
  
      ${selector} .ant-btn,
      ${selector} .ant-input {
          border-radius: ${theme?.button?.borderRadius}px;
      }
  
      ${selector} .ant-card,
      ${selector} .card-border-style {
          border-width: ${theme?.card?.borderWidth ?? 1}px;
          border-color: ${theme?.card?.borderColor ?? 'rgba(0, 0, 0, 0.05)'};
          border-style: solid;
          box-shadow: ${theme?.card?.dropShadow};
          border-radius: ${theme?.card?.borderRadius}px !important;
      }
  
      ${selector} .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button,
      ${selector} .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
          border-top-right-radius: ${theme?.button?.borderRadius}px !important;
      }
  
      ${selector} .ant-input-search .ant-input-group .ant-input-affix-selector:not(:last-child) {
          border-top-left-radius: ${theme?.button?.borderRadius}px !important;
      }
  
      ${selector} .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button,
      ${selector} .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
          border-bottom-right-radius: ${
            theme?.button?.borderRadius
          }px !important;
      }
  
      ${selector} .ant-input-search .ant-input-group .ant-input-affix-selector:not(:last-child) {
          border-bottom-left-radius: ${
            theme?.button?.borderRadius
          }px !important;
      }
  
      ${selector} .ant-btn-primary:not(.ant-input-search-button) {
          box-shadow: ${theme?.button?.dropShadow};
      }
  
      ${selector} .ant-btn-primary,
      ${selector} .ant-input-group > .ant-input-affix-selector,
      ${selector} .ant-input-group > .ant-input {
          border-color: ${theme?.button?.borderColor};
          border-width: ${theme?.button?.borderWidth ?? 1}px;
      }
  
      ${selector} .ant-btn-primary:hover,
      ${selector} .ant-input-group > .ant-input-affix-selector:hover,
      ${selector} .ant-input-group > .ant-input:hover {
          border-color: ${theme?.button?.hoverBorderColor};
      }
  
      ${selector} .ant-btn-primary {
          background-color: ${theme?.button?.backgroundColor};
          color: ${theme?.button?.textColor};
      }
  
      ${selector} .ant-btn-primary:hover {
          background-color: ${theme?.button?.hoverBackgroundColor};
          color: ${theme?.button?.hoverTextColor};
      }
  
      ${selector} .ant-btn.secondary {
          background-color: ${theme?.secondaryButton?.backgroundColor};
          color: ${theme?.secondaryButton?.textColor};
          border-color: ${theme?.secondaryButton?.borderColor};
          border-width: ${theme?.secondaryButton?.borderWidth ?? 1}px;
          box-shadow: ${theme?.secondaryButton?.dropShadow};
          border-radius: ${theme?.secondaryButton?.borderRadius}px;
      }
  
      ${selector} .ant-btn.secondary:hover {
          background-color: ${theme?.secondaryButton?.hoverBackgroundColor};
          border-color: ${theme?.secondaryButton?.hoverBorderColor};
          color: ${theme?.secondaryButton?.hoverTextColor};
      }
  
      ${selector} h1 {
        font-size: ${theme?.fonts?.h1?.fontSize ?? 28}px;
        font-family: ${
          theme?.fonts?.h1?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
  
      ${selector} h2 {
        font-size: ${theme?.fonts?.h2?.fontSize ?? 21}px;
        font-family: ${
          theme?.fonts?.h2?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
  
      ${selector} h3 {
        font-size: ${theme?.fonts?.h3?.fontSize ?? 16.38}px;
        font-family: ${
          theme?.fonts?.h3?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
  
      ${selector} h4 {
        font-size: ${theme?.fonts?.h4?.fontSize ?? 14}px;
        font-family: ${
          theme?.fonts?.h4?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
  
      ${selector} h5 {
        font-size: ${theme?.fonts?.h5?.fontSize ?? 11.62}px;
        font-family: ${
          theme?.fonts?.h5?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
  
      ${selector} h6 {
        font-size: ${theme?.fonts?.h6?.fontSize ?? 9.38}px;
        font-family: ${
          theme?.fonts?.h6?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
  
      ${selector} p {
        font-size: ${theme?.fonts?.p?.fontSize ?? 14}px;
        font-family: ${
          theme?.fonts?.p?.fontFamily ?? theme?.fonts?.font?.fontFamily
        };
      }
    `.replace(/\s+/g, ' ');
};

export function parseDropShadowString(fromString?: string): IDropShadowValues {
  const currentValue = (fromString ?? '0px 0px 0px #000000')
    .trim()
    .split(' ')
    .map((token) => token.replace('px', '').trim());

  while (currentValue.length < 4) {
    currentValue.push('');
  }

  const h = +(currentValue[0] || 0);
  const v = +(currentValue[1] || 0);
  const blur = +(currentValue[2] || 0);
  const color = currentValue[3] || '#000000';

  return { h, v, blur, color };
}

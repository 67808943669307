import { Component, Input } from '@angular/core';
import { IImage, IImageBlock } from '../../types';

@Component({
  selector: 'lib-image-block-view',
  templateUrl: './image-block-view.component.html',
  styleUrls: ['./image-block-view.component.less']
})
export class ImageBlockViewComponent {
  @Input() imageBlock: IImageBlock;

  get image(): IImage {
    return this.imageBlock?.images?.[0];
  }
}

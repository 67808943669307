import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes
} from '@angular/fire/storage';

// 3rd party
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

// App
import { AnalyticsTrackingService } from '../../services';
import { BaseComponent } from '../../models';
import { IFont } from '../../types';
import { FontName, uuidv4 } from '../../tools';
import { UI_EVENTS } from '../../constants';

@Component({
  selector: 'lib-custom-font-config',
  templateUrl: './custom-font-config.component.html',
  styleUrls: ['./custom-font-config.component.less']
})
export class CustomFontConfigComponent extends BaseComponent implements OnInit {
  currentFont: IFont;
  form: FormGroup;
  uploadStatus: 'uploading' | 'done';

  constructor(
    private _formBuilder: FormBuilder,
    private _storage: Storage,
    private _dialogRef: NzModalRef<CustomFontConfigComponent>,
    private _message: NzMessageService,
    private _analyticsTracking: AnalyticsTrackingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      fontFamily: ['', [Validators.required]],
      displayName: ['', []],
      importUrl: [{ value: null, disabled: true }, Validators.required]
    });

    this.form.valueChanges.pipe(this.takeUntilDestroy).subscribe((changes) => {
      this._dialogRef.updateConfig({
        nzOkDisabled: !this.form.valid
      });
    });

    this._dialogRef.updateConfig({
      nzOnOk: () => {
        const { fontFamily, importUrl, displayName } = this.form.getRawValue();
        this._message.success('Your font was successfully imported');
        this._dialogRef.close({
          imageUrl: '',
          importUrl,
          displayName: displayName || fontFamily || 'Custom font',
          fontFamily,
          isCustom: true
        });
      }
    });
  }

  uploadFile = (file: NzUploadFile): boolean => {
    if (!file) return false;

    this.uploadStatus = 'uploading';
    this._dialogRef.updateConfig({
      nzCancelDisabled: true
    });

    this._guessFontFamily(file).then(async (font) => {
      const extension = file.name?.split('.')?.pop();
      const fileName = `${uuidv4()}.${extension}`;
      const storageRef = ref(this._storage, `fonts/${fileName}`);
      uploadBytes(storageRef, file as any)
        .then(async (snapshot) => {
          this.uploadStatus = 'done';
          const importUrl = await getDownloadURL(snapshot.ref);
          this._analyticsTracking.track(UI_EVENTS.userUploadedCustomFont);
          this.form.patchValue({
            importUrl,
            fontFamily: font?.fontFamily || '',
            displayName: font?.fullName || font?.fontFamily
          });
          if (font?.fontFamily) {
            this._dialogRef.triggerOk();
          }
          this._dialogRef.updateConfig({
            nzCancelDisabled: false
          });
        })
        .catch((e) => {
          this.uploadStatus = null;
          this._message.error(
            'Sorry, there was an error uploading. Please try again.'
          );
        });
    });

    return false;
  };

  private async _guessFontFamily(file: NzUploadFile): Promise<any> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        try {
          const fontMeta = FontName().parse(buffer)[0];
          return resolve(fontMeta);
        } catch (e) {
          return resolve({});
        }
      };
      reader.readAsArrayBuffer(file as any);
    });
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-abstract-event-view',
  templateUrl: './abstract-event-view.component.html',
  styleUrls: ['./abstract-event-view.component.less']
})
export class AbstractEventViewComponent {
  @Input() color: string;
}

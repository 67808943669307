import { Component, OnInit } from '@angular/core';

// App
import { IEventDropBase, IUserContent } from '../../types';
import { BaseComponent } from '../../models';
import { UserService, ApiService } from '../../services';

@Component({
  selector: 'lib-rsvp-confirmation',
  templateUrl: './rsvp-confirmation.component.html',
  styleUrls: ['./rsvp-confirmation.component.less']
})
export class RsvpConfirmationComponent extends BaseComponent implements OnInit {
  content: IEventDropBase;
  userContent: IUserContent;
  hasLoaded = false;

  constructor(private _api: ApiService, private _user: UserService) {
    super();
  }

  ngOnInit(): void {
    this._user
      .getUserContent$(this.content?.contentId)
      .pipe(this.takeUntilDestroy)
      .subscribe(async (userContent) => {
        this.userContent = userContent;
        if (!this.userContent?.shortLinks?.referralPageShortLink)
          await this._callReferralEndpoint(userContent.contentId);
        this.hasLoaded = true;
      });
  }

  private async _callReferralEndpoint(contentId: string) {
    const endpoint = `content/${contentId}/referral`;
    const result = await this._api.post<IUserContent>(endpoint, {});
    if (result) this.userContent = result;
  }
}

export const ANALYTICS_URL_TOKEN = 'analytics_api_url_token';
export const ROOT_HOST_TOKEN = 'root_host_token';
export const EMBEDS_URL_TOKEN = 'embeds_url_token';
export const COOKIE_PREFIX_TOKEN = 'cookie_prefix_token';
export const STRIPE_CLIENT_KEY_TOKEN = 'stripe_client_key_token';
export const API_URL_TOKEN = 'api_url_token';
export const SEGMENT_API_KEY = 'segment_api_key';
export const ANALYTICS_TRACKING_DEBUG_MODE = 'analytics_tracking_debug_mode';
export const SOCKET_SERVER_URL_TOKEN = 'socket_server_url_token';
export const FONT_BUCKET_TOKEN = 'font_bucket_token';
export const CORS_ANYWHERE_URL_TOKEN = 'cors_anywhere_url_token';
export const MMS_ATTACHMENT_BUCKET_TOKEN = 'mms_attachment_bucket_token';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { SOCIAL_LINKS } from '../../constants';
import { ISlug, ISocialIconsBlock } from '../../types';
import { buildSocialLinksObjectFromKeys } from '../../tools';

@Component({
  selector: 'lib-social-icons-view',
  templateUrl: './social-icons-view.component.html',
  styleUrls: ['./social-icons-view.component.less']
})
export class SocialIconsViewComponent implements OnChanges {
  @Input() iconOnly = true;
  @Input() slug: ISlug;
  @Input() block: ISocialIconsBlock;
  @Input() size: 'small' | 'default' | 'large' = 'large';
  @Input() preventDefault = false;
  @Input() center = true;
  @Input() type: 'text' | 'primary' | 'default' | 'dashed' = 'text';
  @Output() clicked = new EventEmitter<string>();

  links: Array<{ key: string; icon: string; label: string; url: string }>;

  handleClick(event, url: string) {
    if (this.preventDefault) event?.preventDefault();
    this.clicked.emit(url);
  }

  ngOnChanges() {
    this.links = Object.keys(SOCIAL_LINKS)
      .filter(
        (link) =>
          this.slug?.links?.[link]?.length > 0 &&
          this.block?.settings?.[link] !== false
      ) // !== false is used so that old users with undefined should be allowed
      .map((link) => buildSocialLinksObjectFromKeys(link, this.slug));
  }
}

<div class="youtube-embed-wrapper" *ngIf="safeEmbedCode">
    <div [innerHtml]="safeEmbedCode"></div>
</div>

<nz-card class="youtube-embed-placeholder card-background-color" *ngIf="!safeEmbedCode">
    <nz-card-meta nzDescription="Publish to view the rendered YouTube video." [nzAvatar]="avatarTemplate">
    </nz-card-meta>
</nz-card>

<ng-template #avatarTemplate>
    <nz-avatar nzIcon="youtube"></nz-avatar>
</ng-template>
<form
  nz-form
  nzLayout="vertical"
  [formGroup]="formGroup"
  class="drop-shadow-sliders-view-form"
>
  <nz-form-item>
    <nz-form-control nzErrorTip="That looks too long.">
      <nz-form-label nzFor="hOffset">Horizontal Offset</nz-form-label>
      <nz-slider formControlName="h" nzMax="48"></nz-slider>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control nzErrorTip="That looks too long.">
      <nz-form-label nzFor="vOffset">Vertical Offset</nz-form-label>
      <nz-slider formControlName="v" nzMax="48"></nz-slider>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control nzErrorTip="That looks too long.">
      <nz-form-label nzFor="blur">Blur</nz-form-label>
      <nz-slider formControlName="blur" nzMax="48"></nz-slider>
    </nz-form-control>
  </nz-form-item>
</form>

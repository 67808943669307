import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { EventMainButtonViewComponent } from '../views/event-main-button-view';
import { DirectivesModule } from './directives.module';

@NgModule({
  declarations: [
    EventMainButtonViewComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule
  ],
  exports: [
    EventMainButtonViewComponent
  ]
})
export class EventMainButtonViewModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAlertModule } from 'ng-zorro-antd/alert';

import { NewsletterCardViewComponent } from '../views/newsletter-card-view';
import { NewsletterMainButtonViewModule } from './newsletter-main-button-view.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { NewsletterShareButtonViewModule } from './newsletter-share-button-view.module';

@NgModule({
  declarations: [NewsletterCardViewComponent],
  imports: [
    CommonModule,
    NewsletterMainButtonViewModule,
    NewsletterShareButtonViewModule,
    SoftImageViewModule,
    NzCardModule,
    NzSkeletonModule,
    NzAlertModule
  ],
  exports: [NewsletterCardViewComponent]
})
export class NewsletterCardViewModule { }

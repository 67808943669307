import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { NewsletterMainButtonViewComponent } from '../views/newsletter-main-button-view';
import { DirectivesModule } from './directives.module';

@NgModule({
  declarations: [NewsletterMainButtonViewComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule
  ],
  exports: [NewsletterMainButtonViewComponent]
})
export class NewsletterMainButtonViewModule {}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Calendars } from '../../tools';
import { IContent, IEvent, IUserContent } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class SystemShareService {
  private _webNavigator: any = null;

  constructor(@Inject(PLATFORM_ID) private _platform) {
    if (isPlatformBrowser(this._platform))
      this._webNavigator = window.navigator;
  }

  get canShare(): boolean {
    return (
      this._webNavigator !== null && this._webNavigator.share !== undefined
    );
  }

  async share({
    title,
    text,
    url
  }: {
    title: string;
    text?: string;
    url?: string;
  }): Promise<{ shared: boolean; error?: string }> {
    if (!this.canShare)
      return { shared: false, error: "This platform doesn't support sharing" };
    else if (!text?.length && !url?.length)
      return { shared: false, error: 'Nothing was specified to share' };

    try {
      const isShared = await this._webNavigator.share({
        title,
        text,
        url
      });
      return { shared: isShared };
    } catch (error) {
      return { shared: false, error };
    }
  }

  // Helper for saving to gcal/ical
  private _getCalendarOptions(event: IEvent) {
    return !event
      ? {}
      : {
          title: event.title,
          description: `Join here: ${event.urls?.pageShortLink}`,
          location: event.urls?.pageShortLink,
          start: event.startMoment().toString(),
          end: event.endMoment().toString()
        };
  }

  // Helper for saving to ical
  saveToICal(event: IEvent) {
    if (isPlatformBrowser(this._platform)) {
      const options = this._getCalendarOptions(event);
      Calendars.downloadIcs(options);
    }
  }

  // Helper for saving to gcal
  saveToGoogleCalendar(event: IEvent) {
    if (isPlatformBrowser(this._platform)) {
      const options = this._getCalendarOptions(event);
      const url = Calendars.getGoogleCalendarUrl(options);
      window.open(url);
    }
  }

  // Open the system share sheet, prepopulated with the given content
  openContentShareSheet(content: IContent, userContent?: IUserContent) {
    const title = content?.title;
    let url = content?.shareUrl;

    if (content.isEvent || content.isDrop)
      url = userContent?.shortLinks?.referralPageShortLink ?? url;
    else if (content.isLink)
      url = userContent?.shortLinks?.clickThroughShortLink ?? url;
    else if (content.isNewsletter)
      url = userContent?.shortLinks?.referralPageShortLink ?? url;

    this.share({ title, url })
      .then((r) => console.log(r))
      .catch((e) => console.log(e));
  }
}

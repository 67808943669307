<div class="abstract-event card-background-color card-border-style">
  <svg viewBox="0 0 172 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="172" height="70" rx="4" fill="none"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M154.132 7.515C155.272 7.515 155.797 8.085 155.797 8.835C155.797 9.69 155.287 10.155 153.352 10.65C151.087 11.235 150.112 12.135 150.112 14.25C150.112 14.58 150.127 14.88 150.142 15.18H157.627V13.665H153.712C153.172 13.665 152.422 13.68 151.807 13.695C151.867 12.72 152.362 12.36 154.012 11.91C156.277 11.4 157.522 10.545 157.522 8.85C157.522 6.975 156.307 6 154.162 6C151.777 6 150.532 7.485 150.352 9.975L151.957 10.11C152.092 8.31 152.857 7.515 154.132 7.515Z"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M166 13.125V11.67H164.74V6.18H162.745L157.93 11.67V13.125H163.12V15.18H164.74V13.125H166ZM161.635 11.67C161.14 11.67 160.495 11.67 159.805 11.7L163.075 7.845L163.12 7.86V11.67H161.635Z"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M149.672 19.525C149.672 19.028 149.672 18.419 149.665 17.929H149.672C149.833 18.468 150.036 19.105 150.281 19.77L150.904 21.471H151.632L152.276 19.798C152.514 19.168 152.738 18.475 152.906 17.929C152.892 18.419 152.871 19.035 152.871 19.525V21.471H153.669V17.271H152.514L151.807 19.133C151.681 19.462 151.492 20.036 151.331 20.547C151.177 20.029 150.981 19.448 150.869 19.161L150.162 17.271H148.909V21.471H149.672V19.525Z"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M155.376 20.925C154.949 20.925 154.802 20.715 154.802 20.484C154.802 20.26 154.998 20.127 155.362 20.127H156.23V20.232C156.23 20.631 155.894 20.925 155.376 20.925ZM156.23 21.471H156.979V19.427C156.979 18.587 156.573 18.111 155.621 18.111C154.69 18.111 154.256 18.566 154.158 19.301L154.879 19.399C154.956 18.923 155.187 18.741 155.593 18.741C156.048 18.741 156.23 18.986 156.23 19.434V19.567H155.25C154.424 19.567 154.06 20.001 154.06 20.603C154.06 21.233 154.487 21.541 155.096 21.541C155.663 21.541 156.062 21.233 156.216 20.645H156.23V21.471Z"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M158.21 21.471V19.805C158.21 19.231 158.441 19.028 159.029 19.028H159.351V18.181H159.162C158.665 18.181 158.371 18.405 158.224 19.259H158.21V18.181H157.454V21.471H158.21Z"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M161.953 19.553L162.681 19.434C162.548 18.587 162.093 18.097 161.106 18.097C160.105 18.097 159.412 18.741 159.412 19.833C159.412 20.953 160.091 21.562 161.127 21.562C162.079 21.562 162.555 21.037 162.688 20.197L161.967 20.05C161.862 20.519 161.666 20.869 161.113 20.869C160.553 20.869 160.182 20.498 160.182 19.826C160.182 19.119 160.56 18.783 161.099 18.783C161.61 18.783 161.855 19.091 161.953 19.553Z"/>
    <path [ngStyle]="{'fill': color ? color : '#fff9f2'}" d="M163.781 19.525C163.781 19.056 164.075 18.776 164.558 18.776C165.069 18.776 165.244 19.126 165.244 19.525V21.471H166V19.329C166 18.615 165.699 18.097 164.908 18.097C164.355 18.097 163.935 18.384 163.788 19.084H163.781V17.18H163.025V21.471H163.781V19.525Z"/>
    <path [ngStyle]="{'stroke': color ? color : '#fff9f2'}"d="M6 50C9.22403 50 9.22403 52 12.4426 52C15.6612 52 15.6667 50 18.8853 50C22.1039 50 22.1093 52 25.3279 52C28.5465 52 28.5519 50 31.7705 50C34.9891 50 34.9946 52 38.2132 52C41.4318 52 41.4372 50 44.6612 50C47.8853 50 47.8798 52 51.1039 52C54.3279 52 54.3279 50 57.5519 50C60.776 50 60.776 52 64 52" stroke-width="1.5" stroke-miterlimit="10"/>
    <rect [ngStyle]="{'stroke': color ? color : '#fff9f2'}" x="6.75" y="56.75" width="18.5" height="6.5" rx="1.44158" stroke-width="1.5"/>
  </svg>
</div>

import { Component, Input, OnChanges } from '@angular/core';

import dayjs from 'dayjs';
import { IEvent, IUserContent } from '../../types';

@Component({
  selector: 'app-event-detail-view',
  templateUrl: './event-detail-view.component.html',
  styleUrls: ['./event-detail-view.component.less']
})
export class EventDetailViewComponent implements OnChanges {
  @Input() event: IEvent;
  @Input() userEvent: IUserContent;
  @Input() isLoading = false;
  @Input() shouldDisableButtons = false;
  registrationCloseDate: string;

  ngOnChanges(): void {
    this.registrationCloseDate = this.event?.registrationCloseDate
      ? dayjs(this.event.registrationCloseDate).format('MMM DD, YYYY')
      : null;
  }

  get paletteColor(): string {
    if (this.event?.styles?.detail?.headerImageColor?.length > 0)
      return this.event.styles.detail.headerImageColor;
    else if (this.event?.image?.palettes?.length > 0)
      return this.event.image.palettes[0];
    else return 'black';
  }

  get priceStr(): string {
    if (this.event?.tickets?.length > 0) {
      const price = (this.event.tickets[0]?.price ?? 0) / 100;
      return `$${price}`;
    }

    return null;
  }
}

import { Component, Input } from '@angular/core';
import { ISlug, IProfileBlock } from '../../types';

@Component({
  selector: 'lib-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.less']
})
export class ProfileViewComponent {
  @Input() slug: ISlug;
  @Input() block: IProfileBlock;

  get tagStyle(): string {
    const theme = this.slug?.theme;
    return theme
      ? `background-color: ${theme?.card?.backgroundColor}; color: ${theme?.card?.textColor};`
      : '';
  }
}

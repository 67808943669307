<!-- Show signup button if newsletter is published, and there's no user content or signup is disabled -->
<div *ngIf="newsletter?.published && !userContent?.rsvpEnabled">
  <button nz-button nzBlock nzSize="large" [nzLoading]="isLoading" [disabled]="isDisabled" (click)="handleRsvpClick()"
    nzType="primary" [logEvent]="userClickedPreEventCta"
    [cubeData]="{ entityType: 'newsletter', entityId: newsletter?.contentId }" [destination]="'cube'">
    {{
    newsletter?.buttonLabels?.pre
    ? newsletter.buttonLabels.pre
    : newsletter?.tickets?.length > 0
    ? 'Tickets'
    : 'Sign up'
    }}
  </button>
</div>

<!-- Show confirmed if it's published, untriggered, and we've rsvped -->
<div *ngIf="newsletter?.published && userContent?.rsvpEnabled">
  <button nz-button nzBlock nzSize="large" nzType="primary" nz-dropdown [nzDropdownMenu]="rsvpMenu"
    [nzLoading]="isLoading">
    You're confirmed
    <i nz-icon nzType="down"></i>
  </button>
</div>

<!-- REMOVE RSVP MENU -->
<nz-dropdown-menu #rsvpMenu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngIf="
        !userContent?.receiveNotifications?.email && newsletter?.hasEmailRequirement
      " (click)="handleEnableEmail()">
      <i nz-icon nzType="mail"></i> Turn on emails
    </li>

    <li nz-menu-item *ngIf="userContent?.receiveNotifications?.email" (click)="handleDisableEmail()">
      <i nz-icon nzType="stop"></i> Turn off emails
    </li>

    <li nz-menu-divider></li>

    <li nz-menu-item (click)="handleRemoveRSVP()">
      <i nz-icon nzType="delete"></i> Cancel subscription
    </li>
  </ul>
</nz-dropdown-menu>
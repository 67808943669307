<nz-layout class="event-cancel-message-box">
  <h2>
    {{ uploadStatus !== 'done' ? 'Import a custom font' : 'Almost done...' }}
  </h2>
  <nz-content class="content-area">
    <form nz-form nzLayout="vertical" [formGroup]="form">
      <div
        class="current-font"
        *ngIf="currentFont?.importUrl && uploadStatus !== 'done'"
      >
        <p>
          <label
            ><strong>Your current font:</strong>
            {{ currentFont?.displayName }}</label
          >
        </p>
      </div>

      <ng-container *ngIf="uploadStatus !== 'done'">
        <nz-form-item>
          <nz-upload
            nzType="drag"
            [nzDisabled]="uploadStatus"
            [nzMultiple]="false"
            nzAccept=".ttf,.eof,.otf,.woff,.woff2"
            [nzBeforeUpload]="uploadFile"
          >
            <p class="ant-upload-drag-icon">
              <i
                nz-icon
                [nzType]="uploadStatus ? 'loading' : 'cloud-upload'"
              ></i>
            </p>

            <p *ngIf="!uploadStatus" class="ant-upload-text">
              Click to browse or drag a file here
            </p>
            <p *ngIf="!uploadStatus" class="ant-upload-hint">
              Add your .otf, .ttf, .woff, or .woff2 file
            </p>

            <p *ngIf="uploadStatus" class="ant-upload-text">Hang tight...</p>
            <p *ngIf="uploadStatus" class="ant-upload-hint">
              {{ uploadStatus }}...
            </p>
          </nz-upload>
        </nz-form-item>
      </ng-container>

      <ng-container *ngIf="uploadStatus === 'done'">
        <p>
          We couldn't detect the font family name from your font file. Please
          enter it to use this font.
        </p>

        <nz-form-item>
          <input
            formControlName="fontFamily"
            nzSize="large"
            name="fontFamily"
            nz-input
            placeholder="Font Family"
          />
        </nz-form-item>
      </ng-container>
    </form>
  </nz-content>
</nz-layout>

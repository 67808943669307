import {
  Component,
  PLATFORM_ID,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// 3rd party
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { filter } from 'rxjs/operators';

// App
import { INewsletterListingBlock, INewsletter } from '../../types/content';
import { BaseComponent } from '../../models';
import { objectIdFromDate } from '../../tools';
import { ContentService } from '../../services';

// Helper method to filter out immaterial newsletter list
// updates
const newsletterListsAreEqual = (
  one: INewsletter[],
  two: INewsletter[]
): boolean => {
  if (one?.length !== two?.length) return false;
  let listsAreEqual = true;
  for (let i = 0; i < one.length; i++) {
    if (
      one[i]?.contentId !== two[i]?.contentId ||
      one[i]?.title !== two[i]?.title ||
      one[i]?.subtitle !== two[i]?.subtitle ||
      one[i]?.imageUrl !== two[i]?.imageUrl
    ) {
      listsAreEqual = false;
      break;
    }
  }
  return listsAreEqual;
};

type NewsletterBucketMap = { [label: string]: INewsletter[] };

/*
  Helper method to collapse together newsletters starting at the same
  time
*/
const newsletterBucketsFromNewsletters = (
  newsletters: INewsletter[]
): NewsletterBucketMap => {
  const weekStart = dayjs().startOf('week');
  return newsletters.reduce((prev, newsletter) => {
    const newsletterDate = dayjs(newsletter?.publishAt);
    const newsletterWeekStart = newsletterDate.startOf('week');
    const weekDiff = newsletterWeekStart.diff(weekStart, 'weeks');
    const label =
      weekDiff === 0
        ? 'This week'
        : weekDiff === 1
        ? 'Next week'
        : weekDiff === 2
        ? 'In 2 weeks'
        : weekDiff === 3
        ? 'In 3 weeks'
        : newsletterWeekStart.fromNow();

    const bucket = prev[label] || (prev[label] = new Array<INewsletter>());
    bucket.push(newsletter);
    return prev;
  }, {});
};

@Component({
  selector: 'lib-newsletter-listing',
  templateUrl: './newsletter-listing.component.html',
  styleUrls: ['./newsletter-listing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterListingComponent
  extends BaseComponent
  implements OnChanges
{
  @Output() cardClick = new EventEmitter<INewsletter>();
  @Input() block: INewsletterListingBlock;
  newsletters: INewsletter[] = [null];
  newsletterBuckets: NewsletterBucketMap = { '': [null, null] };

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _cdr: ChangeDetectorRef,
    private _content: ContentService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!isPlatformBrowser(this._platform)) return;

    const startQueryAt = objectIdFromDate(dayjs().startOf('day').toDate());
    this._content
      .getNewslettersForCurrentSlug$({
        startAfter: startQueryAt,
        limit: this.block?.limit,
        tag: this.block?.tag
      })
      // We don't want items to rebuild on an emission (which can happen at any time)
      // unless we actually have new newsletters to display
      .pipe(
        filter(
          (newsletters) =>
            !newsletterListsAreEqual(newsletters, this.newsletters)
        ),
        this.takeUntilChanges
      )
      .subscribe((newsletters) => {
        this.newsletters = newsletters;
        this.newsletterBuckets = newsletterBucketsFromNewsletters(
          this.newsletters
        );
        this._cdr.detectChanges();
      });
  }

  // Comparator for keyvalue pipe to force Angular to preserve
  // the order of the newsletter bucket map
  preserveOrder = (a, b): number => 0;

  newsletterTrackBy(idx: number, newsletter: INewsletter) {
    return newsletter?.contentId;
  }

  bucketTrackBy(idx: number, bucket: any) {
    return bucket?.key ?? idx;
  }

  handleCardClick(newsletter: INewsletter) {
    if (!newsletter) return;
    this.cardClick.next(newsletter);
  }
}

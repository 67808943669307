import { NgModule } from '@angular/core';
import { PoweredByViewComponent } from '../views/powered-by-view';

@NgModule({
    declarations: [
        PoweredByViewComponent
    ],
    exports: [
        PoweredByViewComponent
    ]
})
export class PoweredByViewModule { }

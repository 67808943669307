import FileSaver from 'file-saver';
import { toIcsParamString } from './data';
import { formatTimestampString } from './time';
import { safariFileSave } from './safariFileSave';

/**
 * Removes line breaks and ensures that the string is no
 * longer than maxLength chars (or 75 chars if none specified).
 */
export const formatText = (str) => {
  if (!str) {
    return '';
  }

  return str.replace(/\n/g, '\\n');
};

/**
 * The name of the file will be the event title with alphanumeric chars with the extension `.ics`.
 */
export const getBlob = (icsData) => {
  return new Blob([icsData], {
    type: 'application/octet-stream' // TODO: change to text/calendar?
  });
};

/**
 * Transforms given string to be valid file name.
 */
export const getFileName = (title) => {
  if (!title) {
    return 'event.ics';
  }
  return `${title.replace(/[^\w ]/g, '')}.ics`;
};

/**
 * Returns a random base 36 hash for iCal UID.
 */
export const getUid = () => {
  return Math.random().toString(36).substr(2);
};

/**
 * Returns the hostname for usage in `PRODID`. Returns `datebook` in Node.js.
 */
export const getProdId = () => {
  return typeof window !== 'undefined'
    ? window.location.host
    : 'datebook';
};

/**
 * Converts the given recurrence options to RFC????
 */
export const getRrule = (recurrence) => {
  const keys = {
    FREQ: 'frequency',
    INTERVAL: 'interval',
    COUNT: 'count',
    WKST: 'weekStart',
    BYDAY: 'weekdays',
    BYMONTHDAY: 'monthdays'
  };

  // map all user-defined keys onto the rrule object
  const rrule = Object
    .keys(keys)
    .filter((k) => recurrence.hasOwnProperty(keys[k]))
    .reduce((values, key) => {
      return Object.assign({}, values, {
        [key]: recurrence[keys[key]]
      });
    }, {});

  if (recurrence.end) {
    Object.assign(rrule, { UNTIL: formatTimestampString(recurrence.end) });
  }

  return toIcsParamString(rrule);
};

/**
 * Downloads the given ics as an iCalendar file.
 */
export const download = (title, data) => {
  const fileName = getFileName(title);
  const isSafari = ~navigator
    .userAgent
    .toLowerCase()
    .indexOf('safari');

  if (isSafari) {
    safariFileSave(data, fileName);
  } else {
    FileSaver.saveAs(getBlob(data), fileName);
  }
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { DropCardViewComponent } from '../views/drop-card-view';
import { DropMainButtonViewModule } from './drop-main-button-view.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
    declarations: [DropCardViewComponent],
    imports: [
        CommonModule,
        DropMainButtonViewModule,
        EventDropShareButtonViewModule,
        SoftImageViewModule,
        NzCardModule,
        NzSkeletonModule,
        NzAlertModule,
        NzTagModule
    ],
    exports: [DropCardViewComponent]
})
export class DropCardViewModule { }

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILink } from '../../types';

@Component({
  selector: 'lib-link-card-view',
  templateUrl: './link-card-view.component.html',
  styleUrls: ['./link-card-view.component.less']
})
export class LinkCardViewComponent {
  @Output() cardClick = new EventEmitter<ILink>();
  @Input() link: ILink;

  get href(): string {
    return this.link?.type === 'email'
      ? 'mailto:' + this.link?.url
      : this.link?.type === 'phoneNumber'
      ? 'sms://' + this.link?.url
      : this.link?.url;
  }

  handleClick(event) {
    event.preventDefault();
    this.cardClick.next(this.link);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';

// App
import { IUserContent, INewsletter } from '../../types';
import { CUBE_EVENTS } from '../../constants';
import { ContentInteractionsService } from '../../services';

/*
  Creates a main button (register/rsvp/purchase) for a given newsletter
*/

@Component({
  selector: 'lib-newsletter-main-button-view',
  templateUrl: './newsletter-main-button-view.component.html',
  styleUrls: ['./newsletter-main-button-view.component.less']
})
export class NewsletterMainButtonViewComponent {
  @Input() newsletter: INewsletter;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() forceDisabled = false;

  // Analytics events
  userClickedPreEventCta = CUBE_EVENTS.userClickedPreEventCta;
  userClickedPostEventCta = CUBE_EVENTS.userClickedPostEventCta;

  constructor(
    private _http: HttpClient,
    private _interactions: ContentInteractionsService
  ) {}

  get isDisabled(): boolean {
    return (
      this.forceDisabled ||
      (this.newsletter?.rsvpRestrictions &&
        this.newsletter?.rsvpRestrictions?.remaining < 1)
    );
  }

  handleJoinClick() {
    const trackerUrl = this.userContent?.shortLinks?.clickThroughShortLink;
    if (!trackerUrl) return;

    // Adding the `redirect=false` flag will stop the shortlink
    // service from actually doing the redirect (which would trigger
    // a CORS errror here) and instead just track the interaction
    // and return 200
    this._http.get(`${trackerUrl}/?redirect=false`).subscribe(
      (d) => console.log(`Joined: ${d}`),
      (e) => console.log(`Joined: ${e}`)
    );
  }

  handleRsvpClick() {
    this._interactions.registerForContent(this.newsletter);
  }

  handleRemoveRSVP() {
    this._interactions.unregisterForContent(this.newsletter);
  }

  handleEnableEmail() {
    this._interactions.toggleEmailForContent(this.newsletter, true);
  }

  handleDisableEmail() {
    this._interactions.toggleEmailForContent(this.newsletter, false);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { NewsletterDetailViewComponent } from '../views/newsletter-detail-view';
import { NewsletterMainButtonViewModule } from './newsletter-main-button-view.module';
import { NewsletterShareButtonViewModule } from './newsletter-share-button-view.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';

// Zorro
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [NewsletterDetailViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    NewsletterMainButtonViewModule,
    NewsletterShareButtonViewModule,
    SoftImageViewModule,
    SafeHtmlPipeModule,

    NzCardModule,
    NzSkeletonModule,
    NzButtonModule,
    NzIconModule,
    NzAlertModule,
    NzToolTipModule
  ],
  exports: [NewsletterDetailViewComponent]
})
export class NewsletterDetailViewModule {}

import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';

// App
import { IUserContent, IDrop } from '../../types';
import { CUBE_EVENTS } from '../../constants';
import { ContentInteractionsService } from '../../services';

/*
  Creates a main button (register/rsvp/purchase) for a given drop
*/

@Component({
  selector: 'lib-drop-main-button-view',
  templateUrl: './drop-main-button-view.component.html',
  styleUrls: ['./drop-main-button-view.component.less']
})
export class DropMainButtonViewComponent {
  @Input() drop: IDrop;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() forceDisabled = false;

  // Analytics events
  userClickedPreEventCta = CUBE_EVENTS.userClickedPreEventCta;
  userClickedPostEventCta = CUBE_EVENTS.userClickedPostEventCta;

  constructor(
    private _http: HttpClient,
    private _interactions: ContentInteractionsService
  ) {}

  get isDisabled(): boolean {
    return (
      this.forceDisabled ||
      (this.drop?.rsvpRestrictions &&
        this.drop?.rsvpRestrictions?.remaining < 1) ||
      (this.drop?.registrationCloseDate &&
        this.drop?.isPastRegistrationDeadline)
    );
  }

  handleJoinClick() {
    const trackerUrl = this.userContent?.shortLinks?.clickThroughShortLink;
    if (!trackerUrl) return;

    // Adding the `redirect=false` flag will stop the shortlink
    // service from actually doing the redirect (which would trigger
    // a CORS errror here) and instead just track the interaction
    // and return 200
    this._http.get(`${trackerUrl}/?redirect=false`).subscribe(
      (d) => console.log(`Joined: ${d}`),
      (e) => console.log(`Joined: ${e}`)
    );
  }

  handleRsvpClick() {
    this._interactions.registerForContent(this.drop);
  }

  handleRemoveRSVP() {
    this._interactions.unregisterForContent(this.drop);
  }

  handleEnableSMS() {
    this._interactions.toggleSmsForContent(this.drop, true);
  }

  handleDisableSMS() {
    this._interactions.toggleSmsForContent(this.drop, false);
  }

  handleEnableEmail() {
    this._interactions.toggleEmailForContent(this.drop, true);
  }

  handleDisableEmail() {
    this._interactions.toggleEmailForContent(this.drop, false);
  }
}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

// 3rd party
import { Analytics, AnalyticsInstance } from 'analytics';
import segmentPlugin from '@analytics/segment';

// App
import {
  SEGMENT_API_KEY,
  ANALYTICS_TRACKING_DEBUG_MODE
} from '../../constants';

// providers
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsTrackingService {
  private _analytics: AnalyticsInstance;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    @Inject(DOCUMENT) private _document,
    @Inject(SEGMENT_API_KEY) private _segmentApiKey: string,
    @Inject(ANALYTICS_TRACKING_DEBUG_MODE)
    private analyticsTrackingDebugMode: boolean,
    private _socket: SocketService
  ) {
    if (isPlatformBrowser(this._platform)) {
      this._analytics = Analytics({
        app: 'norby',
        debug: this.analyticsTrackingDebugMode,
        plugins: [
          segmentPlugin({
            writeKey: this._segmentApiKey
          })
        ]
      });
    }
  }

  /**
   * Track a page view
   * @param data Page data overrides
   * @param options Page tracking options
   * @param callback Callback to fire after page view call completes
   */
  page(data?: any, options?: any, callback?: (...params) => any) {
    if (!isPlatformBrowser(this._platform)) return;
    this._analytics?.page(data, options, callback);
  }

  pageViewStart({ path }: { path: string }) {
    if (!isPlatformBrowser(this._platform)) return;
    this._socket.sendMessage({
      message: 'interactions/page_view',
      payload: {
        path,
        action: 'start',
        domReferrer: this._document?.referrer
      }
    });
  }

  /**
   * Used to tell the socket to stop tracking a page view. Not generally needed as
   * each PageStart event closes out the tracking for the previous page, and starts
   * it for the newely specified page. Since we call pageViewStart on every route change
   * we do not need to issue stops.
   * @param param0
   */
  pageViewStop({ path }: { path: string }) {
    if (!isPlatformBrowser(this._platform)) return;
    this._socket.sendMessage({
      message: 'interactions/page_view',
      payload: {
        path,
        action: 'stop',
        domReferrer: this._document?.referrer
      }
    });
  }

  /**
   * Track a custom event
   * @param eventName Event name
   * @param payload Event payload
   * @param options Event options
   * @param callback Callback to fire after tracking completes
   */
  async track(
    eventName: string,
    payload?: any,
    options?: any,
    callback?: (...params) => any
  ) {
    if (!isPlatformBrowser(this._platform)) return;
    return this._analytics?.track(eventName, payload, options, callback);
  }

  /**
   * Identify a visitor
   * @param userId Unique ID of user
   * @param traits Object of user traits
   * @param options Options to pass to identify call
   * @param callback Callback function after identify completes
   */
  identify(
    userId: string,
    traits?: any,
    options?: any,
    callback?: (...params) => any
  ) {
    if (!isPlatformBrowser(this._platform)) return;
    this._analytics?.identify(userId, traits, options, callback);
  }
}

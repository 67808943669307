<nz-card
  (click)="handleClick()"
  [class.hoverable]="event"
  [nzCover]="!event || event?.image?.url ? coverTemplate : null"
  [style]="event?.cardCSS"
>
  <nz-skeleton
    *ngIf="!event"
    [nzLoading]="isLoadingEvent"
    [nzTitle]="false"
    [nzAvatar]="false"
    [nzParagraph]="{ rows: 2, width: 150 }"
  >
  </nz-skeleton>
  <div class="meta" *ngIf="event">
    <div
      *ngIf="
        event?.isFull ||
        (event?.hasCapacity && event?.rsvpRestrictions?.displayLimit) ||
        event?.isCanceled ||
        event?.registrationCloseDate
      "
    >
      <nz-tag
        nzColor="warning"
        *ngIf="event?.isFull || event?.isPastRegistrationDeadline"
      >
        <i nz-icon nzType="lock"></i>
        <span>Registration is closed</span>
      </nz-tag>
      <nz-tag
        nzColor="warning"
        *ngIf="
          event?.registrationCloseDate &&
          !event?.isPastRegistrationDeadline &&
          !event?.isFull
        "
      >
        <i nz-icon nzType="calendar"></i>
        <span>Register by {{ registrationCloseDate }}</span>
      </nz-tag>
      <nz-tag
        *ngIf="event?.hasCapacity && event?.rsvpRestrictions?.displayLimit"
        nzColor="processing"
      >
        <i nz-icon nzType="unlock"></i>
        <span>{{ event?.rsvpRestrictions?.remaining }} left</span>
      </nz-tag>
      <nz-tag *ngIf="event?.isCanceled" nzColor="error">
        <i nz-icon nzType="close-circle"></i>
        <span>Canceled</span>
      </nz-tag>
    </div>
    <h3 class="title card-text-color" [style]="event?.cardTextCSS">
      {{ event?.title }}
    </h3>
    <h4
      class="subtitle card-text-color"
      [style]="event?.cardTextCSS"
      *ngIf="event?.subtitle"
    >
      {{ event?.subtitle }}
    </h4>
    <p class="card-text-color" [style]="event?.cardTextCSS">
      {{ event?.summary }}
    </p>
    <div *ngIf="priceStr">
      <nz-alert nzType="info" [nzMessage]="priceStr"> </nz-alert>
    </div>
  </div>

  <div class="actions-container" (click)="$event.stopPropagation()">
    <div class="row" *ngIf="event?.published">
      <div class="wide">
        <lib-event-main-button-view
          [event]="event"
          [userEvent]="userEvent"
          [isLoading]="isLoadingUserContent"
          [forceDisabled]="isDisabled"
        >
        </lib-event-main-button-view>
      </div>
      <lib-event-drop-share-button-view
        [content]="event"
        [userContent]="userEvent"
        [isDisabled]="isDisabled"
      >
      </lib-event-drop-share-button-view>
    </div>
    <nz-skeleton *ngIf="!event?.published" [nzParagraph]="false"></nz-skeleton>
  </div>
</nz-card>

<ng-template #coverTemplate>
  <lib-soft-image-view
    [imageUrl]="event?.image?.url"
    [image]="event?.image"
    [imgixImage]="event?.imgixImage"
    [alt]="event?.title"
  >
  </lib-soft-image-view>
</ng-template>

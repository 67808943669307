import { NgModule } from '@angular/core';
import {
    LogEventDirective,
    MatchRouterIgnoreParamsDirective
} from '../directives/';


@NgModule({
    declarations: [
        LogEventDirective,
        MatchRouterIgnoreParamsDirective,
    ],
    exports: [
        LogEventDirective,
        MatchRouterIgnoreParamsDirective,
    ]
})
export class DirectivesModule { }

<nz-card
  [nzCover]="
    images?.length > 1
      ? carouselCoverTemplate
      : block?.imageUrl
      ? coverTemplate
      : null
  "
  class="card card-background-color"
>
  <h3 class="title card-text-color" *ngIf="!subscribed && block?.title">
    {{ block.title }}
  </h3>
  <p class="subtitle card-text-color" *ngIf="!subscribed && block?.subtitle">
    {{ block.subtitle }}
  </p>
  <h3
    class="title card-text-color"
    *ngIf="subscribed && block?.postSubmitTitle"
  >
    {{ block.postSubmitTitle }}
  </h3>
  <p
    class="subtitle card-text-color"
    *ngIf="subscribed && block?.postSubmitMessage"
  >
    {{ block.postSubmitMessage }}
  </p>
  <form
    *ngIf="!subscribed"
    nz-form
    [formGroup]="formGroup"
    nzLayout="vertical"
    (ngSubmit)="submit()"
  >
    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="urlSuffixButton">
      <input
        type="email"
        nz-input
        formControlName="email"
        placeholder="your@email.com"
      />
    </nz-input-group>
    <ng-template #urlSuffixButton>
      <button
        nz-button
        nzSize="large"
        [disabled]="!formGroup.valid || isLoading"
        [nzLoading]="isLoading"
        nzSearch
        nzType="primary"
      >
        {{ block?.buttonLabel || 'Subscribe' }}
      </button>
    </ng-template>
  </form>
</nz-card>

<ng-template #coverTemplate>
  <lib-soft-image-view
    [imageUrl]="block?.imageUrl"
    [image]="block?.image"
    [imgixImage]="block?.imgixImage"
    [alt]="block?.title"
  ></lib-soft-image-view>
</ng-template>

<ng-template #carouselCoverTemplate>
  <nz-carousel nzAutoPlay nzAutoPlaySpeed="6000" nzEffect="fade">
    <div nz-carousel-content *ngFor="let image of images; index as i">
      <lib-soft-image-view
        [alt]="block?.title"
        [imageUrl]="image?.url"
        [image]="image"
        [imgixImage]="imgixImages[i]"
      ></lib-soft-image-view>
    </div>
  </nz-carousel>
</ng-template>

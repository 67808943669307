import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INewsletter, IUserContent } from '../../types';

@Component({
  selector: 'app-newsletter-detail-view',
  templateUrl: './newsletter-detail-view.component.html',
  styleUrls: ['./newsletter-detail-view.component.less']
})
export class NewsletterDetailViewComponent {
  @Output() menuStateChanged = new EventEmitter<void>();
  @Input() newsletter: INewsletter;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() shouldDisableButtons = false;

  get paletteColor(): string {
    if (this.newsletter?.styles?.detail?.headerImageColor?.length > 0)
      return this.newsletter.styles.detail.headerImageColor;
    else if (this.newsletter?.image?.palettes?.length > 0)
      return this.newsletter.image.palettes[0];
    else return 'black';
  }

  get infoStr(): string {
    const price = this.priceStr;
    const spots = this.spotsRemainingStr;
    if (!price && !spots) return null;

    return [...(price ? [price] : []), ...(spots ? [spots] : [])].join(', ');
  }

  get spotsRemainingStr(): string {
    if (!this.newsletter?.rsvpRestrictions?.displayLimit) return null;

    const spots = this.newsletter?.rsvpRestrictions?.remaining;
    if (spots > 1) return `${spots} spots left`;
    else if (spots === 1) return `1 spot left!`;

    return 'Closed';
  }

  get priceStr(): string {
    if (this.newsletter?.tickets?.length > 0) {
      const price = (this.newsletter.tickets[0]?.price ?? 0) / 100;
      return `$${price}`;
    }

    return null;
  }

  handleMenuStateChanged() {
    this.menuStateChanged.next();
  }
}

export type SOCIAL_TYPES =
    'website' |
    'twitter' |
    'instagram' |
    'linkedIn' |
    'facebook' |
    'youtube' |
    'tiktok' |
    'soundcloud' |
    'clubhouse' |
    'twitch' |
    'spotify' |
    'venmo' |
    'cashapp' |
    'paypal' |
    'bandcamp' |
    'apple' |
    'medium' |
    'github' |
    'dribbble' |
    'patreon' |
    'discord' |
    'pinterest';

export class SocialLink {
    icon?: string;
    placeholder?: string;
    label?: string;
    color?: string;
}

export const SOCIAL_LINKS: { [key in SOCIAL_TYPES]: SocialLink } = {
    website: {
        icon: 'global',
        placeholder: 'https://www.you.com',
        label: 'Website',
        color: '#000'
    },
    twitter: {
        icon: 'twitter',
        placeholder: 'https://twitter.com/you',
        label: 'Twitter',
        color: '#1DA1F2'
    },
    instagram: {
        icon: 'instagram',
        placeholder: 'https://instagram.com/you',
        label: 'Instagram',
        color: '#405DE6'
    },
    linkedIn: {
        icon: 'linkedin',
        placeholder: 'https://linkedin.com/in/you',
        label: 'LinkedIn',
        color: '#0e76a8'
    },
    facebook: {
        icon: 'facebook',
        placeholder: 'https://facebook.com/you',
        label: 'Facebook',
        color: '#3b5998'
    },
    youtube: {
        icon: 'youtube',
        placeholder: 'https://youtube.com/channel/you',
        label: 'YouTube',
        color: '#c4302b'
    },
    tiktok: {
        icon: 'tiktok',
        placeholder: 'https://tiktok.com/@you',
        label: 'TikTok',
        color: '#EE1D52'
    },
    soundcloud: {
        icon: 'soundcloud',
        placeholder: 'https://soundcloud.com/you',
        label: 'SoundCloud',
        color: '#ff7700'
    },
    clubhouse: {
        icon: 'sound',
        placeholder: 'https://joinclubhouse.com/you',
        label: 'Clubhouse',
        color: '#f2d878'
    },
    twitch: {
        icon: 'twitch',
        placeholder: 'https://twitch.tv/you',
        label: 'Twitch',
        color: '#6441a5'
    },
    spotify: {
        icon: 'spotify',
        placeholder: 'https://spotify.com/you',
        label: 'Spotify',
        color: '#1DB954'
    },
    apple: {
        icon: 'apple',
        placeholder: 'https://podcasts.apple.com/you',
        label: 'Apple',
        color: '#f94c57'
    },
    medium: {
        icon: 'medium',
        placeholder: 'https://medium.com/@you',
        label: 'Medium',
        color: '#34a245'
    },
    github: {
        icon: 'github',
        placeholder: 'https://github.com/you',
        label: 'GitHub',
        color: '#24292e'
    },
    dribbble: {
        icon: 'dribbble',
        placeholder: 'https://dribbble.com/you',
        label: 'Dribbble',
        color: '#ea4c89'
    },
    patreon: {
        icon: 'patreon',
        placeholder: 'https://patreon.com/you',
        label: 'Patreon',
        color: '#f96854'
    },
    discord: {
        icon: 'discord',
        placeholder: 'https://you.discord.com/',
        label: 'Discord',
        color: '#5865F2'
    },
    pinterest: {
        icon: 'pinterest',
        placeholder: 'https://pinterest.com/you',
        label: 'Pinterest',
        color: '#E60023'
    },
    bandcamp: {
        icon: 'bandcamp',
        placeholder: 'https://bandcamp.com/you',
        label: 'Bandcamp',
        color: '#629aa9'
    },
    venmo: {
        icon: 'venmo',
        placeholder: 'https://venmo.com/you',
        label: 'Venmo',
        color: '#3d95ce'
    },
    cashapp: {
        icon: 'cashapp',
        placeholder: 'https://cashapp.com/you',
        label: 'Cash App',
        color: '#00C244'
    },
    paypal: {
        icon: 'paypal',
        placeholder: 'https://paypal.me/you',
        label: 'PayPal',
        color: '#3b7bbf'
    }
};

<nz-card
  (click)="handleClick()"
  [class.hoverable]="drop"
  [nzCover]="!drop || drop?.image?.url ? coverTemplate : null"
  [style]="drop?.cardCSS"
>
  <nz-skeleton
    *ngIf="!drop"
    [nzLoading]="isLoadingDrop"
    [nzTitle]="false"
    [nzAvatar]="false"
    [nzParagraph]="{ rows: 2, width: 150 }"
  >
  </nz-skeleton>
  <div class="meta" *ngIf="drop">
    <div
      *ngIf="
        drop?.isFull ||
        (drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit) ||
        drop?.registrationCloseDate
      "
    >
      <nz-tag
        nzColor="warning"
        *ngIf="drop?.isFull || drop?.isPastRegistrationDeadline"
      >
        <i nz-icon nzType="lock"></i>
        <span>Signups are closed</span>
      </nz-tag>
      <nz-tag
        nzColor="warning"
        *ngIf="
          drop?.registrationCloseDate &&
          !drop?.isPastRegistrationDeadline &&
          !drop?.isFull
        "
      >
        <i nz-icon nzType="calendar"></i>
        <span
          >{{ drop?.tickets?.length > 0 ? 'Get tickets by' : 'Sign up by' }}
          {{ registrationCloseDate }}</span
        >
      </nz-tag>
      <nz-tag
        *ngIf="drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit"
        nzColor="processing"
      >
        <i nz-icon nzType="unlock"></i>
        <span>{{ drop?.rsvpRestrictions?.remaining }} left</span>
      </nz-tag>
    </div>
    <h3 [style]="drop?.cardTextCSS" class="title card-text-color">
      {{ drop?.title }}
    </h3>
    <h4
      *ngIf="drop?.subtitle"
      [style]="drop?.cardTextCSS"
      class="subtitle card-text-color"
    >
      {{ drop?.subtitle }}
    </h4>
    <div *ngIf="priceStr" [style]="drop?.cardTextCSS">
      <nz-alert nzType="info" [nzMessage]="priceStr"> </nz-alert>
    </div>
  </div>

  <div class="actions-container" (click)="$event.stopPropagation()">
    <div class="row" *ngIf="drop?.published">
      <div class="wide">
        <lib-drop-main-button-view
          [drop]="drop"
          [userContent]="userContent"
          [isLoading]="isLoadingUserContent"
          [forceDisabled]="isDisabled"
        >
        </lib-drop-main-button-view>
      </div>
      <lib-event-drop-share-button-view
        [content]="drop"
        [userContent]="userContent"
        [isDisabled]="isDisabled"
      >
      </lib-event-drop-share-button-view>
    </div>
    <nz-skeleton *ngIf="!drop?.published" [nzParagraph]="false"></nz-skeleton>
  </div>
</nz-card>

<ng-template #coverTemplate>
  <lib-soft-image-view
    [imageUrl]="drop?.image?.url"
    [image]="drop?.image"
    [imgixImage]="drop?.imgixImage"
    [alt]="drop?.title"
  >
  </lib-soft-image-view>
</ng-template>

<lib-event-card-view
  *ngIf="content?.isEvent"
  [event]="content"
  [userEvent]="userContent"
  [isLoadingUserContent]="!hasLoaded"
  (cardClick)="handleClick()"
></lib-event-card-view>

<lib-drop-card-view
  *ngIf="content?.isDrop"
  [drop]="content"
  [userContent]="userContent"
  [isLoadingUserContent]="!hasLoaded"
  (cardClick)="handleClick()"
></lib-drop-card-view>

<lib-newsletter-card-view
  *ngIf="content?.isNewsletter"
  [newsletter]="content"
  [userContent]="userContent"
  [isLoadingUserContent]="!hasLoaded"
  (cardClick)="handleClick()"
  [isDisabled]="isFetching"
  (menuStateChanged)="handleMenuStateChanged()"
></lib-newsletter-card-view>

import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';

// App
import { IUserContent, IEvent } from '../../types';
import { CUBE_EVENTS } from '../../constants';
import { ContentInteractionsService } from '../../services';

/*
  Creates a main button (register/rsvp/purchase) for a given event
*/

@Component({
  selector: 'lib-event-main-button-view',
  templateUrl: './event-main-button-view.component.html',
  styleUrls: ['./event-main-button-view.component.less']
})
export class EventMainButtonViewComponent {
  @Input() event: IEvent;
  @Input() userEvent: IUserContent;
  @Input() isLoading = false;
  @Input() forceDisabled = false;

  // Analytics events
  userClickedPreEventCta = CUBE_EVENTS.userClickedPreEventCta;
  userClickedPostEventCta = CUBE_EVENTS.userClickedPostEventCta;

  constructor(
    private _http: HttpClient,
    private _interactions: ContentInteractionsService
  ) {}

  get isDisabled(): boolean {
    return (
      this.forceDisabled ||
      (this.event?.rsvpRestrictions &&
        this.event?.rsvpRestrictions?.remaining < 1) ||
      (this.event?.registrationCloseDate &&
        this.event?.isPastRegistrationDeadline)
    );
  }

  handleJoinClick() {
    const trackerUrl = this.userEvent?.shortLinks?.clickThroughShortLink;
    if (!trackerUrl) return;

    // Adding the `redirect=false` flag will stop the shortlink
    // service from actually doing the redirect (which would trigger
    // a CORS errror here) and instead just track the interaction
    // and return 200
    this._http.get(`${trackerUrl}/?redirect=false`).subscribe(
      (d) => console.log(`Joined: ${d}`),
      (e) => console.log(`Joined: ${e}`)
    );
  }

  handleRsvpClick() {
    this._interactions.registerForContent(this.event);
  }

  handleRemoveRSVP() {
    this._interactions.unregisterForContent(this.event);
  }

  handleEnableSMS() {
    this._interactions.toggleSmsForContent(this.event, true);
  }

  handleDisableSMS() {
    this._interactions.toggleSmsForContent(this.event, false);
  }

  handleEnableEmail() {
    this._interactions.toggleEmailForContent(this.event, true);
  }

  handleDisableEmail() {
    this._interactions.toggleEmailForContent(this.event, false);
  }
}

import {
  trigger,
  style,
  transition,
  animate
} from '@angular/animations';

const TRANSITION_DURATION = 0.12;
const TRANSITION_CURVE = 'ease-out';

// blocks animations on initial render
export const initialNoop =
  trigger('initalRenderNoop', [
    transition(':enter', [])
  ]);

export const fadeInOut =
  trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(`${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
        style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate(`${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
        style({ opacity: 0 })),
      style({ position: 'absolute', height: '0px' })
    ])
  ]);

// slides up on enter, down on leave
export const slideInOut =
  trigger('slideInOut', [
    transition('void => slide', [
      style({ transform: 'translateY(100%)', opacity: 0 }),
      animate(`${TRANSITION_DURATION}s ${TRANSITION_CURVE}`, style({ transform: 'translateY(0)', opacity: 1 })),
    ]),
    transition('slide => void', [
      animate(`${TRANSITION_DURATION}s ${TRANSITION_CURVE}`, style({ transform: 'translateY(100%)' }))
    ])
  ]);

// slides up on leave, down on enter
export const slideInOutReverse =
  trigger('slideInOutReverse', [
    transition('void => slide', [
      style({ transform: 'translateY(-100%)', opacity: 0 }),
      animate(`${TRANSITION_DURATION}s ${TRANSITION_CURVE}`, style({ transform: 'translateY(0)', opacity: 1 })),
    ]),
    transition('slide => void', [
      animate(`${TRANSITION_DURATION}s ${TRANSITION_CURVE}`, style({ transform: 'translateY(-100%)' }))
    ])
  ]);

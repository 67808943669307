<div class="container" *ngIf="slug && iconOnly" [class.center]="center">
  <ng-container *ngFor="let link of links">
    <a
      (click)="handleClick($event, link.url)"
      [attr.aria-label]="link.label"
      [href]="link.url"
      [nzSize]="size"
      [nzTooltipTitle]="link.label"
      [nzType]="type"
      class="text-color card-style-on-hover"
      nz-button
      nz-tooltip
      rel="noreferrer"
      target="_blank"
    >
      <i nz-icon [nzType]="link.icon"></i>
    </a>
  </ng-container>
</div>

<div class="container" *ngIf="slug && !iconOnly" [class.center]="center">
  <ng-container *ngFor="let link of links">
    <a
      (click)="handleClick($event, link.url)"
      [attr.aria-label]="link.label"
      [href]="link.url"
      [nzSize]="size"
      [nzType]="type"
      class="text-color card-style-on-hover"
      nz-button
      rel="noreferrer"
      target="_blank"
    >
      <i nz-icon [nzType]="link.icon"></i> <span>{{ link.label }}</span>
    </a>
  </ng-container>
</div>

import { Component, Input, OnChanges } from '@angular/core';
import { buildImageSrcset, getImageSrc } from '../../tools';
import { IHeaderBlock } from '../../types';

type ImageSet = {
  srcset?: string;
  src?: string;
};

@Component({
  selector: 'lib-header-view',
  templateUrl: './header-view.component.html',
  styleUrls: ['./header-view.component.less']
})
export class HeaderViewComponent implements OnChanges {
  @Input() block: IHeaderBlock;
  @Input() dangleAvatar = false; // avatar hangs over edge of header banner

  bgImage: ImageSet;
  avatarImage: ImageSet;
  imageInlineStyle: string;

  ngOnChanges(): void {
    this.bgImage = {
      src: getImageSrc(
        this.block?.backgroundImageUrl,
        this.block?.backgroundImgixImage
      ),
      srcset: buildImageSrcset(this.block?.backgroundImgixImage)
    };

    this.avatarImage = {
      src: getImageSrc(this.block?.avatarUrl, this.block?.avatarImgixImage),
      srcset: buildImageSrcset(this.block?.avatarImgixImage)
    };

    this.imageInlineStyle = this.block?.maskUrl
      ? `-webkit-mask-image: url(${this.block.maskUrl}); mask-image: url(${this.block.maskUrl})`
      : '';
  }

  get height(): string {
    return `${this.block?.height || 120}px`;
  }

  get backgroundColor(): string {
    return this.block?.backgroundGradient ?? this.block?.backgroundColor;
  }

  get href(): string {
    return this.block?.avatarClickThroughUrl ?? 'javascript:void(0);';
  }

  get isRelative(): boolean {
    return this.block?.avatarClickThroughUrlType === 'collection';
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';

import { NewsletterSignupViewComponent } from '../views/newsletter-signup-view';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
  declarations: [NewsletterSignupViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    NzCardModule,
    NzIconModule,
    NzInputModule,
    NzAvatarModule,
    NzButtonModule,
    NzFormModule,
    NzCarouselModule,

    SoftImageViewModule
  ],
  exports: [NewsletterSignupViewComponent]
})
export class NewsletterSignupViewModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-abstract-signup-view',
  templateUrl: './abstract-signup-view.component.html',
  styleUrls: ['./abstract-signup-view.component.less']
})
export class AbstractSignupViewComponent {
  @Input() color: string;
}

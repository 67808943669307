<div class="wrapper" #wrapper [style.height]="height" *ngIf="isBrowser">
    <div class="container" [class.abstract]="showAsAbstract" [class.desktop-container]="platform === 'desktop'"
        [style]="containerCss">

        <div class="inner" [style]="backgroundCss">

            <div class="content">

                <lib-collection-view [collection]="collection" [slug]="slug" [applyThemeStyles]="true"
                    [showAsAbstract]="showAsAbstract">
                </lib-collection-view>

            </div>

        </div>

    </div>
</div>
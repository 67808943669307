<form class="theme" nz-form [formGroup]="appearanceForm" nzLayout="vertical">
  <h3>Basic</h3>

  <p>Set your default background and typography styles.</p>

  <nz-collapse nzAccordion>
    <nz-collapse-panel nzHeader="Background" formGroupName="layout">
      <nz-form-item>
        <nz-form-label
          nzFor="backgroundColor"
          nzTooltipTitle="Default background fill mode"
        >
          Mode
        </nz-form-label>
        <nz-select
          (ngModelChange)="onBgModeOptionChanged($event)"
          [(ngModel)]="backgroundMode"
          nzSize="large"
          [ngModelOptions]="{ standalone: true }"
        >
          <nz-option nzValue="image" nzLabel="Image"></nz-option>
          <nz-option nzValue="color" nzLabel="Color"></nz-option>
          <nz-option nzValue="gradient" nzLabel="Gradient"></nz-option>
        </nz-select>
      </nz-form-item>

      <nz-form-item *ngIf="backgroundMode === 'image'">
        <nz-form-label nzTooltipTitle="Default background image for every page">
          Image
        </nz-form-label>
        <nz-form-control class="background-image">
          <nz-upload
            [(nzFileList)]="imagePaths"
            [nzBeforeUpload]="uploadFile"
            [nzShowButton]="!imagePaths?.length"
            [nzShowUploadList]="true"
            [nzRemove]="onRemove"
            nzListType="picture-card"
            nzName="banner"
          >
            <ng-container>
              <i
                nz-icon
                [nzType]="isUploadingBackground ? 'loading' : 'plus'"
              ></i>
              {{ isUploadingBackground ? 'Uploading...' : 'Upload' }}
            </ng-container>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="backgroundMode === 'color'">
        <nz-form-label
          nzFor="backgroundColor"
          nzTooltipTitle="Default background color for all pages"
        >
          Color
        </nz-form-label>
        <nz-form-control nzErrorTip="That looks too long to be a color.">
          <input
            nz-input
            nzSize="large"
            formControlName="backgroundColor"
            [colorPicker]="layoutBackgroundColor"
            [value]="layoutBackgroundColor"
            (colorPickerChange)="layoutBackgroundColor = $event"
            cpAlphaChannel="disabled"
            [cpDisableInput]="true"
            cpPosition="bottom"
            placeholder="#FFE87F"
          />
        </nz-form-control>
      </nz-form-item>

      <ng-container *ngIf="backgroundMode === 'gradient'">
        <nz-form-item>
          <nz-form-label
            nzFor="backgroundGradient"
            nzTooltipTitle="Click to add color stops"
          >
            Gradient
          </nz-form-label>
          <lib-gradient-generator
            (handleValueChanged)="onGradientValueChange($event)"
            [initialValue]="appearanceForm?.value?.layout?.backgroundGradient"
          ></lib-gradient-generator>
        </nz-form-item>
      </ng-container>
    </nz-collapse-panel>

    <nz-collapse-panel nzHeader="Fonts" formGroupName="fonts">
      <p>
        Font settings affect all of your pages. Choose from supported Google
        Fonts or import your own.
      </p>

      <nz-form-item *ngFor="let item of typeLevels; trackBy: trackBy">
        <nz-form-label [nzTooltipTitle]="item?.tooltip">{{
          item?.label
        }}</nz-form-label>
        <nz-input-group nzSize="large" nzCompact class="font-row">
          <nz-input-number
            *ngIf="item?.showSize"
            nzSize="large"
            [ngModelOptions]="{ standalone: true }"
            [ngModel]="appearanceForm?.value?.fonts[item?.identifier]?.fontSize"
            [nzMax]="200"
            [nzMin]="1"
            [nzSize]="'large'"
            (ngModelChange)="handleFontSizeChange($event, item?.identifier)"
            [nzStep]="1"
          ></nz-input-number>

          <nz-select
            [compareWith]="fontCompare"
            [formControlName]="item?.identifier"
            [nzCustomTemplate]="selectedFontTemplate"
            nzPlaceHolder="Choose a font..."
            nzSize="large"
          >
            <nz-option
              *ngFor="let font of availableFonts"
              [nzLabel]="font?.displayName"
              [nzValue]="font"
              nzCustomContent
            >
              <div class="custom-option">
                <span
                  *ngIf="!font?.imageUrl"
                  [style.fontFamily]="font?.fontFamily"
                  >{{ font?.displayName || 'Custom' }}</span
                >
                <img
                  *ngIf="font?.imageUrl"
                  [src]="font?.imageUrl"
                  [alt]="font?.displayName"
                />
              </div>
            </nz-option>
          </nz-select>

          <button
            nz-button
            (click)="showCustomFontConfigDialog(item?.identifier)"
            nzSize="large"
          >
            <i nz-icon nzType="plus"></i> Import
          </button>
        </nz-input-group>
      </nz-form-item>
    </nz-collapse-panel>

    <nz-collapse-panel
      class="text-section"
      formGroupName="text"
      nzHeader="Text"
    >
      <p>These settings affect text in rich text and profile blocks.</p>

      <nz-form-item>
        <nz-form-label nzFor="color">Text color </nz-form-label>
        <nz-form-control nzErrorTip="That looks too long to be a color.">
          <input
            nz-input
            nzSize="large"
            formControlName="color"
            [colorPicker]="textColor"
            [value]="textColor"
            (colorPickerChange)="textColor = $event"
            cpAlphaChannel="disabled"
            cpPosition="bottom"
            [cpDisableInput]="true"
            placeholder="#111111"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-collapse-panel>

    <nz-collapse-panel formGroupName="link" nzHeader="Links">
      <p>These settings affect text links on all pages and blocks.</p>

      <nz-form-item>
        <nz-form-label
          nzFor="textColor"
          nzTooltipTitle="Text color used for links"
          >Link text color</nz-form-label
        >
        <nz-form-control nzErrorTip="That looks too long to be a color.">
          <input
            nz-input
            nzSize="large"
            formControlName="textColor"
            [colorPicker]="linkTextColor"
            [value]="linkTextColor"
            (colorPickerChange)="linkTextColor = $event"
            cpAlphaChannel="disabled"
            cpPosition="bottom"
            [cpDisableInput]="true"
            placeholder="#004C4C"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label
          nzFor="hoverTextColor"
          nzTooltipTitle="Color used when hovering over a text link"
          >Link text color (hover)
        </nz-form-label>
        <nz-form-control nzErrorTip="That looks too long to be a color.">
          <input
            nz-input
            nzSize="large"
            formControlName="hoverTextColor"
            [colorPicker]="linkHoverTextColor"
            [value]="linkHoverTextColor"
            (colorPickerChange)="linkHoverTextColor = $event"
            cpPosition="bottom"
            [cpDisableInput]="true"
            cpAlphaChannel="disabled"
            placeholder="#CCCCCC"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-divider></nz-divider>

  <div class="cards-section">
    <h3>Cards</h3>

    <p>
      Cards are used by many blocks including links, events, and signups. You
      can create additional reusable styles that you can easily apply to
      different blocks.
    </p>

    <nz-collapse nzAccordion>
      <nz-collapse-panel formGroupName="card" nzHeader="Default">
        <nz-form-item>
          <nz-form-label nzFor="borderRadius">Card border radius</nz-form-label>
          <nz-form-control nzErrorTip="That looks too long.">
            <nz-slider formControlName="borderRadius" nzMax="48"></nz-slider>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="borderWidth">Card border width</nz-form-label>
          <nz-form-control nzErrorTip="That looks too long.">
            <nz-slider formControlName="borderWidth" nzMax="48"></nz-slider>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label
            nzFor="dropShadow"
            nzTooltipTitle="Use the CSS box-shadow format"
          >
            Card drop shadow
          </nz-form-label>
          <nz-form-control [nzExtra]="cardDropShadow">
            <input
              (colorPickerChange)="onDropShadowInputChange($event, 'card')"
              [colorPicker]="cardDropShadowColor"
              [cpDisableInput]="true"
              [cpExtraTemplate]="dropShadow"
              cpAlphaChannel="disabled"
              cpPosition="bottom"
              formControlName="dropShadow"
              nz-input
              nzSize="large"
              placeholder="6px 6px 0px #000"
              (cpInputChange)="onDropShadowInputChange($event, 'card')"
            />
          </nz-form-control>
          <ng-template #cardDropShadow>
            <a [href]="dropShadowExplainerUrl" target="_blank" rel="noreferrer"
              >How do I format drop shadows?</a
            >
          </ng-template>

          <ng-template #dropShadow>
            <div class="drop-shadow-sliders">
              <lib-drop-shadow-sliders-view
                (shadowChanged)="handleShadowChange($event, 'card')"
                [values]="cardDropShadowParts"
              >
              </lib-drop-shadow-sliders-view>
            </div>
          </ng-template>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label
            nzFor="backgroundColor"
            nzTooltipTitle="This can be overridden for individual cards"
          >
            Card background color
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="backgroundColor"
              [colorPicker]="cardBackgroundColor"
              [value]="cardBackgroundColor"
              (colorPickerChange)="cardBackgroundColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#4BDDC5"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label
            nzFor="textColor"
            nzTooltipTitle="This can be overridden for individual cards"
          >
            Card text color
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="textColor"
              [colorPicker]="cardTextColor"
              [value]="cardTextColor"
              (colorPickerChange)="cardTextColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="borderColor"> Card border color </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="borderColor"
              [colorPicker]="cardBorderColor"
              [value]="cardBorderColor"
              (colorPickerChange)="cardBorderColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-collapse-panel>
      <ng-container formArrayName="customCards">
        <nz-collapse-panel
          *ngFor="let ctrl of customCardsStylesFormArray.controls; index as i"
          [formArrayName]="i"
          [formGroup]="ctrl"
          [nzExtra]="cardExtraTpl"
          [nzHeader]="ctrl?.value?.name"
        >
          <nz-form-item>
            <nz-form-label nzFor="borderRadius"
              >Card border radius</nz-form-label
            >
            <nz-form-control nzErrorTip="That looks too long.">
              <nz-slider formControlName="borderRadius" nzMax="48"></nz-slider>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="borderWidth">Card border width</nz-form-label>
            <nz-form-control nzErrorTip="That looks too long.">
              <nz-slider formControlName="borderWidth" nzMax="48"></nz-slider>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label
              nzFor="dropShadow"
              nzTooltipTitle="Use the CSS box-shadow format"
            >
              Card drop shadow
            </nz-form-label>
            <nz-form-control [nzExtra]="cardDropShadow">
              <input
                (colorPickerChange)="
                  onDropShadowInputChange($event, 'customCardsStyle', i)
                "
                [colorPicker]="
                  getCustomCardStylesDropShadowColorAtIndex(i, 'cards')
                "
                [cpDisableInput]="true"
                [cpExtraTemplate]="dropShadow"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="dropShadow"
                nz-input
                nzSize="large"
                placeholder="6px 6px 0px #000"
              />
            </nz-form-control>
            <ng-template #cardDropShadow>
              <a
                [href]="dropShadowExplainerUrl"
                rel="noreferrer"
                target="_blank"
                >How do I format drop shadows?</a
              >
            </ng-template>

            <ng-template #dropShadow>
              <div class="drop-shadow-sliders">
                <lib-drop-shadow-sliders-view
                  (shadowChanged)="
                    handleShadowChange($event, 'customCardsStyle', i)
                  "
                  [values]="customCardsStyleDropShadowParts[i.toString()]"
                >
                </lib-drop-shadow-sliders-view>
              </div>
            </ng-template>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label
              nzFor="backgroundColor"
              nzTooltipTitle="This can be overridden for individual cards"
            >
              Card background color
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="
                  setCardBackgroundColorAtIndex(i, $event, 'cards')
                "
                [colorPicker]="getCardBackgroundColorAtIndex(i, 'cards')"
                [cpDisableInput]="true"
                [value]="getCardBackgroundColorAtIndex(i, 'cards')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="backgroundColor"
                nz-input
                nzSize="large"
                placeholder="#4BDDC5"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label
              nzFor="textColor"
              nzTooltipTitle="This can be overridden for individual cards"
            >
              Card text color
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="setTextColorAtIndex(i, $event, 'cards')"
                [colorPicker]="getTextColorAtIndex(i, 'cards')"
                [cpDisableInput]="true"
                [value]="getTextColorAtIndex(i, 'cards')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="textColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="borderColor">
              Card border color
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="setBorderColorAtIndex(i, $event, 'cards')"
                [colorPicker]="getBorderColorAtIndex(i, 'cards')"
                [cpDisableInput]="true"
                [value]="getBorderColorAtIndex(i, 'cards')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="borderColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <ng-template #cardExtraTpl>
            <button
              (click)="$event.stopPropagation()"
              nz-button
              nz-popconfirm
              nzDanger
              nzType="text"
              nzSize="small"
              nzOkText="Delete"
              nzPopconfirmTitle="Are you sure? This is permanent!"
              (nzOnConfirm)="deleteCustomCardStyle(i, 'cards')"
            >
              <i nz-icon nzType="delete"></i> Delete
            </button>
          </ng-template>
        </nz-collapse-panel>
      </ng-container>
    </nz-collapse>

    <button
      class="add-styles-button"
      nz-button
      nzType="text"
      (click)="launchCustomContentStyleItemDialog('cards')"
    >
      <i nz-icon nzType="plus"></i>
      Add new
    </button>
  </div>

  <nz-divider></nz-divider>

  <div class="buttons-section">
    <h3>Buttons</h3>

    <p>
      Primary buttons are used for CTAs like signup, register, and purchase
      buttons. Secondary buttons are used for other actions like sharing and
      adding to calendar.
    </p>

    <nz-collapse nzAccordion>
      <nz-collapse-panel formGroupName="button" nzHeader="Primary">
        <nz-form-item>
          <nz-form-label nzFor="borderRadius"
            >Button border radius</nz-form-label
          >
          <nz-form-control nzErrorTip="That looks too long.">
            <nz-slider formControlName="borderRadius" nzMax="48"></nz-slider>
          </nz-form-control>
        </nz-form-item>

        <!-- Not built for input / button groups yet -->
        <!-- <nz-form-item>
            <nz-form-label nzFor="borderWidth">Button border width</nz-form-label>
            <nz-form-control nzErrorTip="That looks too long.">
                <nz-slider formControlName="borderWidth" nzMax="48"></nz-slider>
            </nz-form-control>
        </nz-form-item> -->

        <nz-form-item>
          <nz-form-label
            nzFor="dropShadow"
            nzTooltipTitle="Use the CSS box-shadow format"
          >
            Button drop shadow
          </nz-form-label>
          <nz-form-control [nzExtra]="buttonDropShadow">
            <input
              (colorPickerChange)="onDropShadowInputChange($event, 'button')"
              [colorPicker]="primaryButtonDropShadowColor"
              [cpDisableInput]="true"
              [cpExtraTemplate]="primaryButtonDropShadow"
              cpAlphaChannel="disabled"
              cpPosition="bottom"
              formControlName="dropShadow"
              nz-input
              nzSize="large"
              placeholder="8px 8px 4px #ffffff"
              (cpInputChange)="onDropShadowInputChange($event, 'button')"
            />
          </nz-form-control>
          <ng-template #buttonDropShadow>
            <a [href]="dropShadowExplainerUrl" target="_blank" rel="noreferrer"
              >How do I format drop shadows?</a
            >
          </ng-template>
          <ng-template #primaryButtonDropShadow>
            <div class="drop-shadow-sliders">
              <lib-drop-shadow-sliders-view
                (shadowChanged)="handleShadowChange($event, 'button')"
                [values]="primaryButtonDropShadowParts"
              ></lib-drop-shadow-sliders-view>
            </div>
          </ng-template>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="backgroundColor">
            Button background color
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="backgroundColor"
              [colorPicker]="buttonBackgroundColor"
              [value]="buttonBackgroundColor"
              (colorPickerChange)="buttonBackgroundColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#4BDDC5"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="textColor"> Button text color </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="textColor"
              [colorPicker]="buttonTextColor"
              [value]="buttonTextColor"
              (colorPickerChange)="buttonTextColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="borderColor">
            Button border color
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="borderColor"
              [colorPicker]="buttonBorderColor"
              [value]="buttonBorderColor"
              (colorPickerChange)="buttonBorderColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="hoverBackgroundColor">
            Button background color (hover)
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="hoverBackgroundColor"
              [colorPicker]="buttonHoverBackgroundColor"
              [value]="buttonHoverBackgroundColor"
              (colorPickerChange)="buttonHoverBackgroundColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#4BDDC5"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="hoverTextColor">
            Button text color (hover)
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="hoverTextColor"
              [colorPicker]="buttonHoverTextColor"
              [value]="buttonHoverTextColor"
              (colorPickerChange)="buttonHoverTextColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="hoverBorderColor">
            Button border color (hover)
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="hoverBorderColor"
              [colorPicker]="buttonHoverBorderColor"
              [value]="buttonHoverBorderColor"
              (colorPickerChange)="buttonHoverBorderColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-collapse-panel>

      <nz-collapse-panel
        class="secondary-button-section"
        formGroupName="secondaryButton"
        nzHeader="Secondary"
      >
        <nz-form-item>
          <nz-form-label nzFor="borderRadius"
            >Button border radius</nz-form-label
          >
          <nz-form-control nzErrorTip="That looks too long.">
            <nz-slider formControlName="borderRadius" nzMax="48"></nz-slider>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label
            nzFor="dropShadow"
            nzTooltipTitle="Use the CSS box-shadow format"
          >
            Button drop shadow
          </nz-form-label>
          <nz-form-control [nzExtra]="buttonDropShadow">
            <input
              (colorPickerChange)="
                onDropShadowInputChange($event, 'secondaryButton')
              "
              [colorPicker]="secondaryButtonDropShadowColor"
              [cpDisableInput]="true"
              [cpExtraTemplate]="secondaryButtonDropShadow"
              cpAlphaChannel="disabled"
              cpPosition="bottom"
              formControlName="dropShadow"
              nz-input
              nzSize="large"
              placeholder="8px 8px 4px #ffffff"
              (cpInputChange)="
                onDropShadowInputChange($event, 'secondaryButton')
              "
            />
          </nz-form-control>
          <ng-template #buttonDropShadow>
            <a [href]="dropShadowExplainerUrl" target="_blank" rel="noreferrer"
              >How do I format drop shadows?</a
            >
          </ng-template>
          <ng-template #secondaryButtonDropShadow>
            <div class="drop-shadow-sliders">
              <lib-drop-shadow-sliders-view
                (shadowChanged)="handleShadowChange($event, 'secondaryButton')"
                [values]="secondaryButtonDropShadowParts"
              ></lib-drop-shadow-sliders-view>
            </div>
          </ng-template>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="backgroundColor">
            Button background color
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="backgroundColor"
              [colorPicker]="secondaryButtonBackgroundColor"
              [value]="secondaryButtonBackgroundColor"
              (colorPickerChange)="secondaryButtonBackgroundColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#4BDDC5"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="textColor"> Button text color </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="textColor"
              [colorPicker]="secondaryButtonTextColor"
              [value]="secondaryButtonTextColor"
              (colorPickerChange)="secondaryButtonTextColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="borderColor">
            Button border color
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="borderColor"
              [colorPicker]="secondaryButtonBorderColor"
              [value]="secondaryButtonBorderColor"
              (colorPickerChange)="secondaryButtonBorderColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="hoverBackgroundColor">
            Button background color (hover)
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="hoverBackgroundColor"
              [colorPicker]="secondaryButtonHoverBackgroundColor"
              [value]="secondaryButtonHoverBackgroundColor"
              (colorPickerChange)="secondaryButtonHoverBackgroundColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#4BDDC5"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="hoverTextColor">
            Button text color (hover)
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="hoverTextColor"
              [colorPicker]="secondaryButtonHoverTextColor"
              [value]="secondaryButtonHoverTextColor"
              (colorPickerChange)="secondaryButtonHoverTextColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="hoverBorderColor">
            Button border color (hover)
          </nz-form-label>
          <nz-form-control nzErrorTip="That looks too long to be a color.">
            <input
              nz-input
              nzSize="large"
              formControlName="hoverBorderColor"
              [colorPicker]="secondaryButtonHoverBorderColor"
              [value]="secondaryButtonHoverBorderColor"
              (colorPickerChange)="secondaryButtonHoverBorderColor = $event"
              cpPosition="bottom"
              [cpDisableInput]="true"
              cpAlphaChannel="disabled"
              placeholder="#FF5F43"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-collapse-panel>

      <ng-container formArrayName="customButtons">
        <nz-collapse-panel
          *ngFor="let ctrl of customButtonsStylesFormArray.controls; index as i"
          [formArrayName]="i"
          [formGroup]="ctrl"
          [nzExtra]="buttonExtraTpl"
          [nzHeader]="ctrl?.value?.name"
        >
          <nz-form-item>
            <nz-form-label nzFor="borderRadius"
              >Button border radius</nz-form-label
            >
            <nz-form-control nzErrorTip="That looks too long.">
              <nz-slider formControlName="borderRadius" nzMax="48"></nz-slider>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label
              nzFor="dropShadow"
              nzTooltipTitle="Use the CSS box-shadow format"
            >
              Button drop shadow
            </nz-form-label>
            <nz-form-control [nzExtra]="cardDropShadow">
              <input
                (colorPickerChange)="
                  onDropShadowInputChange($event, 'customButtonsStyle', i)
                "
                [colorPicker]="
                  getCustomCardStylesDropShadowColorAtIndex(i, 'buttons')
                "
                [cpDisableInput]="true"
                [cpExtraTemplate]="dp"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="dropShadow"
                nz-input
                nzSize="large"
                placeholder="8px 8px 4px #ffffff"
              />
            </nz-form-control>
            <ng-template #cardDropShadow>
              <a
                [href]="dropShadowExplainerUrl"
                target="_blank"
                rel="noreferrer"
                >How do I format drop shadows?</a
              >
            </ng-template>
            <ng-template #dp>
              <div class="drop-shadow-sliders">
                <lib-drop-shadow-sliders-view
                  (shadowChanged)="
                    handleShadowChange($event, 'customButtonsStyle', i)
                  "
                  [values]="customButtonsStyleDropShadowParts[i.toString()]"
                ></lib-drop-shadow-sliders-view>
              </div>
            </ng-template>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="backgroundColor">
              Button background color
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="
                  setCardBackgroundColorAtIndex(i, $event, 'buttons')
                "
                [colorPicker]="getCardBackgroundColorAtIndex(i, 'buttons')"
                [cpDisableInput]="true"
                [value]="getCardBackgroundColorAtIndex(i, 'buttons')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="backgroundColor"
                nz-input
                nzSize="large"
                placeholder="#4BDDC5"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="textColor"> Button text color </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="setTextColorAtIndex(i, $event, 'buttons')"
                [colorPicker]="getTextColorAtIndex(i, 'buttons')"
                [cpDisableInput]="true"
                [value]="getTextColorAtIndex(i, 'buttons')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="textColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="borderColor">
              Button border color
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="
                  setBorderColorAtIndex(i, $event, 'buttons')
                "
                [colorPicker]="getBorderColorAtIndex(i, 'buttons')"
                [cpDisableInput]="true"
                [value]="getBorderColorAtIndex(i, 'buttons')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="borderColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="hoverBackgroundColor">
              Button background color (hover)
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="
                  setHoverBgColorAtIndex(i, $event, 'buttons')
                "
                [colorPicker]="getHoverBgColorAtIndex(i, 'buttons')"
                [cpDisableInput]="true"
                [value]="getHoverBgColorAtIndex(i, 'buttons')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="hoverBackgroundColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="hoverTextColor">
              Button text color (hover)
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="setHoverColorAtIndex(i, $event, 'buttons')"
                [colorPicker]="getHoverColorAtIndex(i, 'buttons')"
                [cpDisableInput]="true"
                [value]="getHoverColorAtIndex(i, 'buttons')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="hoverTextColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="hoverBorderColor">
              Button border color (hover)
            </nz-form-label>
            <nz-form-control nzErrorTip="That looks too long to be a color.">
              <input
                (colorPickerChange)="
                  setHoverBorderColorAtIndex(i, $event, 'buttons')
                "
                [colorPicker]="getHoverBorderColorAtIndex(i, 'buttons')"
                [cpDisableInput]="true"
                [value]="getHoverBorderColorAtIndex(i, 'buttons')"
                cpAlphaChannel="disabled"
                cpPosition="bottom"
                formControlName="hoverBorderColor"
                nz-input
                nzSize="large"
                placeholder="#FF5F43"
              />
            </nz-form-control>
          </nz-form-item>

          <ng-template #buttonExtraTpl>
            <button
              (click)="$event.stopPropagation()"
              nz-button
              nz-popconfirm
              nzDanger
              nzType="text"
              nzSize="small"
              nzOkText="Delete"
              nzPopconfirmTitle="Are you sure? This is permanent!"
              (nzOnConfirm)="deleteCustomCardStyle(i, 'buttons')"
            >
              <i nz-icon nzType="delete"></i> Delete
            </button>
          </ng-template>
        </nz-collapse-panel>
      </ng-container>
    </nz-collapse>

    <!-- DISABLED FOR NOW -->
    <!-- <button
      class="add-styles-button"
      nz-button
      nzType="text"
      (click)="launchCustomContentStyleItemDialog('buttons')"
    >
      <i nz-icon nzType="plus"></i>
      Add new
    </button> -->
  </div>

  <nz-divider></nz-divider>

  <h3>Settings</h3>

  <p>Other settings for your theme.</p>

  <nz-collapse nzAccordion>
    <nz-collapse-panel
      class="text-section"
      formGroupName="metadata"
      nzHeader="Metadata"
    >
      <nz-form-item>
        <nz-form-label nzTooltipTitle="Your custom browser icon"
          >Favicon</nz-form-label
        >
        <nz-form-control
          [nzExtra]="!canAdjustFavicon ? notOnBrandTemplate : null"
        >
          <nz-upload
            [nzBeforeUpload]="uploadFavicon"
            [nzDisabled]="!canAdjustFavicon"
            [nzShowUploadList]="false"
            nzAccept=".ico,.png"
            nzListType="picture-card"
            nzName="favicon"
          >
            <ng-container *ngIf="!faviconUrl || isUploadingFavicon">
              <i nz-icon [nzType]="isUploadingFavicon ? 'loading' : 'plus'"></i>
              {{ isUploadingFavicon ? 'Uploading...' : 'Upload' }}
            </ng-container>
            <lib-soft-image-view
              [imageUrl]="faviconUrl"
              *ngIf="faviconUrl && !isUploadingFavicon"
            >
            </lib-soft-image-view>
          </nz-upload>

          <ng-template #notOnBrandTemplate>
            Upgrade to the Brand plan to customize your favicon.
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="empty">Empty text</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            nzSize="large"
            formControlName="empty"
            placeholder="Nothing here."
          />
        </nz-form-control>
      </nz-form-item>
    </nz-collapse-panel>
  </nz-collapse>
</form>

<ng-template #selectedFontTemplate let-selected>
  <div class="custom-option">
    <span
      *ngIf="!selected?.nzValue?.imageUrl"
      [style.fontFamily]="selected?.nzValue?.fontFamily"
      >{{ selected?.nzValue?.displayName || 'Custom' }}</span
    >
    <img
      *ngIf="selected?.nzValue?.imageUrl"
      [src]="selected?.nzValue?.imageUrl"
      [alt]="selected?.nzValue?.displayName"
    />
  </div>
</ng-template>

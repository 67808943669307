import {
  Component,
  OnInit,
  Input,
  Inject,
  EventEmitter,
  Output
} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { IUserContent, IEventDropBase } from '../../types';
import { DeviceService, SystemShareService } from '../../services';
import {
  CUBE_EVENTS,
  EMBEDS_URL_TOKEN,
  EMBED_CLASS,
  EMBEDS
} from '../../constants';

/*
  Creates a share button for a given event or drop
*/

@Component({
  selector: 'lib-event-drop-share-button-view',
  templateUrl: './event-drop-share-button-view.component.html',
  styleUrls: ['./event-drop-share-button-view.component.less']
})
export class EventDropShareButtonViewComponent implements OnInit {
  @Output() menuStateChanged = new EventEmitter<boolean>();
  @Input() content: IEventDropBase;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() showText = false;
  @Input() showIcon = true;
  @Input() size: 'default' | 'large' | 'small' = 'default';
  @Input() type: 'primary' | 'dashed' | 'link' | 'text' = 'text';

  // Whether the current device supports system share menu
  canUseSystemShare = false;

  // Analytics events
  userTappedShare = CUBE_EVENTS.userTappedShare;

  // Event embed constants
  eventType = EMBEDS.event.type;
  eventCardVariant = EMBEDS.event.variants.card;
  eventMainVariant = EMBEDS.event.variants.main;
  eventShareVariant = EMBEDS.event.variants.share;

  // Drop embed constants
  dropType = EMBEDS.drop.type;
  dropCardVariant = EMBEDS.drop.variants.card;
  dropMainVariant = EMBEDS.drop.variants.main;
  dropShareVariant = EMBEDS.drop.variants.share;
  dropScrollVariant = EMBEDS.drop.variants.scroll;

  constructor(
    @Inject(EMBEDS_URL_TOKEN) private _embedsUrl,
    private _systemShare: SystemShareService,
    private _device: DeviceService,
    private _message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.canUseSystemShare = this._systemShare.canShare;
  }

  doShare() {
    this._systemShare.openContentShareSheet(this.content, this.userContent);
  }

  doCopy() {
    this._message.success('Copied');
  }

  handleVisibleChanged(event) {
    this.menuStateChanged.emit(event);
  }

  embedStringForType(variant: string) {
    const slug = this._device.currentSlug;
    const embedType = this.content?.isEvent ? this.eventType : this.dropType;
    const contentId = this.content?.contentId;
    const urls = this.content?.urls;
    const summary = this.content?.summary;
    const title = this.content?.title;
    const buttonLabels = this.content?.buttonLabels;
    const inner = (
      variant === 'register'
        ? `
        <a href="${urls?.pageRaw}" target="_blank" rel="noreferrer">${buttonLabels?.pre} &rarr;</a>
      `
        : variant === 'share'
        ? `
        <a href="${urls?.pageRaw}" target="_blank" rel="noreferrer">Share &rarr;</a>
      `
        : variant === 'card'
        ? `
        <a href="${urls?.pageRaw}" target="_blank" rel="noreferrer"><strong>${title}</strong><br />${summary}</a>
      `
        : ''
    ).trim();

    return `
    <script src="${this._embedsUrl}" defer></script>
<div class="${EMBED_CLASS}" data-type="${embedType}" data-id="${contentId}" data-variant="${variant}" data-slug="${slug}">${inner}</div>
    `.trim();
  }
}

// 3rd party
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// Helper method to pull the date from one Date object and the
// time from a formatted string into one unified ISO string
export const isoDateFromDateAndString = (date: Date, timeStr: string) => {
  date = date ?? new Date();
  date.setHours(0, 0, 0, 0);
  timeStr = timeStr ?? '';
  const dateStr = date.toISOString().split('T')[0];
  const ret = dayjs(`${dateStr} ${timeStr}`, 'YYYY-MM-DD h:mm a');
  return ret.toISOString();
};

// Handy utility to adjust UTC dates to the current timezone
export const adjustUTCDateToCurrentTimezone = (value) => {
  const now = dayjs();
  const then = dayjs(value || undefined);
  return then.add(now.utcOffset(), 'minutes').toDate();
};

export const objectIdFromDate = (date) => {
  return Math.floor(date.getTime() / 1000).toString(16) + '0000000000000000';
};

export const dateFromObjectId = (objectId) => {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

import { LoginComponent } from './login.component';
import { PoweredByViewModule } from '../../modules/powered-by-view.module';
import { PaymentFormModule } from '../../modules/payment-form.module';

@NgModule({
    declarations: [
        LoginComponent,
    ],
    imports: [
        CommonModule,
        ClipboardModule,
        ReactiveFormsModule,
        PaymentFormModule,
        PoweredByViewModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzStepsModule,
        NzSelectModule,
        NzSpinModule,
        NzDividerModule,
        NzToolTipModule,
        NzIconModule,
        NzMenuModule,
        NzSkeletonModule,
        NzListModule,
        NzCheckboxModule
    ],
    exports: [
        LoginComponent
    ]
})
export class LoginModule { }

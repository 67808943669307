<div class="vimeo-embed-wrapper" *ngIf="safeEmbedCode">
    <div [innerHtml]="safeEmbedCode"></div>
</div>

<nz-card class="vimeo-embed-placeholder card-background-color" *ngIf="!safeEmbedCode">
    <nz-card-meta nzDescription="Publish to view the rendered Vimeo video." [nzAvatar]="avatarTemplate">
    </nz-card-meta>
</nz-card>

<ng-template #avatarTemplate>
    <nz-avatar nzIcon="video-camera"></nz-avatar>
</ng-template>
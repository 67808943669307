<!-- Show share button if the link is published -->
<button aria-label="Send" *ngIf="newsletter?.published && textLabel" nz-button [nzBlock]="block" [nzSize]="size"
    class="share-button secondary" nz-dropdown [nzDropdownMenu]="shareMenu" [nzType]="type"
    (nzVisibleChange)="handleMenuStateChanged($event)">
    <i nz-icon nzType="share-alt"></i> {{ textLabel }}
</button>

<button aria-label="Send" *ngIf="newsletter?.published && !textLabel" nz-button [nzBlock]="block" [nzSize]="size"
    class="share-button secondary" nz-dropdown [nzDropdownMenu]="shareMenu" [nzType]="type"
    (nzVisibleChange)="handleMenuStateChanged($event)">
    <i nz-icon nzType="share-alt"></i>
</button>

<!-- SHARE MENU -->
<nz-dropdown-menu #shareMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item *ngIf="isLoading" nzDisabled>
            <i nz-icon nzType="loading"></i>
            Loading...
        </li>
        <li nz-menu-item *ngIf="!isLoading && canUseSystemShare" [nzDisabled]="isDisabled" (click)="doShare()"
            [logEvent]="userTappedShare" [cubeData]="{ entityType: 'newsletter', entityId: newsletter?.contentId }"
            [destination]="'cube'">
            <i nz-icon nzType="share-alt"></i>
            Share...
        </li>
        <li nz-menu-item (click)="doCopy()" [nzDisabled]="isDisabled" *ngIf="!isLoading"
            [cdkCopyToClipboard]="userContent?.shortLinks?.referralPageShortLink || newsletter?.shareUrl">
            <i nz-icon nzType="link"></i>
            Copy link
        </li>
    </ul>
</nz-dropdown-menu>

<div class="embed-wrapper" *ngIf="!showPlaceholder">
    <div [innerHtml]="safeEmbedCode"></div>
</div>

<nz-card *ngIf="showPlaceholder">
    <nz-result nzStatus="success" [nzIcon]="placeholderIcon ? iconTpl : null" [nzTitle]="titleTpl">
        <ng-template #iconTpl>
            <i nz-icon [nzType]="placeholderIcon" class="item"></i>
        </ng-template>
        <ng-template #titleTpl>
            <h2 class="embed-view-placeholder">{{ placeholderText }}</h2>
        </ng-template>
    </nz-result>
</nz-card>
import { Transform, Type } from 'class-transformer';
import { transformTimestampToDate } from '../tools';
import { ProjectTier } from './general';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

export class IStripeSlugMetadata {
  stripeSellerId?: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  stripePaymentMethodIds?: string[];
  stripeDefaultPaymentMethodId?: string;
}

export class IZoomSlugMetadata {
  id!: string;
  accessToken!: string;
  refreshToken!: string;
  name?: string;
}

export class IFacebookSlugMetadata {
  accessToken?: string;
  longLiveAccessToken?: string;
  instagramBusinessAccountIds?: string[];
  pageAccessTokens?: string[];
  pageIds?: string[];
  profile?: {
    displayName?: string;
  };
}

export class IBillingInfoAutomaticSurcharges {
  sms?: boolean;
  email?: boolean;
}

export class IBillingInfoUsage {
  sms?: number;
  mms?: number;
  email?: number;
}

export class IBillingInfo {
  projectTier?: ProjectTier;
  automaticSurcharges?: IBillingInfoAutomaticSurcharges;
  usage?: IBillingInfoUsage;
  subscriptionDiscount?: {
    id: string;
    object: string;
    promotion_code: string;
    start: number;
    subscription: string;
    coupon?: {
      duration: string;
      name: string;
      percent_off: number;
      valid: boolean;
    };
  };

  @Transform(transformTimestampToDate, { toClassOnly: true })
  trialStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  trialEndDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  projectStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  billingPeriodStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  billingPeriodEndDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  subscriptionCancelAtDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  subscriptionCanceledAtDate?: Date;

  get billingStartStr(): string {
    if (!this.billingPeriodStartDate) return null;

    const start = dayjs(this.billingPeriodStartDate);
    return start.format('MM-DD-YYYY');
  }

  get billingEndStr(): string {
    if (!this.billingPeriodEndDate) return null;

    const end = dayjs(this.billingPeriodEndDate);
    return end.format('MM-DD-YYYY');
  }

  get billingRangeStr(): string {
    if (!this.billingPeriodStartDate || !this.billingPeriodEndDate) return null;

    const start = dayjs(this.billingPeriodStartDate);
    const end = dayjs(this.billingPeriodEndDate);
    return `${start?.format('LL') ?? '--'} to ${end?.format('LL') ?? '--'}`;
  }
}

export class ISlugMailingMetadata {
  replyTo?: string;
}

class ResourceInfo {
  total?: number;
}

class ISlugResourceInfo {
  @Type(() => ResourceInfo)
  contacts?: ResourceInfo;
}

class ISlugMetadataFeaturePermission {
  permittedAt?: Date | null;
  permitted!: boolean;
}

class ISlugMetadataFeaturePermissions {
  inbox?: ISlugMetadataFeaturePermission;
}

export class ISlugMetadata {
  slug!: string;
  apiKey?: string;
  zoom?: IZoomSlugMetadata;
  stripe?: IStripeSlugMetadata;
  facebook?: IFacebookSlugMetadata;
  mailing?: ISlugMailingMetadata;
  interestedInOmnichannelInbox?: boolean;
  sms?: {
    phoneNumbers?: string[];
    primaryPhoneNumber?: string;
    alphaSender?: string;
  };

  general?: {
    disableNorbyBranding?: boolean;
  };

  featurePermissions?: ISlugMetadataFeaturePermissions;

  @Type(() => ISlugResourceInfo)
  resourceInfo?: ISlugResourceInfo;

  slugRequestId?: string;

  @Type(() => IBillingInfo)
  billingInfo?: IBillingInfo;

  get trialIsActive(): boolean {
    const now = dayjs();
    const trialEnd = dayjs(this.billingInfo?.trialEndDate);
    return trialEnd.isAfter(now);
  }

  get daysLeftInTrial(): number {
    const now = dayjs();
    const trialEnd = dayjs(this.billingInfo?.trialEndDate);
    return trialEnd.isAfter(now) ? trialEnd.diff(now, 'days') : 0;
  }

  get hasPaymentMethod(): boolean {
    return !!this?.stripe?.stripeDefaultPaymentMethodId;
  }

  get planWasCanceled(): boolean {
    const now = dayjs();
    const canceledAt = dayjs(this.billingInfo?.subscriptionCanceledAtDate);
    return canceledAt?.isBefore(now);
  }

  get planWillBeCanceled(): boolean {
    const now = dayjs();
    const cancelAt = dayjs(this.billingInfo?.subscriptionCancelAtDate);
    return cancelAt?.isAfter(now);
  }

  get trialPercent(): number {
    const now = dayjs();
    const trialStart = dayjs(this?.billingInfo?.trialStartDate);
    const trialEnd = dayjs(this?.billingInfo?.trialEndDate);
    return (
      100 -
      (100 * (now.unix() - trialStart.unix())) /
        (trialEnd.unix() - trialStart.unix())
    );
  }
}

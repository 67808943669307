<nz-card (click)='handleClick()' [class.hoverable]='newsletter'
         [nzCover]='!newsletter || newsletter?.image?.url ? coverTemplate : null'
         [style]='newsletter?.cardCSS'>
  <nz-skeleton *ngIf="!newsletter" [nzLoading]="isLoadingNewsletter" [nzTitle]="false" [nzAvatar]="false"
    [nzParagraph]="{ rows: 2, width: 150 }">
  </nz-skeleton>
  <div class="meta" *ngIf="newsletter">
    <h3 [style]='newsletter?.cardTextCSS' class='title card-text-color'>{{ newsletter?.title }}</h3>
    <h4 *ngIf='newsletter?.subtitle' [style]='newsletter?.cardTextCSS' class='subtitle card-text-color'>
      {{ newsletter?.subtitle }}
    </h4>
    <div *ngIf="priceStr" [style]='newsletter?.cardTextCSS'>
      <nz-alert nzType="info" [nzMessage]="priceStr"> </nz-alert>
    </div>
  </div>

  <div class="actions-container" (click)="$event.stopPropagation()">
    <lib-newsletter-share-button-view *ngIf="newsletter?.published" [newsletter]="newsletter"
      [userContent]="userContent" [isDisabled]="isDisabled" (menuStateChanged)="handleMenuStateChanged()">
    </lib-newsletter-share-button-view>
    <nz-skeleton *ngIf="!newsletter?.published" [nzParagraph]="false"></nz-skeleton>
  </div>
</nz-card>

<ng-template #coverTemplate>
  <lib-soft-image-view [imageUrl]="newsletter?.image?.url" [image]="newsletter?.image"
    [imgixImage]="newsletter?.imgixImage" [alt]="newsletter?.title">
  </lib-soft-image-view>
</ng-template>

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

// 3rd party
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

// App
import { FULL_SCREEN_MODAL_SETTINGS } from '../../types';
import { GlobalLoadingIndicatorViewComponent } from '../../views';

/*
  Most of the time chunk loading is fast enough that
  showing an indicator only increases perceived latency,
  but if chunk loading is slow due to network delays, etc,
  a blocking modal loading indicator should be shown after
  this number of milliseconds
*/
const CHUNK_LOADING_INDICATOR_DELAY = 400;

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private _modalRef: NzModalRef;
  private _loadingTimerSubscription: Subscription;
  globalIsLoading = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _modal: NzModalService
  ) {}

  setLoading(loading = false) {
    if (!isPlatformBrowser(this._platform)) return;

    this.globalIsLoading.next(loading);
    if (loading && !this._loadingTimerSubscription) {
      this._loadingTimerSubscription = timer(CHUNK_LOADING_INDICATOR_DELAY)
        .pipe(take(1))
        .subscribe(
          (_) =>
            (this._modalRef = this._modal.create({
              ...FULL_SCREEN_MODAL_SETTINGS,
              nzMaskClosable: false,
              nzContent: GlobalLoadingIndicatorViewComponent
            }))
        );
    } else if (!loading) {
      this._loadingTimerSubscription?.unsubscribe();
      this._modalRef?.close();
      this._loadingTimerSubscription = null;
      this._modalRef = null;
    }
  }
}

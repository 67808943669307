import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { DirectivesModule } from './directives.module';
import { NewsletterShareButtonViewComponent } from '../views/newsletter-share-button-view/newsletter-share-button-view.component';

@NgModule({
  declarations: [
    NewsletterShareButtonViewComponent
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    DirectivesModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule
  ],
  exports: [
    NewsletterShareButtonViewComponent
  ]
})
export class NewsletterShareButtonViewModule { }

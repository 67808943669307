import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollectionViewComponent } from '../views';
import { LinkCardViewModule } from './link-card-view.module';
import { EventDropCardModule } from './event-drop-card.module';
import { ImageBlockViewModule } from './image-block-view.module';
import { SpacerViewModule } from './spacer-view.module';
import { UpcomingEventsModule } from './upcoming-events.module';
import { EmbedViewModule } from './embed-view.module';
import { YoutubeEmbedViewModule } from './youtube-embed-view.module';
import { VimeoEmbedViewModule } from './vimeo-embed-view.module';
import { NewsletterListingModule } from './newsletter-listing.module';
import { NewsletterSignupViewModule } from './newsletter-signup-view.module';
import { HeaderViewModule } from './header-view.module';
import { ProfileViewModule } from './profile-view.module';
import { SocialIconsViewModule } from './social-icons-view.module';
import { AbstractEventViewModule } from './abstract-event-view.module';
import { AbstractSignupViewModule } from './abstract-signup-view.module';

@NgModule({
  declarations: [
    CollectionViewComponent
  ],
  imports: [
    CommonModule,
    EventDropCardModule,
    UpcomingEventsModule,
    EmbedViewModule,
    YoutubeEmbedViewModule,
    VimeoEmbedViewModule,
    LinkCardViewModule,
    ImageBlockViewModule,
    NewsletterListingModule,
    NewsletterSignupViewModule,
    HeaderViewModule,
    SpacerViewModule,
    ProfileViewModule,
    SocialIconsViewModule,
    AbstractEventViewModule,
    AbstractSignupViewModule
  ],
  exports: [
    CollectionViewComponent
  ]
})
export class CollectionViewModule { }

<div class="wrapper">
  <div class="login-container">
    <div class="loading" @onboardingAnimation *ngIf="!currentStage">
      <i nz-icon nzType="loading"></i>
    </div>

    <div
      class="steps-content"
      @onboardingAnimation
      *ngIf="currentStage === 'phone'"
    >
      <form
        nz-form
        [formGroup]="phoneFormGroup"
        nzLayout="vertical"
        (ngSubmit)="submitPhone()"
      >
        <h2 class="stagger">What's your number?</h2>

        <nz-form-item class="stagger">
          <nz-input-group nzSize="large" nzCompact>
            <nz-select
              nzSize="large"
              style="width: 30%"
              (nzOpenChange)="!$event ? numberInput.focus() : null"
              (ngModelChange)="handleCountryCodeChanged($event)"
              formControlName="countryCode"
            >
              <nz-option
                [nzLabel]="country.value?.flag + ' +' + country.value?.value"
                nzCustomContent
                *ngFor="
                  let country of validCountryCodes | keyvalue: preserveOrder
                "
                [nzValue]="country.key"
              >
                {{ country.value?.flag + ' ' + country.value?.label }}
              </nz-option>
            </nz-select>
            <input
              style="width: 70%"
              type="tel"
              nz-input
              #numberInput
              [placeholder]="phonePlaceholder"
              formControlName="phoneNumber"
              required
            />
          </nz-input-group>
        </nz-form-item>

        <button
          nz-button
          class="action-button stagger"
          nzBlock
          nzSize="large"
          nzType="primary"
          [disabled]="!phoneFormGroup.valid"
          [nzLoading]="isLoading"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>

    <!-- STEP 1B: ENTER CONFIRMATION CODE -->
    <div
      class="steps-content"
      @onboardingAnimation
      *ngIf="currentStage === 'code'"
    >
      <form
        nz-form
        [formGroup]="codeFormGroup"
        nzLayout="vertical"
        (ngSubmit)="submitCode()"
      >
        <h2 class="stagger">Enter your verification code</h2>
        <p class="stagger">Please enter the code we just sent you.</p>

        <nz-form-item class="stagger">
          <nz-input-group [nzSuffix]="resendButton" nzSize="large">
            <input
              nz-input
              nzSize="large"
              type="number"
              name="verificationCode"
              formControlName="verificationCode"
              placeholder="Your verification code"
              required
              minLength="6"
              maxLength="6"
            />
          </nz-input-group>

          <ng-template #resendButton>
            <button
              nz-button
              type="button"
              class="stagger"
              (click)="resendPhoneCode()"
              nzSize="small"
              nzType="text"
              [nzLoading]="isResendingCode"
            >
              <i nz-icon nzType="undo"></i> Resend
            </button>
          </ng-template>
        </nz-form-item>

        <button
          nz-button
          class="action-button stagger"
          nzBlock
          nzSize="large"
          [nzLoading]="isLoading"
          nzType="primary"
          [disabled]="!codeFormGroup.valid"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>

    <!-- STEP 2: Pick a subsite -->
    <div
      class="steps-content"
      @onboardingAnimation
      *ngIf="userRoles?.length > 0 && currentStage === 'subsite_selection'"
    >
      <h2 class="stagger">Choose your Norby</h2>

      <div
        class="account-button list-item-hoverable stagger small-margin-top"
        *ngFor="let slug of slugs"
        (click)="directToSlugAdmin(slug.slug)"
      >
        <lib-content-row-view
          [title]="slug?.title"
          [imageUrl]="slug?.avatarUrl"
        ></lib-content-row-view>
      </div>
    </div>

    <div
      class="steps-content"
      @onboardingAnimation
      *ngIf="userRoles?.length == 0 && currentStage === 'subsite_selection'"
    >
      <h2 class="stagger">We couldn't find your Norby account</h2>
      <p class="stagger">
        Did you mean to
        <a href="https://join.nor.by/" target="_blank" rel="noreferrer"
          >request an invite</a
        >?
      </p>
    </div>
  </div>
</div>

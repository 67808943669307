import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { IEvent, IUserContent } from '../../types';

// 3rd party
import dayjs from 'dayjs';

@Component({
  selector: 'lib-event-card-view',
  templateUrl: './event-card-view.component.html',
  styleUrls: ['./event-card-view.component.less']
})
export class EventCardViewComponent implements OnChanges {
  @Output() cardClick = new EventEmitter<IEvent>();
  @Input() event: IEvent;
  @Input() userEvent: IUserContent;
  @Input() isLoadingEvent = false;
  @Input() isLoadingUserContent = false;
  @Input() isDisabled = false;
  registrationCloseDate: string;

  ngOnChanges(): void {
    this.registrationCloseDate = this.event?.registrationCloseDate
      ? dayjs(this.event.registrationCloseDate).format('MMM DD, YYYY')
      : null;
  }

  handleClick(): void {
    if (!this.isDisabled) this.cardClick.next(this.event);
  }

  get priceStr(): string {
    if (this.event?.tickets?.length > 0) {
      const price = this.event.tickets[0].price / 100;
      return `From $${price}`;
    }

    return null;
  }
}

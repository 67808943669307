import { Component, Input, TemplateRef } from '@angular/core';

// App
import { ContentType } from '../../types';

@Component({
  selector: 'lib-content-row-view',
  templateUrl: './content-row-view.component.html',
  styleUrls: ['./content-row-view.component.less']
})
export class ContentRowViewComponent {
  @Input() avatarPosition: 'left' | 'right' = 'left';
  @Input() avatarColor: string;
  @Input() avatarSize: 'default' | 'small' | 'large' = 'large';
  @Input() avatarIcon: string;
  @Input() avatarIconColor: string;
  @Input() avatarShape: 'square' | 'circle' = 'square';
  @Input() title: string | TemplateRef<any>;
  @Input() subtitle: string | TemplateRef<any>;
  @Input() header: string;
  @Input() imageUrl: string;
  @Input() isLoading: boolean;
  @Input() contentType: ContentType;
  @Input() disabled = false;

  get avatarIsLeft(): boolean {
    return (
      (this.imageUrl || this.isLoading || this.avatarIcon) &&
      this.avatarPosition === 'left'
    );
  }

  get avatarIsRight(): boolean {
    return (
      (this.imageUrl || this.isLoading || this.avatarIcon) &&
      this.avatarPosition === 'right'
    );
  }

  get isStringTitle(): boolean {
    return typeof this.title === 'string';
  }

  get isStringSubtitle(): boolean {
    return typeof this.subtitle === 'string';
  }
}

<div
  [class.creator]="block?.style === 'creator'"
  [class.dangling-avatar]="dangleAvatar"
  [style.background]="backgroundColor"
  [style.height]="height"
  class="page-header"
>
  <img
    *ngIf="bgImage?.src || bgImage?.srcset?.length"
    class="background-image"
    [src]="bgImage?.src"
    [srcset]="bgImage?.srcset"
    alt="Background"
  />

  <div
    *ngIf="avatarImage?.src || avatarImage?.srcset?.length"
    [class.has-mask]="block?.maskUrl"
    class="avatar-frame"
  >
    <a
      [href]="href"
      [attr.intercept]="isRelative || null"
      *ngIf="avatarImage?.srcset"
    >
      <img
        [src]="avatarImage?.src"
        [srcset]="avatarImage?.srcset"
        [style]="imageInlineStyle"
        alt="Avatar"
        class="avatar-image"
      />
    </a>

    <a
      [href]="href"
      *ngIf="!avatarImage?.srcset"
      [attr.intercept]="isRelative || null"
    >
      <img
        class="avatar-image"
        [src]="avatarImage?.src"
        [style]="imageInlineStyle"
        alt="Avatar"
      />
    </a>
  </div>
</div>

import { Component, Input, OnChanges, OnInit } from '@angular/core';

// 3rd party
import { NzMessageService } from 'ng-zorro-antd/message';

// App
import { CUBE_EVENTS } from '../../constants';
import { SystemShareService } from '../../services/system-share';
import {
  IUserContent,
  IEventDropBase,
  IEvent,
  IDrop,
  INewsletter,
  isEvent
} from '../../types';

@Component({
  selector: 'lib-rsvp-confirmation-view',
  templateUrl: './rsvp-confirmation-view.component.html',
  styleUrls: ['./rsvp-confirmation-view.component.less']
})
export class RsvpConfirmationViewComponent implements OnInit, OnChanges {
  @Input() content: IEventDropBase;
  @Input() userContent: IUserContent;
  @Input() isLoadingUserContent = false;

  message: string;
  title: string;
  icon: string;
  canUseSystemShare = false;
  showCalendarOptions = false;

  // Analytics events
  saveToGcal = CUBE_EVENTS.userSavedToGcal;
  saveToIcal = CUBE_EVENTS.userSavedToIcal;
  support = CUBE_EVENTS.userTappedDonate;
  share = CUBE_EVENTS.userTappedShare;

  constructor(
    private _systemShareService: SystemShareService,
    private _message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.canUseSystemShare = this._systemShareService.canShare;
    this._initDisplay();
  }

  ngOnChanges(): void {
    this._initDisplay();
  }

  private _initDisplay() {
    this.title = "You're confirmed!";
    this.message = null;
    this.icon = 'check-circle';
    this.showCalendarOptions = false;

    if (this.content instanceof IEvent) {
      this.showCalendarOptions = true;

      if (this.content?.isHappening) {
        this.title = 'Happening now';
        this.icon = 'alert';
      } else if (this.content?.isOver) {
        this.title = 'Thanks';
        this.message = `This event has ended.`;
      } else {
        this.message = 'Add it to your calendar or share the link.';
      }
    } else if (this.content instanceof IDrop) {
      if (this.content?.triggerProcessed) {
        this.title = 'Thanks';
        this.message = 'This signup has closed.';
      } else {
        this.message = "You're subscribed to updates.";
      }
    } else if (this.content instanceof INewsletter) {
      this.message = "You're subscribed to this newsletter.";
    }
  }

  doCopy() {
    this._message.success('Copied');
  }

  doShare() {
    this._systemShareService.openContentShareSheet(
      this.content,
      this.userContent
    );
  }

  setGCal(): void {
    if (isEvent(this.content))
      this._systemShareService.saveToGoogleCalendar(this.content);
  }

  setICal(): void {
    if (isEvent(this.content))
      this._systemShareService.saveToICal(this.content);
  }
}

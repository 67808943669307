import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsTrackingService } from '../../services/analytics-tracking/analytics-tracking.service';
import { ENDPOINTS } from '../../constants';
import { ApiService } from '../../services/api/api.service';

/*
  Easy event tracking in template files. Send events to
  Segment, Cube, or both.

  Usage:

  .ts
  import { ANALYTICS_EVENTS } from 'src/app/constants';
  ...
  eventName = ANALYTICS_EVENTS.eventName;

  .html
  <a [logEvent]="eventName"
  [segmentData]="{ id: event.eventId }"
  [cubeData]="{ entityType: 'event', entityId: event.eventId }"
  [destination]="'all'">JOIN</a>
*/

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[logEvent]'
})
export class LogEventDirective {
  constructor(
    private _analyticsTracking: AnalyticsTrackingService,
    private _api: ApiService
  ) {}
  @Input('logEvent') eventName: string;
  @Input() segmentData: any;
  @Input() cubeData: { entityType: string; entityId: string };
  @Input() destination: 'cube' | 'segment' | 'all';

  @HostListener('click') onClick() {
    if (this.destination === 'segment' || this.destination === 'all') {
      this._analyticsTracking.track(this.eventName, this.segmentData);
    }

    if (this.destination === 'cube' || this.destination === 'all') {
      this._api
        .post(ENDPOINTS.analytics.trackEntityInteraction, {
          entityType: this.cubeData?.entityType,
          entityId: this.cubeData?.entityId,
          interactionType: this.eventName,
          domReferrer: document?.referrer
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
}

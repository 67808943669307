import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { BaseComponent } from '../../models';
import { ContentInteractionsService, UserService } from '../../services';
import { IEventDropBase, IUserContent } from '../../types';

@Component({
  selector: 'lib-event-drop-card',
  templateUrl: './event-drop-card.component.html',
  styleUrls: ['./event-drop-card.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDropCardComponent extends BaseComponent implements OnChanges {
  @Output() cardClick = new EventEmitter<IEventDropBase>();
  @Input() content: IEventDropBase;
  userContent: IUserContent;
  hasLoaded = false;
  isFetching = false;

  constructor(
    private _user: UserService,
    private _interactions: ContentInteractionsService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges() {
    super.ngOnChanges();

    if (this.content?.contentId)
      this._user
        .getUserContent$(this.content.contentId)
        .pipe(this.takeUntilChanges)
        .subscribe((e) => {
          this.userContent = e;
          this.hasLoaded = true;
          this._cdr.detectChanges();
        });
  }

  handleClick(): void {
    this.cardClick.next(this.content);
  }

  // Triggered when the user hovers over the send button
  // on newsletters
  // TODO: add for events and drops too?
  handleMenuStateChanged() {
    if (this.userContent || this.isFetching) return;

    this.isFetching = true;
    this._cdr.detectChanges();

    this._interactions
      .getUserContentForContent(this.content?.contentId)
      .then((userContent) => {
        this.isFetching = false;
        this.userContent = userContent;
        this._cdr.detectChanges();
      })
      .catch((e) => {
        this.isFetching = false;
        this.userContent = null;
        this._cdr.detectChanges();
      });
  }
}

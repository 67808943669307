import { ValidationErrors, FormControl } from '@angular/forms';
import { VALID_COUNTRY_CODES, ALPHA_SENDER_REGEX } from '../constants';

export const isPhoneNumberValid = (
  phoneNumber: string,
  countryCode: string
): ValidationErrors | null => {
  const adjustedNumber = phoneNumber.replace(/[^0-9]+/g, '');
  const regex = VALID_COUNTRY_CODES[countryCode]?.regex;
  return !!adjustedNumber?.match(regex) ? null : { invalidNumber: true };
};

export const isAlphaSenderValid = (
  alphaSender: string
): ValidationErrors | null => {
  if (!alphaSender) return null;
  return !!alphaSender?.match(ALPHA_SENDER_REGEX)
    ? null
    : { invalidAlphaSender: true };
};

// form validator to make sure empty string or string of spaces/tabs was not entered
export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

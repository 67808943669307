// App
import { SOCIAL_LINKS } from '../constants';
import { ISlug } from '../types';

export const isBrowser = typeof window !== 'undefined';

type cleaner = <t>(obj: t) => t;
export const cleanRecursive: cleaner = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') cleanRecursive(obj[key]);
    else if (
      !obj[key] &&
      typeof obj[key] !== 'boolean' &&
      typeof obj[key] !== 'number'
    )
      delete obj[key];
    // This means its a boolean or number so we do nothing
    else if (!obj[key]) cleanRecursive(obj[key]);
    else if (typeof obj[key] === 'object' && obj[key].length === 0)
      delete obj[key];
  });
  return obj;
};

export function stripHtml(input: string) {
  return input?.length > 0 ? input.replace(/<.*?>/g, '') : input;
}

export const buildSocialLinksObjectFromKeys = (
  key: string,
  slug: ISlug
): { key: string; icon: string; label: string; url: string } => {
  return {
    key,
    icon: SOCIAL_LINKS[key]?.icon,
    label: SOCIAL_LINKS[key]?.label,
    url: slug.links[key]
  };
};

<nz-spin [nzSpinning]="!currentStage">
  <div class="login-container">
    <div class="stepper-container" *ngIf="!error">
      <div *ngIf="stages?.length > 1" class="stages">
        <nz-steps [nzCurrent]="currentIndex" nzSize="small" nzDirection="horizontal">
          <nz-step *ngFor="let stage of stages"></nz-step>
        </nz-steps>
      </div>

      <!-- STEP 0: SELECT TICKET -->
      <div class="steps-content" *ngIf="currentStage === 'tickets'">
        <form nz-form [formGroup]="ticketFormGroup" nzLayout="vertical" (ngSubmit)="submitTicketSelection()">
          <h2 *ngIf="content?.tickets?.length > 1">Choose a ticket</h2>
          <h2 *ngIf="content?.tickets?.length == 1">Confirm your ticket</h2>

          <nz-form-item>
            <nz-select nzSize="large" formControlName="ticket" (nzOpenChange)="$event ? null : recalculateTotalPrice()">
              <nz-option *ngFor="let ticket of content?.tickets" [nzLabel]="
                  ticket?.statementDescription + ' - $' + ticket?.price / 100
                " [nzValue]="ticket?.label">
              </nz-option>
            </nz-select>
          </nz-form-item>
          <nz-form-item>
            <nz-list nzSize="small" nzHeader="Summary">
              <nz-list-item>
                <span>Subtotal</span>
                {{ subtotal / 100 | currency }}
              </nz-list-item>

              <nz-list-item>
                <span>Stripe Processing Fee
                  <i nz-icon nzType="question-circle" nz-tooltip
                    nzTooltipTitle="Checkout secured with Stripe"></i></span>
                {{ fees / 100 | currency }}
              </nz-list-item>

              <nz-list-item class="checkout-total">
                <span>Total</span>
                {{ total / 100 | currency }}
              </nz-list-item>
            </nz-list>
          </nz-form-item>

          <button nz-button class="action-button" nzBlock nzSize="large" nzType="primary"
            [disabled]="!ticketFormGroup.valid" [nzLoading]="isLoading" type="submit">
            Next
          </button>
        </form>
      </div>

      <!-- OPTIONAL STEP 1A: ENTER PHONE -->
      <div class="steps-content" *ngIf="currentStage === 'phone'">
        <form nz-form [formGroup]="phoneFormGroup" nzLayout="vertical" (ngSubmit)="submitPhone()">
          <h2>Enter your number</h2>
          <p *ngIf="loginCopy">{{ loginCopy }}</p>

          <nz-form-item>
            <nz-input-group nzSize="large" nzCompact>
              <nz-select nzSize="large" style="width: 30%" (nzOpenChange)="!$event ? numberInput.focus() : null"
                (ngModelChange)="handleCountryCodeChanged($event)" formControlName="countryCode">
                <nz-option [nzLabel]="country.value?.flag + ' +' + country.value?.value" nzCustomContent *ngFor="
                    let country of validCountryCodes | keyvalue: preserveOrder
                  " [nzValue]="country.key">
                  {{ country.value?.flag + ' ' + country.value?.label }}
                </nz-option>
              </nz-select>
              <input style="width: 70%" type="tel" nz-input #numberInput [placeholder]="phonePlaceholder"
                formControlName="phoneNumber" required />
            </nz-input-group>
          </nz-form-item>

          <button nz-button class="action-button" nzBlock nzSize="large" nzType="primary"
            [disabled]="!phoneFormGroup.valid" [nzLoading]="isLoading" type="submit">
            Next
          </button>
        </form>
      </div>

      <!-- STEP 1B: ENTER CONFIRMATION CODE -->
      <div class="steps-content" *ngIf="currentStage === 'code'">
        <form nz-form [formGroup]="codeFormGroup" nzLayout="vertical" (ngSubmit)="submitCode()">
          <h2>Enter your verification code</h2>
          <p>Please enter the code we just sent you.</p>

          <nz-form-item>
            <nz-input-group [nzSuffix]="resendButton" nzSize="large">
              <input nz-input nzSize="large" type="number" name="verificationCode" formControlName="verificationCode"
                placeholder="Your verification code" required minLength="6" maxLength="6" />
            </nz-input-group>

            <ng-template #resendButton>
              <button nz-button type="button" class="stagger" (click)="resendPhoneCode()" nzSize="small" nzType="text"
                [nzLoading]="isResendingCode">
                <i nz-icon nzType="undo"></i> Resend
              </button>
            </ng-template>
          </nz-form-item>

          <button nz-button class="action-button" nzBlock nzSize="large" [nzLoading]="isLoading" nzType="primary"
            [disabled]="!codeFormGroup.valid" type="submit">
            {{ isOnFinalScreen ? 'Submit' : 'Next' }}
          </button>
        </form>
      </div>

      <!-- OPTIONAL STEP 2A: ENTER EMAIL -->
      <div class="steps-content" *ngIf="currentStage === 'email'">
        <form nz-form [formGroup]="emailFormGroup" nzLayout="vertical" (ngSubmit)="submitEmail()">
          <h2>Enter your email</h2>
          <p *ngIf="emailLoginCopy">{{ emailLoginCopy }}</p>

          <nz-form-item>
            <nz-input-group nzSize="large" nzCompact>
              <input type="email" nz-input placeholder="bob@example.com" formControlName="email" required />
            </nz-input-group>
          </nz-form-item>

          <button nz-button class="action-button" nzBlock nzSize="large" nzType="primary"
            [disabled]="!emailFormGroup.valid" [nzLoading]="isLoading" type="submit">
            Next
          </button>
        </form>
      </div>

      <!-- SUPER OPTIONAL STEP 2B: ENTER CONFIRMATION CODE -->
      <div class="steps-content" *ngIf="currentStage === 'emailCode'">
        <form nz-form [formGroup]="emailCodeFormGroup" nzLayout="vertical" (ngSubmit)="submitEmailCode()">
          <h2>Enter your verification code</h2>
          <p *ngIf="emailVerificationCodeMedium === 'email'">
            Please enter the code we just sent to your email. If you can't find
            the code, check your spam folder.
          </p>
          <p *ngIf="emailVerificationCodeMedium === 'sms'">
            Please enter the code we just sent you over text.
          </p>

          <nz-form-item>
            <input type="number" class="number-input" nz-input nzSize="large" formControlName="verificationCode"
              placeholder="Your verification code" required minLength="6" maxLength="6" />
          </nz-form-item>

          <button nz-button class="action-button" nzBlock nzSize="large" [nzLoading]="isLoading" nzType="primary"
            [disabled]="!emailCodeFormGroup.valid" type="submit">
            {{ isOnFinalScreen ? 'Submit' : 'Next' }}
          </button>
        </form>
      </div>

      <!-- OPTIONAL STEP 3: ENTER REQUIRED USER INFO -->
      <div class="steps-content" *ngIf="currentStage === 'profile'">
        <form nz-form [formGroup]="profileFormGroup" nzLayout="vertical" (ngSubmit)="submitProfile()">
          <h2>Confirm your details</h2>
          <nz-spin [nzSpinning]="!hasLoadedProfile">
            <p>The following is required:</p>
            <ul>
              <li *ngIf="
                  isEditingName || (needsName && !userProfile?.displayName)
                ">
                Your name
              </li>
              <li *ngIf="!isEditingName && needsName && userProfile?.displayName">
                Your name (<strong>{{ userProfile?.displayName }}</strong>)
                <button nz-button type="button" (click)="isEditingName = true" nzSize="small" nzType="text">
                  Edit
                </button>
              </li>
              <li *ngIf="isEditingEmail || (needsEmail && !userMetadata?.email)">
                Your email
              </li>
              <li *ngIf="!isEditingEmail && needsEmail && userMetadata?.email">
                Your email (<strong>{{ userMetadata?.email }}</strong>)
                <button nz-button *ngIf="!userMetadata?.emailVerified" (click)="isEditingEmail = true" type="button"
                  nzSize="small" nzType="text">
                  Edit
                </button>
              </li>
            </ul>

            <div *ngIf="hasLoadedProfile">
              <nz-form-item *ngIf="
                  needsName && (!userProfile?.displayName || isEditingName)
                ">
                <nz-form-label>Name</nz-form-label>
                <input type="text" class="name-input" nz-input formControlName="displayName"
                  placeholder="Your full name" required />
              </nz-form-item>

              <nz-form-item *ngIf="needsEmail && (!userMetadata?.email || isEditingEmail)">
                <nz-form-label>Email</nz-form-label>
                <input type="text" class="email-input" nz-input formControlName="email" placeholder="Your email"
                  required />
              </nz-form-item>

              <nz-form-item *ngIf="shouldShowNewsletterOptIn">
                <label nz-checkbox formControlName="hasOptedIntoNewsletter">{{ content.buttonLabels.newsletter
                  }}</label>
              </nz-form-item>
            </div>
          </nz-spin>

          <button nz-button class="action-button margin-top" nzBlock nzSize="large" nzType="primary"
            [nzLoading]="isLoading" [disabled]="!profileFormGroup.valid || !hasLoadedProfile" type="submit">
            {{ isOnFinalScreen ? 'Submit' : 'Next' }}
          </button>

        </form>
      </div>

      <!-- OPTIONAL STEP 4: COLLECT PROMPT RESPONSES -->
      <div class="steps-content" *ngIf="currentStage === 'prompts'">
        <form nz-form nzLayout="vertical" [formGroup]="promptsFormGroup" (ngSubmit)="submitPrompts()">
          <h2>Complete your registration</h2>

          <nz-form-item *ngFor="let prompt of content.prompts; let i = index">
            <nz-form-label>
              {{ prompt.prompt }}
            </nz-form-label>
            <nz-form-control>
              <input *ngIf="!prompt?.type || prompt?.type == 'text'" class="prompt-input" nz-input
                placeholder="Your answer..." [formControlName]="i" />
              <nz-select *ngIf="prompt?.type == 'select'" [formControlName]="i">
                <nz-option *ngFor="let option of prompt?.options" [nzLabel]="option" [nzValue]="option">
                </nz-option>
              </nz-select>
              <nz-checkbox-wrapper *ngIf="prompt?.type == 'checkbox'" style="width: 100%"
                (nzOnChange)="handleDidCheckCheckbox($event, i)">
                <div nz-row>
                  <div nz-col nzSpan="8" *ngFor="let option of prompt?.options">
                    <label nz-checkbox [nzValue]="option">{{ option }}</label>
                  </div>
                </div>
              </nz-checkbox-wrapper>
            </nz-form-control>
          </nz-form-item>

          <button nz-button class="action-button" nzBlock nzSize="large" nzType="primary"
            [disabled]="!promptsFormGroup.valid" [nzLoading]="isLoading" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>

    <!-- OPTIONAL STEP 5: ENTER PAYMENT INFO -->
    <div class="steps-content" *ngIf="currentStage === 'purchase'">
      <lib-payment-form *ngIf="!userHasPaid && selectedTicket" [ticket]="selectedTicket" [content]="content"
        [paymentCallback]="paymentCallback()" [name]="userProfile?.displayName" [isFinalScreen]="isOnFinalScreen">
      </lib-payment-form>
      <div *ngIf="userHasPaid">
        <h2>Payment complete</h2>
        <p>You've already paid.</p>
        <button nz-button class="action-button" nzBlock nzSize="large" nzType="primary" [nzLoading]="isLoading"
          (click)="skipPayment()">
          {{ isOnFinalScreen ? 'Submit' : 'Next' }}
        </button>
      </div>
    </div>

    <div class="error" *ngIf="error">
      <p>{{ error }}</p>
      <button nz-button (click)="close()">Okay</button>
    </div>

    <div class="powered-container margin-top">
      <lib-powered-by-view></lib-powered-by-view>
      <div class="links">
        <a [href]="privacyUrl" target="_blank">Privacy</a>
        <a [href]="termsUrl" target="_blank">Terms</a>
      </div>
    </div>
  </div>
</nz-spin>

import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';

// 3rd party
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';

// App
import { BaseComponent } from '../../models';
import { ICollection, ISlug } from '../../types';

export type Platform = 'mobile' | 'desktop';

const FIXED_WIDTH = 375.0;
const FIXED_HEIGHT = 812.0;

@Component({
  selector: 'lib-site-preview-view',
  templateUrl: './site-preview-view.component.html',
  styleUrls: ['./site-preview-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitePreviewViewComponent
  extends BaseComponent
  implements OnChanges, AfterViewInit
{
  @Input() collection: ICollection;
  @Input() platform: Platform = 'mobile';
  @Input() slug: ISlug;
  @Input() showAsAbstract = false;
  @ViewChild('wrapper') wrapper: ElementRef;

  backgroundCss: string;
  containerCss: string;
  height: string;
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this._platform);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this._platform)) {
      fromEvent(window, 'resize')
        .pipe(startWith([null]), this.takeUntilDestroy)
        .subscribe((_) => this._recalculateLayout());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    const imgUrl =
      this.collection?.backgroundImage ??
      this.slug?.theme?.layout?.backgroundImage;
    const bgColor = this.collection?.backgroundColor
      ? this.collection?.backgroundColor
      : !this.collection?.backgroundGradient
      ? this.slug?.theme?.layout?.backgroundColor
      : null;

    const bgGradient = this.collection?.backgroundGradient
      ? this.collection?.backgroundGradient
      : !this.collection?.backgroundColor
      ? this.slug?.theme?.layout?.backgroundGradient
      : null;
    const bgColorStr = !!bgColor
      ? `background-color: ${bgColor}`
      : `background: ${bgGradient}`;
    const oldCss = this.backgroundCss;
    this.backgroundCss = imgUrl
      ? `background-image: url(${imgUrl})`
      : bgColorStr;

    const platformChanged =
      changes?.platform?.previousValue !== changes?.platform?.currentValue;

    if (platformChanged) {
      this._recalculateLayout();
    } else if (this.backgroundCss !== oldCss) {
      this._cdr.detectChanges();
    }
  }

  private _recalculateLayout() {
    if (!this.isBrowser) return;

    const oldContainerCss = this.containerCss;
    const oldHeight = this.height;

    if (this.platform === 'mobile') {
      const width = this.wrapper.nativeElement?.offsetWidth;
      const multiplier = width / FIXED_WIDTH;
      this.containerCss = `transform: scale(${multiplier})`;
      this.height = `${multiplier * FIXED_HEIGHT}px`;
    } else {
      this.containerCss = '';
      this.height = '100%';
    }

    if (oldContainerCss !== this.containerCss || oldHeight !== this.height)
      this._cdr.detectChanges();
  }
}

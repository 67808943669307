import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { buildImageSrcset, getImageSrc } from '../../tools';
import { IImage, IImgixImageMap } from '../../types';

export type Rounded = {
  topLeft: boolean;
  topRight: boolean;
  bottomLeft: boolean;
  bottomRight: boolean;
};

@Component({
  selector: 'lib-soft-image-view',
  templateUrl: './soft-image-view.component.html',
  styleUrls: ['./soft-image-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftImageViewComponent implements OnChanges {
  @Input() imageUrl: string;
  @Input() image: IImage;
  @Input() imgixImage: IImgixImageMap;
  @Input() alt: string;
  @Input() initialHeight = '100px';
  loaded = false;
  srcset: string;
  src: string;
  width: number;
  height: number;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { imageUrl, imgixImage } = changes;
    const { large, medium, small, thumbnail } = imgixImage?.currentValue ?? {};
    const { oldLarge, oldMedium, oldSmall, oldThumbnail } =
      imgixImage?.previousValue ?? {};

    const urlHasChanged = imageUrl?.previousValue !== imageUrl?.currentValue;
    const setHasChanged =
      large !== oldLarge ||
      medium !== oldMedium ||
      small !== oldSmall ||
      thumbnail !== oldThumbnail;

    // Only rerender if the urls have actually changed
    if (urlHasChanged || setHasChanged) {
      // Default image for old browsers or when set isn't available
      this.src = getImageSrc(this.imageUrl, imgixImage?.currentValue);

      // Build srcset from imgix images if supplied
      this.srcset = buildImageSrcset(imgixImage?.currentValue);

      this.width =
        this.image?.width ??
        large?.width ??
        medium?.width ??
        small?.width ??
        thumbnail?.width;

      this.height =
        this.image?.height ??
        large?.height ??
        medium?.height ??
        small?.height ??
        thumbnail?.height;

      this._cdr.detectChanges();
    }
  }
}

import { Component, Input } from '@angular/core';
import { ISpacerBlock } from '../../types';

@Component({
  selector: 'lib-spacer-view',
  templateUrl: './spacer-view.component.html',
  styleUrls: ['./spacer-view.component.less']
})
export class SpacerViewComponent {
  @Input() block: ISpacerBlock;

  get height(): string {
    return `${this.block?.height || 12}px`;
  }
}

import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Firestore, doc, docData, getDoc } from '@angular/fire/firestore';

// 3rd party
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

// App
import { DeviceService } from '../device/device.service';
import { ISlug, ISlugMetadata } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class SlugService {
  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _firestore: Firestore,
    private _device: DeviceService
  ) {}

  getCurrentSlug$(): Observable<ISlug> {
    return this._device.currentSlug$.pipe(
      switchMap((slug) =>
        docData(doc(this._firestore, 'slugs', slug)).pipe(
          map((s) => plainToClass(ISlug, s))
        )
      )
    );
  }

  async getCurrentSlug(): Promise<ISlug> {
    return this.getSlug(this._device.currentSlug);
  }

  async getCurrentSlugMetadata(): Promise<ISlugMetadata> {
    if (isPlatformServer(this._platform)) return null as ISlugMetadata;

    try {
      const slug = this._device.currentSlug;
      const ref = doc(this._firestore, 'slugMetadata', slug);
      const snapshot = await getDoc(ref);
      return snapshot.exists
        ? plainToClass(ISlugMetadata, snapshot.data())
        : null;
    } catch (e) {
      return null;
    }
  }

  async getSlug(slug: string) {
    try {
      const ref = doc(this._firestore, 'slugs', slug);
      const snapshot = await getDoc(ref);
      return snapshot.exists ? plainToClass(ISlug, snapshot.data()) : null;
    } catch (e) {
      return null;
    }
  }
}

<div class="container">
  <div class="event-hero" *ngIf="!event || event?.imageUrl">
    <div
      class="event-hero-backdrop"
      [style.backgroundColor]="event ? paletteColor : ''"
    ></div>
    <div
      class="event-hero-backdrop image"
      *ngIf="
        event?.imageUrl &&
        !(event?.styles?.detail?.headerImageColor?.length > 0)
      "
      [style.background]="'url(' + event.imageUrl + ')'"
    ></div>
  </div>

  <div class="event-container" [class.has-image]="!event || event?.imageUrl">
    <div class="space-wrapper">
      <nz-card [nzBodyStyle]="{ padding: '0px' }" [style]="event?.cardCSS">
        <div class="header">
          <div
            class="image-wrapper"
            *ngIf="!event || event?.imageUrl"
            [style.backgroundColor]="
              event?.image?.palettes?.length > 0 ? event.image?.palettes[0] : ''
            "
          >
            <lib-soft-image-view
              [imageUrl]="event?.imageUrl"
              [image]="event?.image"
              [initialHeight]="'100px'"
              [imgixImage]="event?.imgixImage"
              [alt]="event?.title"
            >
            </lib-soft-image-view>
          </div>

          <div class="meta-wrapper">
            <div *ngIf="event" class="meta card-text-color">
              <div
                *ngIf="
                  event?.isFull ||
                  (event?.hasCapacity &&
                    event?.rsvpRestrictions?.displayLimit) ||
                  event?.isCanceled ||
                  event?.registrationCloseDate
                "
              >
                <nz-tag
                  nzColor="warning"
                  *ngIf="event?.isFull || event?.isPastRegistrationDeadline"
                >
                  <i nz-icon nzType="lock"></i>
                  <span>Registration is closed</span>
                </nz-tag>
                <nz-tag
                  nzColor="warning"
                  *ngIf="
                    event?.registrationCloseDate &&
                    !event?.isPastRegistrationDeadline &&
                    !event?.isFull
                  "
                >
                  <i nz-icon nzType="calendar"></i>
                  <span>Register by {{ registrationCloseDate }}</span>
                </nz-tag>
                <nz-tag
                  *ngIf="
                    event?.hasCapacity && event?.rsvpRestrictions?.displayLimit
                  "
                  nzColor="processing"
                >
                  <i nz-icon nzType="unlock"></i>
                  <span>{{ event?.rsvpRestrictions?.remaining }} left</span>
                </nz-tag>
                <nz-tag *ngIf="event?.isCanceled" nzColor="error">
                  <i nz-icon nzType="close-circle"></i>
                  <span>Canceled</span>
                </nz-tag>
              </div>
              <h4 [style]="event?.cardTextCSS">{{ event.summary }}</h4>
              <h2 class="title" [style]="event?.cardTextCSS">
                {{ event?.title }}
              </h2>
              <p
                *ngIf="event?.subtitle"
                class="subtitle"
                [style]="event?.cardTextCSS"
              >
                {{ event.subtitle }}
              </p>
              <div *ngIf="priceStr">
                <nz-alert nzType="info" [nzMessage]="priceStr"> </nz-alert>
              </div>
            </div>
            <nz-skeleton
              *ngIf="!event"
              [nzTitle]="false"
              [nzAvatar]="false"
              [nzParagraph]="{ rows: 3, width: 150 }"
            >
            </nz-skeleton>

            <div
              class="main-actions-container"
              (click)="$event.stopPropagation()"
            >
              <div class="row" *ngIf="event?.published">
                <div class="wide">
                  <lib-event-main-button-view
                    [event]="event"
                    [userEvent]="userEvent"
                    [isLoading]="isLoading"
                    [forceDisabled]="shouldDisableButtons"
                  >
                  </lib-event-main-button-view>
                </div>
                <lib-event-drop-share-button-view
                  [content]="event"
                  [userContent]="userEvent"
                >
                </lib-event-drop-share-button-view>
              </div>
            </div>
          </div>
        </div>
      </nz-card>

      <nz-card
        *ngIf="userEvent?.rsvpEnabled && event?.published"
        [nzBodyStyle]="{ padding: 0 }"
        [style]="event?.cardCSS"
      >
        <lib-rsvp-confirmation-view
          [content]="event"
          [userContent]="userEvent"
          [isLoadingUserContent]="isLoading"
        >
        </lib-rsvp-confirmation-view>
      </nz-card>

      <nz-card *ngIf="event?.body" [style]="event?.cardCSS">
        <div class="event-body ql-readonly-body card-text-color">
          <div
            *ngIf="event.body"
            [innerHTML]="
              event?.signedBody || event.body | safeHtml: event?.cardTextCSS
            "
          ></div>
        </div>
      </nz-card>
    </div>
  </div>
</div>

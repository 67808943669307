import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { User } from '@angular/fire/auth';

// App
import { DeviceService } from '../device';
import { API_URL_TOKEN } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _currentUser: User;

  constructor(
    @Inject(API_URL_TOKEN) private _apiUrl: string,
    private _http: HttpClient,
    private _device: DeviceService
  ) {}

  // Called by auth service to let us know the current user
  // TODO: Move headers to interceptor that can subscribe
  // to auth state
  setCurrentUser(user: User) {
    this._currentUser = user;
  }

  constructApiUrl(uri: string) {
    const base = this._apiUrl;
    const url = new URL(uri, base);
    return url.href;
  }

  private async _constructHttpOpts(params?: any) {
    return {
      withCredentials: true,
      headers: await this._contructHeaders(),
      ...(params && { params })
    };
  }

  private async _contructHeaders() {
    const accessToken = await this._currentUser?.getIdToken();
    const headers = {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      'x-slug': this._device.currentSlug
    };

    return headers;
  }

  async post<T>(route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this.constructApiUrl(route);
    return firstValueFrom<T>(this._http.post<T>(url, body, opts));
  }

  async put<T>(route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this.constructApiUrl(route);
    return firstValueFrom<T>(this._http.put<T>(url, body, opts));
  }

  async patch<T>(route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this.constructApiUrl(route);
    return firstValueFrom<T>(this._http.patch<T>(url, body, opts));
  }

  async delete<T>(route: string, params?: any) {
    const opts = await this._constructHttpOpts(params);
    const url = this.constructApiUrl(route);
    return firstValueFrom<T>(this._http.delete<T>(url, opts));
  }

  async get<T>(route: string, params?: any) {
    const opts = await this._constructHttpOpts(params);
    const url = this.constructApiUrl(route);
    return firstValueFrom<T>(this._http.get<T>(url, opts));
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// App
import { BaseComponent } from '../../models';
import { IDropShadowValues } from '../../types';

@Component({
  selector: 'lib-drop-shadow-sliders-view',
  templateUrl: './drop-shadow-sliders-view.component.html',
  styleUrls: ['./drop-shadow-sliders-view.component.less']
})
export class DropShadowSlidersViewComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() values: IDropShadowValues;
  @Output() shadowChanged = new EventEmitter<string>();
  formGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      h: [this.values?.h],
      v: [this.values?.v],
      blur: [this.values?.blur],
      color: [this.values?.color]
    });

    this.formGroup.valueChanges
      .pipe(this.takeUntilDestroy)
      .subscribe((shadowValues: IDropShadowValues) => {
        const { h, v, blur, color } = shadowValues;
        const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
          color ?? '#000000'
        }`;
        this.shadowChanged.emit(shadow);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const oldValues = changes?.values?.previousValue as IDropShadowValues;
    const newValues = changes?.values?.currentValue as IDropShadowValues;
    const dirty =
      !oldValues ||
      !newValues ||
      oldValues.color !== newValues.color ||
      oldValues.h !== newValues.h ||
      oldValues.v !== newValues.v ||
      oldValues.blur !== newValues.blur;

    if (dirty) this.formGroup.patchValue(this.values, { emitEvent: false });
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

// 3rd party
import { NzMessageService } from 'ng-zorro-antd/message';

// App
import { INewsletter, IUserContent } from '../../types';
import { BaseComponent } from '../../models';
import { CUBE_EVENTS } from '../../constants';
import { SystemShareService } from '../../services/system-share';

/*
  Creates a share button for a given newsletter
*/

@Component({
  selector: 'lib-newsletter-share-button-view',
  templateUrl: './newsletter-share-button-view.component.html',
  styleUrls: ['./newsletter-share-button-view.component.less']
})
export class NewsletterShareButtonViewComponent
  extends BaseComponent
  implements OnInit
{
  @Output() menuStateChanged = new EventEmitter<boolean>();
  @Input() newsletter: INewsletter;
  @Input() userContent: IUserContent;
  @Input() isDisabled = false;
  @Input() isLoading = false;
  @Input() size: 'default' | 'large' | 'small' = 'large';
  @Input() textLabel = 'Share';
  @Input() type: 'primary' | 'dashed' | 'link' | 'text' = null;
  @Input() block = true;

  // Whether the current device supports system share menu
  canUseSystemShare = false;

  // Analytics events
  userTappedShare = CUBE_EVENTS.userTappedShare;

  constructor(
    private _systemShare: SystemShareService,
    private _message: NzMessageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.canUseSystemShare = this._systemShare.canShare;
  }

  doShare() {
    this._systemShare.openContentShareSheet(this.newsletter, this.userContent);
  }

  doCopy() {
    this._message.success('Copied');
  }

  handleMenuStateChanged(event) {
    this.menuStateChanged.next(event);
  }
}

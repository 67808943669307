<div class="container card-background-color card-text-color" [style]="content?.cardCSS">
  <nz-result class="confirmed-result" nzStatus="success" [nzIcon]="iconTpl" [nzTitle]="titleTpl"
    [nzSubTitle]="messageTpl">
    <ng-template #iconTpl>
      <i nz-icon [style]="content?.cardTextCSS" [nzType]="icon"></i>
    </ng-template>
    <ng-template #titleTpl>
      <h2 class="center" [style]="content?.cardTextCSS">{{ title }}</h2>
    </ng-template>
    <ng-template #messageTpl>
      <h3 class="center" [style]="content?.cardTextCSS">{{ message }}</h3>
    </ng-template>
    <div nz-result-extra *ngIf="content?.urls?.donateRaw">
      <a nz-button nzSize="large" nzBlock class="donate" [logEvent]="support"
        [cubeData]="{ entityType: content?.contentType, entityId: content?.contentId }" [destination]="'cube'"
        [href]="content.urls.donateRaw" target="_blank" rel="noreferrer">
        <i nz-icon nzType="heart"></i> Donate
      </a>
    </div>
  </nz-result>
  <div class="confirmed-row">
    <div class="save" *ngIf="showCalendarOptions">
      <h3 [style]="content?.cardTextCSS">Add to calendar</h3>
      <button nz-button nzSize="large" class='secondary' nzBlock (click)="setGCal()" [logEvent]="saveToGcal"
        [cubeData]="{ entityType: 'event', entityId: content?.contentId }" [destination]="'cube'">
        <i nz-icon nzType="google"></i>
        Google Calendar
      </button>

      <button nz-button nzSize="large" class='secondary' nzBlock (click)="setICal()" [logEvent]="saveToIcal"
        [cubeData]="{ entityType: 'event', entityId: content?.contentId }" [destination]="'cube'">
        <i nz-icon nzType="apple"></i>
        Apple Calendar
      </button>
    </div>
    <div class="share">
      <h3 [style]="content?.cardTextCSS">Share the link</h3>
      <div class="copy-container">
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="urlSuffixButton" [nzPrefix]="urlPrefix">
          <input type="text" nz-input readonly [value]="
        userContent?.shortLinks?.referralPageShortLink ||
        content.urls?.pageShortLink" />
        </nz-input-group>
        <ng-template #urlSuffixButton>
          <button nz-button nzSize="large" nzSearch (click)="doCopy()" *ngIf="!canUseSystemShare" [logEvent]="share"
            [nzLoading]="isLoadingUserContent" [cubeData]="{ entityType: 'event', entityId: content?.contentId }"
            [destination]="'cube'" [cdkCopyToClipboard]="
          userContent?.shortLinks?.referralPageShortLink ||
          content.urls?.pageShortLink" [disabled]="isLoadingUserContent" nzType="primary">
            Copy
          </button>
          <button class="share-button" nzSize="large" nzSearch nz-button *ngIf="canUseSystemShare"
            [disabled]="isLoadingUserContent" (click)="doShare()" [logEvent]="share"
            [cubeData]="{ entityType: 'event', entityId: content?.contentId }" [destination]="'cube'"
            nzType="primary">
            Share...
          </button>
        </ng-template>
        <ng-template #urlPrefix>
          <i nz-icon nzType="link"></i>
        </ng-template>
      </div>
    </div>
  </div>
</div>
export const EMBEDS = {
    event: {
        type: 'event',
        variants: {
            card: 'card',
            main: 'register',
            share: 'share'
        }
    },
    drop: {
        type: 'drop',
        variants: {
            card: 'card',
            main: 'register',
            share: 'share',
            scroll: 'scroll'
        }
    },
    page: {
        type: 'page'
    }
};

// Class name used for embeds
export const EMBED_CLASS = 'norby-embed';

import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-loading-indicator-view',
  templateUrl: './loading-indicator-view.component.html',
  styleUrls: ['./loading-indicator-view.component.less']
})
export class LoadingIndicatorViewComponent {
  @ViewChild('nzIndicatorTpl', { static: true })
  nzIndicator!: TemplateRef<void>;
}

<div class="payment-form-container">
  <nz-spin [nzSpinning]="isLoading">

    <form nz-form nzLayout="vertical" (ngSubmit)="completeCardPayment()" [formGroup]="stripePaymentForm">

      <h2>Enter your billing details</h2>
      <p>You'll see <em>{{ ticket?.statementDescription }}</em> on your statement.</p>

      <nz-form-item>
        <nz-form-label nzFor="name">Name</nz-form-label>
        <input nz-input formControlName="name" placeholder="Jane Doe">
      </nz-form-item>

      <div class="form-row">
        <nz-form-item>
          <nz-form-label nzFor="line1">Address Line 1</nz-form-label>
          <input nz-input formControlName="line1" placeholder="620 Atlantic Avenue">
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="line2">Address Line 2</nz-form-label>
          <input nz-input formControlName="line2" placeholder="Apt 5">
        </nz-form-item>
      </div>

      <div class="form-row">
        <nz-form-item>
          <nz-form-label nzFor="city">City</nz-form-label>
          <input nz-input formControlName="city" placeholder="Brooklyn">
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="state">State</nz-form-label>
          <input nz-input formControlName="state" placeholder="NY">
        </nz-form-item>
      </div>

      <div class="form-row">
        <nz-form-item>
          <nz-form-label nzFor="postalCode">Postal Code</nz-form-label>
          <input nz-input type="text" formControlName="postalCode" placeholder="12345">
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="country">Country</nz-form-label>
          <nz-select formControlName="country">
            <nz-option *ngFor="let country of billingCountries" [nzLabel]="country?.name" [nzValue]="country?.code">
            </nz-option>
          </nz-select>
        </nz-form-item>
      </div>

      <div id="card-element" class="card-input-field"></div>
      <div id="card-errors"></div>

      <button nz-button nzBlock type="submit" class="submit" nzSize="large" (click)="isSubmitting = true"
        nzType="primary" [nzLoading]="isSubmitting"
        [disabled]="!stripePaymentForm.valid || !cardInputComplete || isLoading">Purchase</button>
    </form>

    <div id="payment-request-button">
      <!-- A Stripe Element will be inserted here. -->
    </div>

  </nz-spin>

</div>
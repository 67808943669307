import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger
} from '@angular/animations';

const TRANSITION_DURATION = 0.12;
const TRANSITION_STAGGER = 0.01;
const TRANSITION_CURVE = 'ease-out';
const TRANSITION_TRANSLATE = '32px';
const TRANSITION_SCALE = '0.9';

export const onboardingAnimation = trigger('onboardingAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
      position: 'absolute'
    }),
    query('.stagger', [
      style({
        opacity: 0,
        transform: `translateY(${TRANSITION_TRANSLATE}) scale(${TRANSITION_SCALE})`
      })
    ]),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({
        position: 'relative',
        opacity: 1
      })
    ),
    query('.stagger', [
      stagger(`${TRANSITION_STAGGER}s`, [
        animate(
          `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
          style({ opacity: 1, transform: 'none' })
        )
      ])
    ])
  ]),
  transition(':leave', [
    style({
      transform: 'none',
      position: 'relative',
      opacity: 1
    }),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({
        transform: `scale(${TRANSITION_SCALE})`,
        opacity: 0
      })
    ),
    style({
      position: 'absolute'
    })
  ])
]);

<div class="container">
  <div class="newsletter-hero" *ngIf="!newsletter || newsletter?.imageUrl">
    <div
      class="newsletter-hero-backdrop"
      [style.backgroundColor]="newsletter ? paletteColor : ''"
    ></div>
    <div
      class="newsletter-hero-backdrop image"
      *ngIf="
        newsletter?.imageUrl &&
        !(newsletter?.styles?.detail?.headerImageColor?.length > 0)
      "
      [style.background]="'url(' + newsletter.imageUrl + ')'"
    ></div>
  </div>

  <div
    class="newsletter-container"
    [class.has-image]="!newsletter || newsletter?.imageUrl"
  >
    <div class="space-wrapper">
      <nz-card [nzBodyStyle]="{ padding: '0px' }" [style]="newsletter?.cardCSS">
        <div class="header">
          <div
            class="image-wrapper"
            *ngIf="!newsletter || newsletter?.imageUrl"
            [style.backgroundColor]="
              newsletter?.image?.palettes?.length > 0
                ? newsletter.image?.palettes[0]
                : ''
            "
          >
            <lib-soft-image-view
              [imageUrl]="newsletter?.imageUrl"
              [initialHeight]="'100px'"
              [image]="newsletter?.image"
              [imgixImage]="newsletter?.imgixImage"
              [alt]="newsletter?.title"
            >
            </lib-soft-image-view>
          </div>

          <div class="meta-wrapper">
            <div *ngIf="newsletter" class="meta card-text-color">
              <h2 class="title" [style]="newsletter?.cardTextCSS">
                {{ newsletter?.title }}
              </h2>
              <p
                *ngIf="newsletter?.subtitle"
                [style]="newsletter?.cardTextCSS"
                class="subtitle"
              >
                {{ newsletter.subtitle }}
              </p>
              <div *ngIf="infoStr">
                <nz-alert nzType="info" [nzMessage]="infoStr"> </nz-alert>
              </div>
            </div>
            <nz-skeleton
              *ngIf="!newsletter"
              [nzTitle]="false"
              [nzAvatar]="false"
              [nzParagraph]="{ rows: 3, width: 150 }"
            >
            </nz-skeleton>

            <div
              class="main-actions-container"
              (click)="$event.stopPropagation()"
            >
              <div class="row" *ngIf="newsletter?.published">
                <div class="wide">
                  <lib-newsletter-share-button-view
                    [isDisabled]="shouldDisableButtons || isLoading"
                    [isLoading]="isLoading"
                    (menuStateChanged)="handleMenuStateChanged()"
                    [newsletter]="newsletter"
                    [userContent]="userContent"
                  >
                  </lib-newsletter-share-button-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-card>

      <nz-card *ngIf="newsletter?.body" [style]="newsletter?.cardCSS">
        <div class="newsletter-body ql-readonly-body card-text-color">
          <div
            *ngIf="newsletter.body"
            [innerHTML]="
              newsletter?.signedBody || newsletter.body
                | safeHtml: newsletter?.cardTextCSS
            "
          ></div>
        </div>
      </nz-card>
    </div>
  </div>
</div>

import { SafeUrl } from '@angular/platform-browser';

import { plainToClass, Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

import { hashString, stripHtml, transformTimestampToDate } from '../../tools';
import { IImage, IImgixImageMap } from '../IImage';
import { IAccessControlSettings } from '../accessControl';
import { SOCIAL_TYPES } from '../../constants';
import { IContentStyles } from './styles';
import { ContentNotificationType } from './metadata';
import { DeliveryType } from '../general';
import { TimeZone } from '../timezone';

export class IContent {
  contentId!: string;
  contentType!: ContentType;
  images?: IImage[];
  imgixImages?: IImgixImageMap[];
  published!: boolean;

  styles?: IContentStyles;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  createdAt!: Date;
  createdAtCursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  modifiedAt?: Date;
  modifiedAtCursor?: string;

  title!: string;
  slugIdentifiers!: string[];
  primarySlugIdentifier!: string;

  get imageUrl(): string {
    return (
      this.imgixImage?.large?.url ??
      this.imgixImage?.medium?.url ??
      this.image?.url
    );
  }

  get imagesUrls(): string[] {
    return this.imgixImages.map(
      (img, idx) =>
        img?.large?.url ??
        img?.medium?.url ??
        this.image[idx]?.url ??
        this.image?.url
    );
  }

  get image(): IImage {
    return this.images?.[0];
  }

  get imgixImage(): IImgixImageMap {
    return this.imgixImages?.[0];
  }

  get isEvent(): boolean {
    return isEvent(this);
  }

  get isDrop(): boolean {
    return isDrop(this);
  }

  get isNewsletter(): boolean {
    return isNewsletter(this);
  }

  get isLink(): boolean {
    return isLink(this);
  }

  get routerPath(): string {
    return '/';
  }

  get primaryColor(): string {
    return this.image?.palettes?.[0];
  }

  get shareUrl(): string {
    if (isDrop(this) || isEvent(this) || isNewsletter(this))
      return this.urls?.pageShortLink ?? this.urls?.pageRaw;
    else if (isLink(this)) return this.url;
  }

  get summary(): string {
    const date = dayjs(this.modifiedAt ?? this.createdAt);
    return `Updated ${date.fromNow()}`;
  }

  get contentName(): string {
    return this.isDrop
      ? 'Signup'
      : this.isEvent
      ? 'Event'
      : this.isNewsletter
      ? 'Newsletter'
      : this.isLink
      ? 'Link'
      : 'Content';
  }

  get cardCSS(): string {
    const cardStyles = this.styles?.card;
    if (!cardStyles) return '';

    const { borderWidth, borderColor, dropShadow, backgroundColor } =
      cardStyles;
    let style = '';
    if (borderWidth) style += `border-width: ${borderWidth}px;`;
    if (borderColor) style += ` border-color: ${borderColor};`;
    if (dropShadow) style += ` box-shadow: ${dropShadow};`;
    if (backgroundColor) style += ` background-color: ${backgroundColor};`;
    return style;
  }

  get cardTextCSS(): string {
    const textColor = this.styles?.card?.textColor;
    return textColor ? `color: ${textColor}` : '';
  }

  static fromObject(object: IContent) {
    switch (object?.contentType) {
      case 'event':
        return plainToClass(IEvent, object);
      case 'link':
        return plainToClass(ILink, object);
      case 'drop':
        return plainToClass(IDrop, object);
      case 'newsletter':
        return plainToClass(INewsletter, object);
      default:
        return plainToClass(IContent, object);
    }
  }
}

export class IEventDropBase extends IContent {
  userInfoRequirements?: IUserInfoRequirements;
  privateUserInfoRequirements?: IPrivateUserInfoRequirements;
  rsvpRestrictions?: IEventDropRsvpRestrictions;
  tickets?: ITicket[];
  hasAccessControl?: boolean;
  buttonLabels?: ButtonLabels;
  postRegistrationRedirectUrl?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  canceledAt?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  registrationCloseDate?: Date;

  isCanceled?: boolean;
  tags?: string[];
  urls?: IPublicUrls;
  platform?: string;
  subtitle?: string;
  body?: string;
  signedBody?: string;
  people?: any;
  bodyType?: any;
  prompts?: IPrompt[];
  styles?: IContentStyles;

  get isFull() {
    return this.rsvpRestrictions && this.rsvpRestrictions?.remaining < 1;
  }

  get hasCapacity() {
    return this.rsvpRestrictions?.remaining > 0;
  }

  get isPaid() {
    return this.tickets?.length > 0;
  }

  get hasEmailRequirement(): boolean {
    return this.privateUserInfoRequirements?.email?.required;
  }

  get hasPhoneRequirement(): boolean {
    return this.privateUserInfoRequirements?.phoneNumber?.required;
  }

  get primaryColor(): string {
    return this.styles?.detail?.headerImageColor ?? this.image?.palettes?.[0];
  }

  get isPastRegistrationDeadline(): boolean {
    const currentMoment = dayjs();
    return (
      this.registrationCloseDate &&
      currentMoment.isAfter(dayjs(this.registrationCloseDate))
    );
  }
}

export type ContentLocationType = 'online' | 'irl';
export const ContentLocationTypes: ContentLocationType[] = ['online', 'irl'];

export class IEvent extends IEventDropBase {
  contentType!: 'event';
  contentLocation?: string;
  contentLocationType?: ContentLocationType;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  startDate!: Date;
  startDateCursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  endDate?: Date;

  get isOnline(): boolean {
    // Legacy may not have this populated
    return this.contentLocationType !== 'irl';
  }

  get isIrl(): boolean {
    return this.contentLocationType === 'irl';
  }

  get routerPath(): string {
    return `/event/${this.contentId}`;
  }

  get hours() {
    return this.endDate
      ? this.startMoment().diff(this.endMoment(), 'hours')
      : 1;
  }

  get summary(): string {
    const platform = this.platform ? ` on ${this.platform}` : '';
    const startMoment = this.startMoment();
    const endMoment = this.endMoment();
    const isToday = this.isToday;

    const daySpan = Math.abs(startMoment.diff(endMoment, 'days'));
    if (daySpan > 0) {
      if (isToday) return `Today through ${endMoment.format('LL')}${platform}`;
      return `${startMoment.format('LL')} to ${endMoment.format(
        'LL'
      )}${platform}`;
    }

    if (isToday) {
      const isHappening = this.isHappening;
      const isOver = this.isOver;

      if (isOver) return `Ended ${startMoment.fromNow()}${platform}`;
      else if (isHappening)
        return `Started ${startMoment.fromNow()}${platform}`;
      else
        return `${startMoment.format('h:mm a')} to ${endMoment.format(
          'h:mm a z'
        )}${platform}`;
    }

    return `${startMoment.calendar(null, {
      lastDay: '[Yesterday at] h:mm a z',
      sameDay: '[Today at] h:mm a z',
      nextDay: '[Tomorrow at] h:mm a z',
      lastWeek: '[Last] dddd [at] h:mm a z',
      nextWeek: 'dddd [at] h:mm a z',
      sameElse: 'LL [at] h:mm a z'
    })}${platform}`;
  }

  get isToday(): boolean {
    return this.startMoment().startOf('day').isSame(dayjs().startOf('day'));
  }

  get isHappening(): boolean {
    const now = dayjs();
    return this.startMoment().isBefore(now) && this.endMoment().isAfter(now);
  }

  get isOver(): boolean {
    const now = dayjs();
    return this.endMoment().isBefore(now);
  }

  get isFuture(): boolean {
    const now = dayjs();
    return this.startMoment().isAfter(now);
  }

  startMoment(): dayjs.Dayjs {
    return dayjs(this.startDate || undefined);
  }

  endMoment(): dayjs.Dayjs {
    return this.endDate ? dayjs(this.endDate) : this.startMoment();
  }

  registrationCloseDateMoment(): dayjs.Dayjs {
    return this.registrationCloseDate
      ? dayjs(this.registrationCloseDate)
      : null;
  }
}

export function isEvent(x: IContent): x is IEvent {
  return x?.contentType === 'event';
}

export class INotificationDTO {
  type!: ContentNotificationType;
  offset!: number;
  deliveryType!: DeliveryType;
  message?: string;
  subject?: string;
}

export class IDropNotificationDTO extends INotificationDTO {
  type!: ContentNotificationType;
}

export type NewsletterContentNotificationType = 'rsvpConfirmation';
export class INewsletterNotificationDTO extends INotificationDTO {
  type!: NewsletterContentNotificationType;
}

export class IEventNotificationDTO extends INotificationDTO {
  type!: ContentNotificationType;
}

export class IEventDropRsvpRestrictions {
  remaining!: number;
  limit?: number;
  displayLimit?: boolean;
}

export class IRsvpRestrictions {
  limit!: number;
  displayLimit?: boolean;
}

export class IUserInfoRequirement {
  required!: boolean;
}

export class IUserInfoRequirements {
  displayName!: IUserInfoRequirement;
}

export class IPrivateUserInfoRequirement {
  required!: boolean;
}
export class IPrivateUserInfoRequirements {
  email?: IPrivateUserInfoRequirement;
  phoneNumber?: IPrivateUserInfoRequirement;
}

export type RefundPolicy = 'noRefunds' | 'allowRequests';
export class ITicket {
  label!: string;
  refundPolicy?: RefundPolicy;
  price!: number;
  description?: string;
  statementDescription?: string;
}

export class IPublicUrls {
  donateRaw?: string;
  pageRaw!: string;
  pageShortLink!: string;
  postRegistrationRedirect!: string;
}

export class IPrompt {
  prompt!: string;
  required!: boolean;
  type?: string;
  options?: string[];
}

/// CREATION PAYLOADS
export type ZoomApprovalType = 'automatic' | 'manual' | 'noreg';
export class IZoomGeneration {
  generateZoom!: boolean;
  'host_video'?: boolean;
  'participant_video'?: boolean;
  'cn_meeting'?: boolean;
  'in_meeting'?: boolean;
  'join_before_host'?: boolean;
  'mute_upon_entry'?: boolean;
  'watermark'?: boolean;
  'approval_type'?: ZoomApprovalType;
  'audio'?: string;
  'auto_recording'?: string;
  'global_dial_in_countries'?: string[];
  'registrants_email_notification'?: boolean;
  timezone?: TimeZone;
}

export class ButtonLabels {
  pre?: string | null;
  mid?: string | null;
  post?: string | null;
  newsletter?: string | null;
}

export type ContentKeywordAction = 'rsvp';
export const ContentKeywordActions: ContentKeywordAction[] = ['rsvp'];

export class IContentKeywordDTO {
  keyword!: string;
  action!: ContentKeywordAction;
}

export class IContentKeyword {
  id!: string;
  slug!: string;
  keyword!: string;
  action!: ContentKeywordAction;
  contentId!: string;
  contentType!: ContentType;
  createdAt!: Date;
  createdAtCursor!: string;
}

export class IEventDropCreateBase {
  contentType!: SubscribableContentType;
  title!: string;
  subtitle?: string;
  summary?: string;
  primarySlug!: string;
  contentUrl?: string;
  recapUrl?: string;
  donateUrl?: string;
  body?: string;
  type?: string;
  published?: boolean;
  images?: string[];
  slugs!: string[];
  tags?: string[];
  bodyType?: string;
  people?: any;
  notifications?: INotificationDTO[];
  prompts?: IPrompt[];
  userInfoRequirements?: IUserInfoRequirements;
  privateUserInfoRequirements?: IPrivateUserInfoRequirements;
  rsvpRestrictions?: IRsvpRestrictions;
  tickets?: ITicket[];
  accessControlSettings?: IAccessControlSettings;
  registrationCloseDate?: string;
  password?: string | null;
  buttonLabels?: ButtonLabels;
  collectionIds?: string[];
  keywords?: IContentKeywordDTO[];
  styles?: IContentStyles;
  postRegistrationRedirectUrl?: string;
}

export type SubscribableContentType = 'event' | 'drop' | 'newsletter';

export class IEventDropEditBase {
  title?: string;
  subtitle?: string;
  summary?: string;
  primarySlug?: string;
  contentUrl?: string;
  recapUrl?: string;
  donateUrl?: string;
  body?: string;
  type?: string;
  published?: boolean;
  images?: string[];
  slugs?: string[];
  tags?: string[];
  bodyType?: any;
  people?: any;
  notifications?: INotificationDTO[];
  prompts?: IPrompt[];
  userInfoRequirements?: IUserInfoRequirements;
  privateUserInfoRequirements?: IPrivateUserInfoRequirements;
  rsvpRestrictions?: IRsvpRestrictions;
  tickets?: ITicket[];
  accessControlSettings?: IAccessControlSettings;
  registrationCloseDate?: string | null;
  password?: string | null;
  buttonLabels?: ButtonLabels;
  collectionIds?: string[];
  keywords?: IContentKeywordDTO[] | null;
  styles?: IContentStyles;
}

// CRUD DTOs
export class IEventCreate extends IEventDropCreateBase {
  contentType: 'event';
  notifications?: IEventNotificationDTO[];
  zoomGeneration?: IZoomGeneration;
  type?: string;
  startDate!: string;
  endDate?: string;
  contentLocation?: string;
  contentLocationPublic?: boolean;
}

export class IEventEdit extends IEventDropEditBase {
  notifications?: IEventNotificationDTO[];
  zoomGeneration?: IZoomGeneration;
  type?: string;
  startDate?: string;
  endDate?: string | null;
  contentLocation?: string;
  contentLocationPublic?: boolean;
}

export class IDropCreate extends IEventDropCreateBase {
  contentType: 'drop';
  notifications?: IDropNotificationDTO[];
  triggerAt?: string;
}

export class IDropEdit extends IEventDropEditBase {
  notifications?: IDropNotificationDTO[];
  triggerAt?: string | null;
}

export type ContentType = 'event' | 'link' | 'drop' | 'newsletter';

export type EntityType = ContentType | 'singleSend' | 'collection';

export class IDrop extends IEventDropBase {
  contentType!: 'drop';

  get routerPath(): string {
    return `/signup/${this.contentId}`;
  }

  @Transform(transformTimestampToDate, { toClassOnly: true })
  triggerAt?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  triggeredAt?: Date;

  triggerProcessed!: boolean;

  get summary(): string {
    return this.triggerProcessed
      ? `Closed ${dayjs(this.triggeredAt).fromNow()}`
      : super.summary;
  }

  get isPending(): boolean {
    return this.triggerAt
      ? dayjs(this.triggerAt).isBefore(dayjs()) && !this.triggerProcessed
      : false;
  }

  get isScheduled(): boolean {
    return (
      this.triggerAt &&
      !this.triggerProcessed &&
      dayjs(this.triggerAt).isAfter(dayjs())
    );
  }

  registrationCloseDateMoment(): dayjs.Dayjs {
    return this.registrationCloseDate
      ? dayjs(this.registrationCloseDate)
      : null;
  }
}

export function isDrop(x: IContent): x is IDrop {
  return x?.contentType === 'drop';
}

// Newsletters

export class INewsletterCreate extends IEventDropCreateBase {
  contentType: 'newsletter';
  notifications?: INewsletterNotificationDTO[];

  @Transform(transformTimestampToDate, { toClassOnly: true })
  publishAt: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  sendAt: Date;
}

export class INewsletterEdit extends IEventDropEditBase {
  notifications?: INewsletterNotificationDTO[];

  @Transform(transformTimestampToDate, { toClassOnly: true })
  publishAt?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  sendAt?: Date;
}

export class INewsletter extends IEventDropBase {
  contentType!: 'newsletter';

  @Transform(transformTimestampToDate, { toClassOnly: true })
  publishAt: Date;
  publishAtCursor: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  sendAt: Date;
  sendAtCursor: string;

  get routerPath(): string {
    return `/newsletter/${this.contentId}`;
  }

  get isScheduled(): boolean {
    const now = dayjs();
    return this.published && this.publishMoment().isAfter(now);
  }

  get isAvailable(): boolean {
    const now = dayjs();
    return this.published && this.publishMoment().isBefore(now);
  }

  publishMoment(): dayjs.Dayjs {
    return dayjs(this.publishAt);
  }

  sendMoment(): dayjs.Dayjs {
    return this.sendAt ? dayjs(this.sendAt) : this.publishMoment();
  }
}

export function isNewsletter(x: IContent): x is INewsletter {
  return x?.contentType === 'newsletter';
}

export class ILinkColors {
  text?: string | null;
  card?: string | null;
}

export type LinkType = 'absolute' | 'collection' | 'email' | 'phoneNumber';
export type AvatarClickThroughUrlType = 'absolute' | 'collection';
export type LinkImagePosition = 'top' | 'left' | 'right' | 'bottom';

export class ILink extends IContent {
  contentType!: 'link';
  url!: string;
  description?: string;
  colors?: ILinkColors;
  type!: LinkType;
  imagePosition?: LinkImagePosition;
  textPosition?: LinkTextPosition;

  get imageIsTop(): boolean {
    return this.imagePosition === 'top' || !this.imagePosition;
  }

  get imageIsLeft(): boolean {
    return this.imagePosition === 'left';
  }

  get imageIsRight(): boolean {
    return this.imagePosition === 'right';
  }

  get imageIsBottom(): boolean {
    return this.imagePosition === 'bottom';
  }

  get routerPath(): string {
    return `/link/${this.contentId}`;
  }
}

export type LinkTextPosition = 'left' | 'right' | 'center';
const LinkTextPositionEnum: LinkTextPosition[] = ['left', 'right', 'center'];

export class ICreateLink {
  contentType: 'link';
  primarySlugIdentifier!: string;
  title!: string;
  description!: string;
  url!: string;
  published!: boolean;
  images?: string[];
  colors?: ILinkColors;
  type?: LinkType;
  imagePosition?: LinkImagePosition;
  collectionIds?: string[];
  styles?: IContentStyles;
  textPosition?: LinkTextPosition;
}

export class IUpdateLink {
  title?: string;
  description?: string;
  url?: string;
  published?: boolean;
  images?: string[];
  colors?: ILinkColors;
  type?: LinkType;
  imagePosition?: LinkImagePosition;
  collectionIds?: string[];
  styles?: IContentStyles;
  textPosition?: LinkTextPosition;
}

export interface ILinkUpdateInput {
  title?: string;
  description?: string;
  url?: string;
  draft?: boolean;
  images?: IImage[];
  imgixImages?: IImgixImageMap[];
  imagePosition?: LinkImagePosition;
  colors?: {
    text?: string | null;
    card?: string | null;
  };
}

export type LinkInteractionType = 'click';

export class IUserLinkInteraction {
  id!: string;
  userId!: string;
  linkId!: string;
  interactionType!: LinkInteractionType;
  interactionValue?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  createdAt!: Date;

  createdAtCursor!: string;
}

export class ITrackLinkInteraction {
  interactionType!: LinkInteractionType;
}

export function isLink(x: IContent): x is ILink {
  return x?.contentType === 'link';
}

export type ContentFamily = ILink | IEvent | IDrop;

export class ICreateCollection {
  blocks!: IBlockDTO[];
  slug!: string;
  label!: string;
  default!: boolean;
  title?: string;
  description?: string;
  published?: boolean;
}

export class IUpdateCollection {
  blocks?: IBlockDTO[];
  label?: string;
  title?: string;
  description?: string;
  default?: boolean;
  published?: boolean;
}

export class ISetDefaultCollection {
  id?: string;
  slug!: string;
}

export type BlockType =
  | 'content'
  | 'profile'
  | 'text'
  | 'socialIcons'
  | 'image'
  | 'spacer'
  | 'upcomingEvents'
  | 'youtube'
  | 'vimeo'
  | 'twitter'
  | 'embed'
  | 'newsletterListing'
  | 'newsletterSignup'
  | 'header'
  | 'instagram';

export const BlockTypeEnum: BlockType[] = [
  'content',
  'profile',
  'text',
  'socialIcons',
  'image',
  'spacer',
  'upcomingEvents',
  'youtube',
  'vimeo',
  'twitter',
  'embed',
  'newsletterListing',
  'newsletterSignup',
  'header'
];

export class IBlockDTO {
  blockType!: BlockType;
  contentId?: string;
}

export class IBlock extends IBlockDTO {
  content?: IContent;
  body?: string;

  get hash(): number {
    return hashString(this.blockType);
  }

  get icon(): string {
    return 'build';
  }

  get header(): string {
    return 'Block';
  }

  get title(): string {
    return 'Any block';
  }
}

export class IContentBlockDTO extends IBlockDTO {
  contentId!: string;
  blockType!: 'content';
}

export class IContentBlock extends IContentBlockDTO {
  @Transform((val) => IContent.fromObject(val.value), { toClassOnly: true })
  content!: IContent;

  get hash(): number {
    return hashString(this.contentId + this.blockType);
  }

  get icon(): string {
    return this.content?.isLink
      ? 'link'
      : this.content?.isDrop
      ? 'form'
      : this.content?.isEvent
      ? 'calendar'
      : this.content?.isNewsletter
      ? 'mail'
      : null;
  }

  get header(): string {
    const s = this.content?.contentType;
    return this.content?.isLink
      ? 'Link'
      : this.content?.isDrop
      ? 'Signup'
      : this.content?.isEvent
      ? 'Event'
      : this.content?.isNewsletter
      ? 'Newsletter'
      : `${s[0].toUpperCase()}${s.substring(1)}`;
  }

  get title(): string {
    return this.content?.title;
  }
}

export function isContentBlock(x: IBlock): x is IContentBlock {
  return x.blockType === 'content';
}

export function isLinkBlock(x: IBlock): boolean {
  return isContentBlock(x) && x.content?.contentType === 'link';
}

export function isEventBlock(x: IBlock): boolean {
  return isContentBlock(x) && x.content?.contentType === 'event';
}

export function isDropBlock(x: IBlock): boolean {
  return isContentBlock(x) && x.content?.contentType === 'drop';
}

export function isNewsletterBlock(x: IBlock): boolean {
  return isContentBlock(x) && x.content?.contentType === 'newsletter';
}

export class INewsletterListingBlockDTO extends IBlockDTO {
  blockType!: 'newsletterListing';
  tag?: string;
  limit?: number;
  reverse?: boolean;
  startAt?: Date;
}

export class INewsletterListingBlock extends INewsletterListingBlockDTO {
  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.tag}
      ${this.limit}
      ${this.reverse}
      ${this.startAt?.toString()}
    `);
  }

  get icon(): string {
    return 'group';
  }

  get header(): string {
    return 'Newsletter list';
  }

  get title(): string {
    const count = this?.limit ?? 10;
    return `Up to ${count} newsletter${count === 1 ? '' : 's'}`;
  }
}

export function isNewsletterListingBlock(
  x: IBlock
): x is INewsletterListingBlock {
  return x.blockType === 'newsletterListing';
}

export class INewsletterSignupBlockDTO extends IBlockDTO {
  blockType!: 'newsletterSignup';
  title: string;
  subtitle?: string;
  postSubmitTitle?: string;
  postSubmitMessage?: string;
  buttonLabel?: string;
  rawImages?: string[];
  images?: IImage[];
  imgixImages?: IImgixImageMap[];

  get imageUrl(): string {
    return (
      this.imgixImage?.large?.url ??
      this.imgixImage?.medium?.url ??
      this.image?.url ??
      this.rawImages?.[0]
    );
  }

  get imagesUrls(): string[] {
    return this.imgixImages.map(
      (img, idx) =>
        img?.large?.url ??
        img?.medium?.url ??
        this.image[idx]?.url ??
        this.image?.url ??
        this.rawImages?.[0]
    );
  }

  get image(): IImage {
    return (
      this.images?.[0] ?? (this.rawImages?.length && { url: this.rawImages[0] })
    );
  }

  get imgixImage(): IImgixImageMap {
    return (
      this.imgixImages?.[0] ??
      (this.rawImages?.length && {
        thumbnail: { url: this.rawImages[0] },
        small: { url: this.rawImages[0] },
        medium: { url: this.rawImages[0] },
        large: { url: this.rawImages[0] }
      })
    );
  }
}

export class INewsletterSignupBlock extends INewsletterSignupBlockDTO {
  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.title}
      ${this.subtitle}
      ${this.postSubmitTitle}
      ${this.postSubmitMessage}
      ${this.buttonLabel}
    `);
  }

  get icon(): string {
    return 'user-add';
  }

  get header(): string {
    return 'Newsletter signup';
  }
}

export function isNewsletterSignupBlock(
  x: IBlock
): x is INewsletterSignupBlock {
  return x.blockType === 'newsletterSignup';
}

export class IUpcomingEventsBlockDTO extends IBlockDTO {
  blockType!: 'upcomingEvents';
  groupBy?: 'default' | 'day' | 'week' | 'month' | null;
  tag?: string;
  limit?: number;
  reverse?: boolean;
  startAt?: Date;
}

export class IUpcomingEventsBlock extends IUpcomingEventsBlockDTO {
  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.groupBy}
      ${this.tag}
      ${this.limit}
      ${this.reverse}
      ${this.startAt?.toString()}
    `);
  }

  get icon(): string {
    return 'group';
  }

  get header(): string {
    return 'Event list';
  }

  get title(): string {
    const count = this?.limit ?? 10;
    const order = this?.reverse ? 'past' : 'upcoming';
    return `Up to ${count} ${order} event${count === 1 ? '' : 's'}`;
  }
}

export function isUpcomingEventsBlock(x: IBlock): x is IUpcomingEventsBlock {
  return x.blockType === 'upcomingEvents';
}

export function isProfileBlock(x: IBlock): x is IProfileBlock {
  return x.blockType === 'profile';
}

export class IHeaderBlockDTO extends IBlockDTO {
  blockType!: 'header';
  style?: 'creator' | 'brand';
  avatarUrl?: string | SafeUrl;
  height?: number;
  backgroundColor?: string;
  backgroundGradient?: string;
  backgroundImageUrl?: string;
  maskUrl?: string;
  avatarClickThroughUrl?: string;
  avatarClickThroughUrlType: AvatarClickThroughUrlType;
}

export class IHeaderBlock extends IHeaderBlockDTO {
  avatarImage?: IImage;
  avatarImgixImage?: IImgixImageMap;
  backgroundImage?: IImage;
  backgroundImgixImage?: IImgixImageMap;

  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.style}
      ${this.avatarUrl}
      ${this.height}
      ${this.avatarImage?.url}
      ${this.avatarImgixImage?.large?.url}
      ${this.backgroundImageUrl}
    `);
  }

  get icon(): string {
    return 'bulb';
  }

  get header(): string {
    return 'Header';
  }

  get title(): string {
    const s = this.style ?? 'brand';
    return `${s[0].toUpperCase()}${s.substring(1)}-style header`;
  }
}

export function isHeaderBlock(x: IBlock): x is IHeaderBlock {
  return x.blockType === 'header';
}

export class IYoutubeBlockDTO extends IBlockDTO {
  blockType!: 'youtube';
  url!: string;
  width?: number;
  height?: number;
}
export class IYoutubeBlock extends IYoutubeBlockDTO {
  embedCode!: string;

  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.url}
      ${this.embedCode}
      ${this.width}
      ${this.height}
    `);
  }

  get icon(): string {
    return 'youtube';
  }

  get header(): string {
    return 'YouTube';
  }

  get title(): string {
    return 'YouTube video';
  }
}
export function isYoutubeBlockDto(x: IBlock): x is IYoutubeBlock {
  return x.blockType === 'youtube';
}

export class IVimeoBlockDTO extends IBlockDTO {
  blockType!: 'vimeo';
  url!: string;
  width?: number;
  height?: number;
}
export class IVimeoBlock extends IVimeoBlockDTO {
  embedCode!: string;

  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.url}
      ${this.width}
      ${this.height}
    `);
  }

  get icon(): string {
    return 'video-camera';
  }

  get header(): string {
    return 'Vimeo';
  }

  get title(): string {
    return 'Vimeo video';
  }
}
export function isVimeoBlockDto(x: IBlock): x is IVimeoBlock {
  return x.blockType === 'vimeo';
}
export class ITwitterBlockDTO extends IBlockDTO {
  blockType!: 'twitter';
  url!: string;
}
export class ITwitterBlock extends ITwitterBlockDTO {
  embedCode!: string;

  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.url}
      ${this.embedCode}
    `);
  }

  get icon(): string {
    return 'twitter';
  }

  get header(): string {
    return 'Tweet';
  }

  get title(): string {
    return 'Embedded tweet';
  }
}

export function isTwitterBlockDto(x: IBlock): x is ITwitterBlock {
  return x.blockType === 'twitter';
}

export class IInstagramBlockDTO extends IBlockDTO {
  blockType!: 'instagram';
  url!: string;
}

export class IInstagramBlock extends IInstagramBlockDTO {
  embedCode!: string;

  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.url}
      ${this.embedCode}
    `);
  }

  get icon(): string {
    return 'instagram';
  }

  get header(): string {
    return 'Instagram Post';
  }

  get title(): string {
    return 'Embedded Instagram post';
  }
}

export function isInstagramBlockDto(x: IBlock): x is IInstagramBlock {
  return x.blockType === 'instagram';
}

export class IEmbedBlockDTO extends IBlockDTO {
  blockType!: 'embed';
  embedCode!: string;
}

export class IEmbedBlock extends IEmbedBlockDTO {
  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.embedCode}
    `);
  }
}

export class IProfileBlockDTO extends IBlockDTO {
  blockType!: 'profile';
  showTitle?: boolean;
  showPronouns?: boolean;
  showBio?: boolean;
  showLocation?: boolean;
}

export class IProfileBlock extends IProfileBlockDTO {
  get hash(): number {
    return hashString('profile');
  }

  get icon(): string {
    return 'profile';
  }

  get header(): string {
    return 'Profile';
  }

  get title(): string {
    const items = [
      ...(this.showTitle ? ['name'] : []),
      ...(this.showPronouns ? ['pronouns'] : []),
      ...(this.showBio ? ['bio'] : []),
      ...(this.showLocation ? ['location'] : [])
    ];
    return `Your ${items.length > 0 ? items.join(', ') : 'profile'}`;
  }
}

export class ITextBlockDTO extends IBlockDTO {
  body!: string;
  blockType!: 'text';
}

export class ITextBlock extends ITextBlockDTO {
  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.body}
    `);
  }

  get icon(): string {
    return 'font-size';
  }

  get header(): string {
    return 'Rich text';
  }

  get title(): string {
    return stripHtml(this?.body);
  }
}

export function isTextBlock(x: IBlock): x is ITextBlock {
  return x.blockType === 'text';
}

export class ISocialIconsBlockDTO extends IBlockDTO {
  blockType!: 'socialIcons';
  settings?: ISocialIconsSettings;
}

export class ISocialIconsBlock extends ISocialIconsBlockDTO {
  get hash(): number {
    return hashString('socialIcons');
  }

  get icon(): string {
    return 'smile';
  }

  get header(): string {
    return 'Social icons';
  }

  get title(): string {
    return 'Your social icons';
  }
}

export type ISocialIconsSettings = {
  [key in SOCIAL_TYPES]: boolean;
};

export function isSocialBlockDto(x: IBlock): x is ISocialIconsBlock {
  return x.blockType === 'socialIcons';
}

export class IImageBlockDTO extends IBlockDTO {
  blockType!: 'image';
  rawImages!: string[];
  hideCard?: boolean;
}

export class IImageBlock extends IImageBlockDTO {
  images!: IImage[];
  imgixImages!: IImgixImageMap[];

  get hash(): number {
    return hashString(`
      ${this.blockType}
      ${this.rawImages?.join()}
      ${this.images?.join()}
      ${this.imgixImages?.join()}
    `);
  }

  get icon(): string {
    return 'picture';
  }

  get header(): string {
    return 'Image';
  }

  get title(): string {
    const isSingular = this.rawImages?.length === 1;
    return `${this.rawImages?.length || 0} image${isSingular ? '' : 's'}`;
  }
}

export function isImageBlockDto(x: IBlock): x is IImageBlock {
  return x.blockType === 'image';
}

export class ISpacerBlockDTO extends IBlockDTO {
  blockType!: 'spacer';
  height?: number;
}

export class ISpacerBlock extends ISpacerBlockDTO {
  get hash(): number {
    return hashString('spacer');
  }

  get icon(): string {
    return 'column-width';
  }

  get header(): string {
    return 'Spacer';
  }

  get title(): string {
    return 'Spacer';
  }
}

export function isSpacerBlock(x: IBlock): x is ISpacerBlock {
  return x.blockType === 'spacer';
}

export function isSocialIconsBlock(x: IBlock): x is ISocialIconsBlock {
  return x.blockType === 'socialIcons';
}

export class ICollection {
  id!: string;
  slug!: string;
  label!: string;
  title?: string;
  default!: boolean;
  description?: string;
  entityIds!: string[];
  published?: boolean;
  createdAt!: Date;
  modifiedAt!: Date;
  createdAtCursor?: string;
  modifiedAtCursor?: string;
  backgroundImage?: string;
  signedBackgroundImage?: string;
  previewImage?: string;
  signedPreviewImage?: string;
  backgroundColor?: string;
  backgroundGradient?: string;

  @Type(() => IBlock, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'blockType',
      subTypes: [
        { value: IContentBlock, name: 'content' },
        { value: IProfileBlock, name: 'profile' },
        { value: ITextBlock, name: 'text' },
        { value: IImageBlock, name: 'image' },
        { value: ISocialIconsBlock, name: 'socialIcons' },
        { value: ISpacerBlock, name: 'spacer' },
        { value: IEmbedBlock, name: 'embed' },
        { value: ITwitterBlock, name: 'twitter' },
        { value: IVimeoBlock, name: 'vimeo' },
        { value: IYoutubeBlock, name: 'youtube' },
        { value: IUpcomingEventsBlock, name: 'upcomingEvents' },
        { value: INewsletterListingBlock, name: 'newsletterListing' },
        { value: INewsletterSignupBlock, name: 'newsletterSignup' },
        { value: IHeaderBlock, name: 'header' },
        { value: IInstagramBlock, name: 'instagram' }
      ]
    }
  })
  blocks!: IBlock[];
}

export class CancelationNotificationSettings {
  deliveryType!: DeliveryType;
  message!: string;
  subject?: string;
}

export class IEmailCancelationNotificationSettings extends CancelationNotificationSettings {
  @Type(() => String)
  deliveryType!: 'email';
  message!: string;
  subject!: string;
}

export class ISmsCancelationNotificationSettings extends CancelationNotificationSettings {
  @Type(() => String)
  deliveryType!: 'sms';
  message!: string;
  subject?: string;
}

export class IContentCancelation {
  @Type(() => CancelationNotificationSettings, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'deliveryType',
      subTypes: [
        { value: IEmailCancelationNotificationSettings, name: 'email' },
        { value: ISmsCancelationNotificationSettings, name: 'sms' }
      ]
    }
  })
  cancelationNotificationSettings!: CancelationNotificationSettings;

  @Type(() => Boolean)
  unpublish!: boolean;
}

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import Grapick from 'grapick';

@Component({
  selector: 'lib-gradient-generator',
  templateUrl: './gradient-generator.component.html',
  styleUrls: [
    '../../../../../../node_modules/grapick/dist/grapick.min.css',
    './gradient-generator.component.less'
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientGeneratorComponent implements AfterViewInit, OnChanges {
  @ViewChild('picker', { static: true })
  picker: ElementRef;

  @ViewChild('colorPicker', { static: true })
  colorPicker: ElementRef;

  @Input()
  initialValue: string;

  @Output()
  handleValueChanged = new EventEmitter<string>();

  angle: number;
  colorPickerVisible: boolean;
  selectedHandle: any;
  type: 'linear' | 'radial' = 'linear';

  private _isDragging: boolean;
  private _gp: any;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platform,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.initialValue?.currentValue &&
      this._gp &&
      !this._isDragging &&
      !this.colorPickerVisible
    ) {
      this._gp.setValue(changes.initialValue.currentValue, { silent: true });
      this._gp.setDirection(this._gp?.getDirection(), { silent: true });
      this.angle = +this._gp?.getDirection()?.replace('deg', '');
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platform)) {
      this._gp = new Grapick({
        el: this.picker.nativeElement
      });

      this._gp.setColorPicker((handler) => {
        const listener = (ev: MouseEvent) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          handler?.select?.();
          this.selectedHandle = handler;
          this.colorPickerVisible = !this.colorPickerVisible;
          this._cdr.detectChanges();
          setTimeout(() => {
            const picker = this._document.querySelector('.color-picker') as any;
            if (picker && this.colorPickerVisible) {
              picker.style.left = ev.clientX + 'px';
              picker.style.top = ev.clientY + 'px';
            }
          }, 1);
        };

        const wrapper = handler.getEl().querySelector('.grp-handler-cp-wrap');
        wrapper.addEventListener('click', listener, true);

        // return a function in order to destroy the custom color picker
        return () => {
          wrapper.removeEventListener('click', listener, true);
        };
      });

      this._gp.setValue(this.initialValue);
      this._gp.setDirection(this._gp?.getDirection());
      this.angle = +this._gp?.getDirection()?.replace('deg', '');
      this.type = this._gp.getType();

      this._gp.on('change', (complete) => {
        const val = this._cleanUpValue(this._gp.getSafeValue());
        this.handleValueChanged.emit(val);
      });

      this._gp.on('handler:drag', (e) => {
        this._isDragging = true;
      });
      this._gp.on('handler:drag:end', (e) => {
        this._isDragging = false;
      });
    }
  }

  handleAngleChanged(angle: number) {
    if (this._gp) {
      this._gp.setDirection(`${angle}deg`);
    }
  }

  handleTypeChange($event: any) {
    this._gp.setType($event);
    const getCss = this._gp?.getSafeValue(
      $event,
      $event === 'radial' ? 'center' : 'right'
    );
    this.handleValueChanged.emit(this._cleanUpValue(getCss));
  }

  colorChanged(color: string) {
    if (this.selectedHandle) {
      this.selectedHandle.setColor(color);
      this.selectedHandle
        .getEl()
        .querySelector('.grp-handler-cp-wrap').style.backgroundColor = color;
    }
  }

  private _cleanUpValue(safeValue: string) {
    return safeValue?.replace(/-webkit-|-moz-|-o-|-ms-/, '');
  }
}

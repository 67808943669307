import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContentInteractionsService, ErrorService } from '../../services';
import { IImage, IImgixImageMap, INewsletterSignupBlock } from '../../types';

@Component({
  selector: 'lib-newsletter-signup-view',
  templateUrl: './newsletter-signup-view.component.html',
  styleUrls: ['./newsletter-signup-view.component.less']
})
export class NewsletterSignupViewComponent implements OnInit {
  @Input() block: INewsletterSignupBlock;
  @Input() value: string;
  formGroup: FormGroup;
  isLoading = false;
  subscribed = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _interactions: ContentInteractionsService,
    private _error: ErrorService
  ) {}

  get images(): IImage[] {
    return (
      this.block?.images ||
      this.block?.rawImages?.map((img) => ({ url: img })) ||
      []
    );
  }

  get imgixImages(): IImgixImageMap[] {
    return this.block?.imgixImages || [];
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  async submit() {
    this.isLoading = true;
    const { email } = this.formGroup.value;

    try {
      await this._interactions.subscribeToNewsletter(email);
      this.subscribed = true;
    } catch (e) {
      this._error.displayError(e);
    }

    this.isLoading = false;
  }
}

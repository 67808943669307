import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import {
  Firestore,
  doc,
  docData,
  collection,
  collectionData,
  getDoc,
  query,
  where,
  startAfter as _startAfter,
  limit as _limit,
  orderBy as _orderBy,
  endAt as _endAt,
  collectionSnapshots
} from '@angular/fire/firestore';

// 3rd party
import { map, switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { plainToClass } from 'class-transformer';

// App
import { DeviceService } from '../device';
import { ApiService } from '../api';
import { ErrorService } from '../error';
import { ENDPOINTS, PAGE_SIZE } from '../../constants';
import { IContent, ICollection, IEvent, INewsletter } from '../../types';

/*
  Content retrieval
*/

export type PaymentIntentType = { secret: string; accountId: string };

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _error: ErrorService,
    private _firestore: Firestore,
    private _api: ApiService,
    private _device: DeviceService
  ) {}

  // Retrieve a content object
  getContent$<T extends IContent>(id: string): Observable<T> {
    if (!id?.length) return from([null as T]);
    const ref = doc(this._firestore, 'content', id);
    return docData(ref).pipe(
      map((c) => IContent.fromObject(c as IContent) as T)
    );
  }

  async getContent<T extends IContent>(id: string): Promise<T> {
    if (!id?.length) return null as T;
    try {
      const ref = doc(this._firestore, 'content', id);
      const snapshot = await getDoc(ref);
      return snapshot.exists
        ? (IContent.fromObject(snapshot.data() as IContent) as T)
        : null;
    } catch (e) {
      return null;
    }
  }

  getCollection$({
    label = '',
    isDefault = false,
    published = true
  }: {
    label?: string;
    isDefault?: boolean;
    published?: boolean;
  }): Observable<ICollection> {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(this._firestore, 'collections');
        const constraints = [
          where('slug', '==', slug),
          where('published', '==', published),
          ...(label ? [where('label', '==', label)] : []),
          ...(isDefault ? [where('default', '==', true)] : []),
          _limit(1)
        ];
        return collectionData(query(ref, ...constraints)).pipe(
          map((l) => (l?.length ? plainToClass(ICollection, l[0]) : null))
        );
      })
    );
  }

  getCollectionByLabel$(label: string): Observable<ICollection> {
    return this.getCollection$({ label });
  }

  getDefaultCollectionForCurrentSlug$(): Observable<ICollection> {
    return this.getCollection$({ isDefault: true });
  }

  getNewslettersCollection({
    published,
    limit,
    startAfter,
    ascending,
    tag
  }: {
    published?: boolean;
    limit?: number;
    startAfter?: string;
    ascending?: boolean;
    tag?: string;
  }) {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(this._firestore, 'content');
        // Can't have multiple array contains clauses in one query
        // If tags are specified, then we can only query on the
        // primary slug
        const constraints = [
          where('contentType', '==', 'newsletter'),
          ...(tag
            ? [where('primarySlugIdentifier', '==', slug)]
            : [where('slugIdentifiers', 'array-contains', slug)]),
          where('published', '==', published ?? true),
          ...(tag ? [where('tags', 'array-contains', tag)] : []),
          _orderBy('publishAtCursor', ascending ? 'asc' : 'desc'),
          ...(startAfter ? [_startAfter(startAfter)] : []),
          _limit(limit ?? PAGE_SIZE)
        ];
        return collectionSnapshots(query(ref, ...constraints));
      })
    );
  }

  getNewslettersForCurrentSlug$({
    published,
    limit,
    startAfter,
    ascending,
    tag
  }: {
    published?: boolean;
    limit?: number;
    startAfter?: string;
    ascending?: boolean;
    tag?: string;
  }): Observable<INewsletter[]> {
    if (isPlatformServer(this._platform)) return from([null]);

    return this.getNewslettersCollection({
      startAfter,
      published,
      limit,
      ascending,
      tag
    }).pipe(
      map((list) => list.map((n) => plainToClass(INewsletter, n.data())))
    );
  }

  getEventsCollection({
    startAfter,
    endAt,
    published,
    descending,
    limit,
    orderBy = 'startDate',
    tag
  }: {
    startAfter?: string;
    endAt?: Date;
    published?: boolean;
    descending?: boolean;
    tag?: string;
    limit?: number;
    orderBy?: 'startDate' | 'modifiedAt';
  }) {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(this._firestore, 'content');
        // Can't have multiple array contains clauses in one query
        // If tags are specified, then we can only query on the
        // primary slug
        const constraints = [
          where('contentType', '==', 'event'),
          ...(tag
            ? [where('primarySlugIdentifier', '==', slug)]
            : [where('slugIdentifiers', 'array-contains', slug)]),
          where('published', '==', published ?? true),
          ...(tag ? [where('tags', 'array-contains', tag)] : []),
          _orderBy(`${orderBy}Cursor`, descending ? 'desc' : 'asc'),
          ...(startAfter ? [_startAfter(startAfter)] : []),
          ...(endAt ? [_endAt(endAt)] : []),
          _limit(limit ?? PAGE_SIZE)
        ];
        return collectionSnapshots(query(ref, ...constraints));
      })
    );
  }

  getEventsForCurrentSlug$({
    startAfter,
    endAt,
    published,
    descending,
    limit,
    orderBy = 'startDate',
    tag
  }: {
    startAfter?: string;
    endAt?: Date;
    published?: boolean;
    descending?: boolean;
    limit?: number;
    tag?: string;
    orderBy?: 'startDate' | 'modifiedAt';
  }): Observable<IEvent[]> {
    if (isPlatformServer(this._platform)) return from([null]);

    return this.getEventsCollection({
      startAfter,
      endAt,
      published,
      descending,
      limit,
      tag,
      orderBy
    }).pipe(map((list) => list.map((e) => plainToClass(IEvent, e.data()))));
  }

  async getPaymentIntentSecret(
    eventId: string,
    label: string
  ): Promise<PaymentIntentType> {
    try {
      const endpoint = `${ENDPOINTS.event}/${eventId}/payment_intent`;
      const body = { applyTax: true, label };
      const ret = await this._api.post<PaymentIntentType>(endpoint, body);
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  getStaticDocument$<T>(document: string): Observable<T> {
    if (isPlatformServer(this._platform)) return from([null]);
    const ref = doc(this._firestore, 'static', document);
    return docData(ref) as Observable<T>;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { INewsletter, IUserContent } from '../../types';

@Component({
  selector: 'lib-newsletter-card-view',
  templateUrl: './newsletter-card-view.component.html',
  styleUrls: ['./newsletter-card-view.component.less']
})
export class NewsletterCardViewComponent {
  @Output() cardClick = new EventEmitter<INewsletter>();
  @Output() menuStateChanged = new EventEmitter<void>();
  @Input() newsletter: INewsletter;
  @Input() userContent: IUserContent;
  @Input() isLoadingNewsletter = false;
  @Input() isLoadingUserContent = false;
  @Input() isDisabled = false;

  handleClick(): void {
    if (!this.isDisabled) this.cardClick.next(this.newsletter);
  }

  get priceStr(): string {
    if (this.newsletter?.tickets?.length > 0) {
      const price = (this.newsletter.tickets[0]?.price ?? 0) / 100;
      return `$${price}`;
    }

    return null;
  }

  handleMenuStateChanged() {
    this.menuStateChanged.next();
  }
}

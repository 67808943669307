export const CUBE_EVENTS = {
  userClickedPreEventCta: 'userClickedPreEventCta',
  userClickedPostEventCta: 'userClickedPostEventCta',
  userSavedToGcal: 'userSavedToGcal',
  userSavedToIcal: 'userSavedToIcal',
  userTappedShare: 'userTappedShare',
  userTappedDonate: 'userTappedDonate'
};

export const AUTH_FLOW_EVENTS = {
  userBeganLoginFlow: 'userBeganLoginFlow',
  userFinishedLoginFlow: 'userFinishedLoginFlow',

  userBeganRegisterForDropFlow: 'userBeganRegisterForDropFlow',
  userFinishedRegisterForDropFlow: 'userFinishedRegisterForDropFlow',

  userBeganRegisterForEventFlow: 'userBeganRegisterForEventFlow',
  userFinishedRegisterForEventFlow: 'userFinishedRegisterForEventFlow',

  userBeganRegisterForNewsletterFlow: 'userBeganRegisterForNewsletterFlow',
  userFinishedRegisterForNewsletterFlow:
    'userFinishedRegisterForNewsletterFlow'
};

export const UI_EVENTS = {
  userUploadedCustomFont: 'userUploadedCustomFont'
};

import { TransformFnParams } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';

// Utility to transform Timestamp to date
export const transformTimestampToDate = (val: TransformFnParams): Date => {
  const value = val?.value;
  const seconds: number = value?.seconds;
  const nanoseconds: number = value?.nanoseconds || 0;
  if (seconds) return new Timestamp(seconds, nanoseconds).toDate();

  return value;
};

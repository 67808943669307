import { IImgixImageMap } from '../types';

// helper for converting remote images to base64 data url
export function toDataUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    return reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export function getImageSrc(imgUrl: any, imgMap: IImgixImageMap): string {
  const { large, medium, small, thumbnail } = imgMap ?? {};

  return imgUrl ?? medium?.url ?? large?.url ?? small?.url ?? thumbnail?.url;
}

export function buildImageSrcset(imgMap: IImgixImageMap): string {
  const { large, medium, small, thumbnail } = imgMap ?? {};
  const srcSet = [
    ...(thumbnail ? [`${thumbnail?.url} ${thumbnail?.width}w`] : []),
    ...(small ? [`${small?.url} ${small?.width}w`] : []),
    ...(medium ? [`${medium?.url} ${medium?.width}w`] : []),
    ...(large ? [`${large?.url} ${large?.width}w`] : [])
  ].join(', ');

  return srcSet;
}

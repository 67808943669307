<nz-layout class="">
  <h2>Create a style</h2>
  <p>Give your custom style a name.</p>
  <nz-content class="content-area">
    <form nz-form nzLayout="vertical" [formGroup]="form">
      <nz-form-item>
        <input
          formControlName="name"
          nzSize="large"
          name="name"
          nz-input
          placeholder="Custom style name"
        />
      </nz-form-item>
    </form>
  </nz-content>
</nz-layout>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';

import { ImageBlockViewComponent } from '../views/image-block-view';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
    declarations: [
        ImageBlockViewComponent
    ],
    imports: [
        CommonModule,
        SoftImageViewModule,
        NzCardModule,
        NzCarouselModule
    ],
    exports: [
        ImageBlockViewComponent
    ]
})
export class ImageBlockViewModule { }

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { IBlock, ICollection, IContent, ISlug } from '../../types';
import { LiveThemedComponent } from '../../models';
import { initialNoop, slideInOut } from '../../animations';

@Component({
  selector: 'lib-collection-view',
  templateUrl: './collection-view.component.html',
  styleUrls: ['./collection-view.component.less'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInOut, initialNoop]
})
export class CollectionViewComponent extends LiveThemedComponent {
  @Output() contentClick = new EventEmitter<IContent>();
  @Input() collection: ICollection;
  @Input() slug: ISlug;
  @Input() showAsAbstract = false;

  handleClick(content: IContent) {
    if (!content) return;
    this.contentClick.next(content);
  }

  trackBy(idx: number, item: IBlock) {
    return item.hash;
  }

  get cardTextColor() {
    return this.slug?.theme?.card?.textColor;
  }
}

import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string, inlineCss?: string) {
    const transformedValue = value && inlineCss
      ? value.replace(/<(p|h1|h2|h3|h4|h5|h6|li)/g, `<$1 style="${inlineCss}"`)
      : value;
    return this._sanitizer.bypassSecurityTrustHtml(transformedValue);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

import { CustomStyleItemComponent } from './custom-style-item.component';

@NgModule({
  declarations: [CustomStyleItemComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    // Zorro
    NzLayoutModule,
    NzFormModule,
    NzInputModule
  ]
})
export class CustomStyleItemModule {}

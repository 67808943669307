<!-- Show RSVP button if drop is untriggered, published, and there's no user content or rsvp is disabled -->
<div *ngIf="drop?.published && !drop?.triggerProcessed && !userContent?.rsvpEnabled">
    <button nz-button nzBlock nzSize="large" [nzLoading]="isLoading" [disabled]="isDisabled" (click)="handleRsvpClick()"
        nzType="primary" [logEvent]="userClickedPreEventCta"
        [cubeData]="{ entityType: 'drop', entityId: drop?.contentId }" [destination]="'cube'">{{
        drop?.buttonLabels?.pre ? drop.buttonLabels.pre : drop?.tickets?.length > 0 ? 'Tickets' :
        'Sign up'}}</button>
</div>

<!-- Show confirmed if it's published, untriggered, and we've rsvped -->
<div *ngIf="drop?.published && !drop?.triggerProcessed && userContent?.rsvpEnabled">
    <button nz-button nzBlock nzSize="large" nzType="primary" nz-dropdown [nzDropdownMenu]="rsvpMenu"
        [nzLoading]="isLoading">
        You're confirmed
        <i nz-icon nzType="down"></i>
    </button>
</div>

<!-- REMOVE RSVP MENU -->
<nz-dropdown-menu #rsvpMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item *ngIf="!userContent?.receiveNotifications?.sms && drop?.hasPhoneRequirement"
            (click)="handleEnableSMS()">
            <i nz-icon nzType="message"></i> Turn on texts
        </li>

        <li nz-menu-item *ngIf="userContent?.receiveNotifications?.sms" (click)="handleDisableSMS()">
            <i nz-icon nzType="stop"></i> Turn off texts
        </li>

        <li nz-menu-item *ngIf="!userContent?.receiveNotifications?.email && drop?.hasEmailRequirement"
            (click)="handleEnableEmail()">
            <i nz-icon nzType="mail"></i> Turn on emails
        </li>

        <li nz-menu-item *ngIf="userContent?.receiveNotifications?.email" (click)="handleDisableEmail()">
            <i nz-icon nzType="stop"></i> Turn off emails
        </li>

        <li nz-menu-divider></li>

        <li nz-menu-item (click)="handleRemoveRSVP()">
            <i nz-icon nzType="delete"></i> Remove signup
        </li>
    </ul>
</nz-dropdown-menu>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { EventDetailViewComponent } from '../views/event-detail-view';
import { EventMainButtonViewModule } from './event-main-button-view.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';
import { RsvpConfirmationViewModule } from './rsvp-confirmation-view.module';

// Zorro
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  declarations: [EventDetailViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    EventMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    RsvpConfirmationViewModule,
    SafeHtmlPipeModule,

    NzCardModule,
    NzSkeletonModule,
    NzButtonModule,
    NzIconModule,
    NzAlertModule,
    NzTagModule,
    NzToolTipModule
  ],
  exports: [EventDetailViewComponent]
})
export class EventDetailViewModule {}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private _message: NzMessageService) {}

  formatError(e: HttpErrorResponse): string {
    let message = e?.error?.message ?? e?.error?.error;
    if (Array.isArray(e?.error?.message)) message = e.error.message.join(', ');
    return message;
  }

  displayError(e: HttpErrorResponse) {
    this._message.error(this.formatError(e));
  }
}
